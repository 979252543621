import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { RootState } from '../../state/store';
import * as campusSlice from '../Units/campusSlice';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import IconButton from '../../common/components/buttons/IconButton';
import { lrvConfirm } from '../../common/components/LrvConfirm/LrvConfirm';
import { LrvDivider } from '../../common/components/LrvDivider/LrvDivider';
import { getUnitPhaseTypeFromStocking } from '../../helpers/stocking.helpers';
import { ExtraActionsButton } from '../../common/components/buttons/ExtraActionsButton';
import { getMainRole, roles, stockingOptions, stockingPhaseTypes, stockingStatuses, transferTypes } from '../../config/commons';

import { Stocking } from './interfaces';
import * as stockingSlice from './sowingsSlice';
import styles from './DropdownStockingOptions.module.scss';
import * as finishStockingSlice from './finishStockingSlice';
import { defaultDestinationStocking } from './sowings.helpers';
import * as multiphaseSlice from './Multiphase/multiphaseSlice';
import * as transferStockingSlice from './transferStockingSlice';
import * as harvestsStockingSlice from './harvestsStockingSlice';
import * as parameterChartSlice from './Parameters/parameterChartSlice';
import * as stockingAnalysisSlice from './Analysis/stockingAnalysisSlice';

interface Props {
  theme?: 'dark' | 'light';
  stocking: Stocking;
  page?: 'sowings' | 'sowing';
}

export const DropdownStockingOptions = (props: Props) => {
  const { stocking, theme, page = 'sowings' } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation();
  const { pathname } = useLocation();

  const higherRole = getMainRole();

  const { currentPage } = useSelector((state: RootState) => state.stockings);
  const { company, phaseType } = useSelector((state: RootState) => state.header);
  const isRunningOnboarding = useSelector((state: RootState) => state.onboarding.run);
  const { sorts, stockingsToShow } = useSelector((state: RootState) => state.stockings.filters);

  const {
    selectedCampus,
    selectedModuleId: moduleId,
    selectedTankId: tankId,
  } = useSelector((state: Store) => state.stockings);

  function onEditStockingOption () {
    dispatch(stockingSlice.fetchStocking({ id: stocking._id }));
    dispatch(stockingSlice.fetchMaturations(stocking.companyId));
    dispatch(stockingSlice.fetchMaturationCodes(stocking.maturationId._id));

    dispatch(stockingSlice.setShowModalEdit(true));
    dispatch(stockingSlice.setStockingsToShow(stockingsToShow));

    dispatch(campusSlice.fetchVolumeRanges());
    dispatch(stockingSlice.fetchStockingDateRanges());
    dispatch(stockingSlice.fetchStageOptions());
  }

  function onEditPopulationOption () {
    history.push(`/production/stockings/${stocking._id}/population`);
  }

  function onMoveStockingOption () {
    dispatch(stockingSlice.fetchStocking({ id: stocking._id }));
    dispatch(stockingSlice.fetchMaturations(stocking.companyId));
    dispatch(stockingSlice.fetchMaturationCodes(stocking.maturationId._id));

    dispatch(stockingSlice.setShowModalMove(true));
    dispatch(stockingSlice.setStockingsToShow(stockingsToShow));
    dispatch(campusSlice.fetchVolumeRanges());
    dispatch(stockingSlice.fetchStockingDateRanges());
  }

  function onFinishStockingOption () {
    dispatch(finishStockingSlice.getAveragePLG(stocking._id));

    if (stocking.phaseType === stockingPhaseTypes.LARVAE || stocking.phaseType === stockingPhaseTypes.JUVENILE) {
      dispatch(campusSlice.fetchVolumeRanges());
      dispatch(stockingSlice.fetchStockingDateRanges());
    }

    dispatch(stockingSlice.fetchStocking({ id: stocking._id }));
    dispatch(finishStockingSlice.fetchStockingFinishedData());

    dispatch(finishStockingSlice.setShowModalFinish(true));
    dispatch(finishStockingSlice.setDataDestinationStocking([defaultDestinationStocking]));
  }

  function onEnableStockingOption () {
    dispatch(finishStockingSlice.setShowModalEnable(true));
    dispatch(stockingSlice.fetchStocking({ id: stocking._id }));
  }

  function onEnableTransferStockingOption () {
    dispatch(finishStockingSlice.setShowModalEnable(true));
    dispatch(stockingSlice.fetchStocking({ id: stocking._id }));
  }

  function onShowCurves (stocking: Stocking) {
    dispatch(stockingAnalysisSlice.setBrowsingFromStocking(true));
    history.push('/production/insights');
    const unitPhaseType = getUnitPhaseTypeFromStocking(stocking.phaseType);
    dispatch(stockingAnalysisSlice.fetchUnits({ companyId: company._id, phaseType: unitPhaseType, modulePhaseType: stocking.phaseType, selectFirstItem: false }));
    dispatch(stockingAnalysisSlice.setUnitSelected(stocking.campusId?._id));
    dispatch(stockingAnalysisSlice.fetchModules({ unitId: stocking.campusId?._id, phaseType: stocking.phaseType, selectFirstItem: false }));
    dispatch(stockingAnalysisSlice.setModuleSelected(stocking.moduleId?._id));
    dispatch(stockingAnalysisSlice.fetchContainers({ unitId: stocking.campusId?._id, moduleId: stocking.moduleId?._id }));
    dispatch(stockingAnalysisSlice.setContainerSelected(stocking.tankId?._id));
    dispatch(stockingAnalysisSlice.fetchStocking({ stockingId: stocking._id }));
  }

  const onShowParameterCurves = (stocking: Stocking) => {
    dispatch(parameterChartSlice.setUnitId(stocking.campusId?._id));

    dispatch(parameterChartSlice.fetchModules({ unitId: stocking.campusId?._id }));
    dispatch(parameterChartSlice.setModuleId(stocking.moduleId?._id));

    dispatch(parameterChartSlice.fetchContainers({ unitId: stocking.campusId?._id, moduleId: stocking.moduleId?._id }));
    dispatch(parameterChartSlice.setContainerId(stocking.tankId?._id));

    dispatch(parameterChartSlice.fetchStockings({ unitId: stocking.campusId?._id, moduleId: stocking.moduleId?._id, containerId: stocking.tankId?._id, companyId: company._id }));
    dispatch(parameterChartSlice.setStockingId(stocking._id));

    dispatch(parameterChartSlice.fetchStockingParameterChart({ companyId: company._id, stockingId: stocking._id }));

    history.push('/production/parameters');
  };

  const onSaveArchiveStocking = () => {
    if (pathname === '/production/stockings') {
      const params = {
        companyId: company._id,
        campusId: selectedCampus?._id,
        moduleId,
        tankId,
        page: currentPage,
        stockingsToShow,
        phaseType,
        sortByCampusName: sorts.byCampusName,
        sortByModuleName: sorts.byModuleName,
        sortByTankName: sorts.byTankName,
        sortByStockingDate: sorts.byStockingDate,
      };
      dispatch(stockingSlice.fetchStockings(params));
      return;
    }

    history.push('/production/stockings');
  };

  const renderConfirmationArchiveStocking = () => {
    const title = t('stockings.confirmArchive.title');
    const description = t('stockings.confirmArchive.description');
    const idOkButton = 'confirm_button';
    const idCancelButton = 'cancel_button';

    lrvConfirm({
      theme: 'light',
      title: title,
      content: description,
      icon: <ExclamationCircleOutlined />,
      okText: t('stockings.confirmArchive.yes').toUpperCase(),
      cancelText: t('stockings.confirmArchive.no').toUpperCase(),
      okButtonProps: { id: idOkButton },
      cancelButtonProps: { id: idCancelButton },
      async onOk () {
        await stockingSlice.archiveStocking(stocking._id, onSaveArchiveStocking);
      },
    });
  };

  const renderConfirmationUnarchiveStocking = () => {
    const title = t('stockings.confirmUnarchive.title');
    const description = t('stockings.confirmUnarchive.description');
    const idOkButton = 'confirm_button';
    const idCancelButton = 'cancel_button';

    lrvConfirm({
      theme: 'light',
      title: title,
      content: description,
      icon: <ExclamationCircleOutlined />,
      okText: t('stockings.confirmUnarchive.yes').toUpperCase(),
      cancelText: t('stockings.confirmUnarchive.no').toUpperCase(),
      okButtonProps: { id: idOkButton },
      cancelButtonProps: { id: idCancelButton },
      async onOk () {
        await stockingSlice.archiveStocking(stocking._id, onSaveArchiveStocking);
      },
    });
  };

  function renderConfirmationBindingCode () {
    const title = t('stockings.confirmBindingCode.title');
    const description = t('stockings.confirmBindingCode.description');
    const idOkButton = 'confirm_button';
    const idCancelButton = 'cancel_button';

    lrvConfirm({
      theme: 'light',
      title: title,
      content: description,
      icon: <ExclamationCircleOutlined />,
      okText: t('stockings.confirmBindingCode.yes').toUpperCase(),
      cancelText: t('stockings.confirmBindingCode.no').toUpperCase(),
      okButtonProps: { id: idOkButton },
      cancelButtonProps: { id: idCancelButton },
      onOk () {
        const stockingParams = {
          page: currentPage,
          companyId: company._id,
          stockingsToShow,
          phaseType,
          sortByCampusName: sorts.byCampusName,
          sortByModuleName: sorts.byModuleName,
          sortByTankName: sorts.byTankName,
          sortByStockingDate: sorts.byStockingDate,
        };

        const params = {
          stockingId: stocking._id,
          companyId: stocking.companyId,
          onFinishingRequest: () => {
            dispatch(stockingSlice.fetchStockings(stockingParams));
            dispatch(stockingSlice.fetchStocking({ id: stocking._id }));
          }
        };

        multiphaseSlice.createStockingBindingCode(params);
      },
    });
  }

  const onFinishInfoStockingOption = () => {
    dispatch(stockingSlice.fetchStocking({ id: stocking._id }));
    dispatch(finishStockingSlice.setShowFinishStockingInfo(true));
  };

  const onTransferInfoStockingOption = () => {
    dispatch(stockingSlice.setStocking(stocking));
    dispatch(transferStockingSlice.fetchTransfers({ stockingId: stocking._id }));
    dispatch(stockingSlice.fetchLastAnalysisData({ stockingId: stocking._id }));
    dispatch(transferStockingSlice.setShowTransferStockingInfo(true));
  };

  const onHarvestsInfoStockingOption = () => {
    dispatch(stockingSlice.setStocking(stocking));
    dispatch(harvestsStockingSlice.fetchPartialHarvests({ stockingId: stocking._id }));
    dispatch(harvestsStockingSlice.setShowHarvestsInfo(true));
  };

  const checkMoveModalPermission = () => {
    return higherRole === roles.SUPER_ADMIN || higherRole === roles.SALES || higherRole === roles.SALES_MANAGER || higherRole === roles.CLIENT_OWNER || higherRole === roles.CLIENT_MANAGER;
  };

  const menuItemsStockingOptions = () => {
    const showMoveModalOption = checkMoveModalPermission();

    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case stockingOptions.EDIT:
              onEditStockingOption();
              return;

            case stockingOptions.POPULATION:
              onEditPopulationOption();
              return;

            case stockingOptions.MOVE:
              onMoveStockingOption();
              return;

            case stockingOptions.ARCHIVE:
              renderConfirmationArchiveStocking();
              return;

            case stockingOptions.UNARCHIVE:
              renderConfirmationUnarchiveStocking();
              return;

            case stockingOptions.FINISH:
              onFinishStockingOption();
              return;

            case stockingOptions.ENABLE:
              onEnableStockingOption();
              return;

            case stockingOptions.ENABLE_TRANSFERS:
              onEnableTransferStockingOption();
              return;

            case stockingOptions.VIEW_CURVES:
              onShowCurves(stocking);
              return;

            case stockingOptions.VIEW_PARAMETER_CURVES:
              onShowParameterCurves(stocking);
              return;

            case stockingOptions.ASSIGN_BINDING_CODE:
              renderConfirmationBindingCode();
              return;

            case stockingOptions.ANALYSIS_CARD:
              history.push('/company/analysis-card');
              return;

            case stockingOptions.FINISH_INFO:
              onFinishInfoStockingOption();
              return;

            case stockingOptions.TRANSFER_INFO:
              onTransferInfoStockingOption();
              return;

            case stockingOptions.HARVESTS_INFO:
              onHarvestsInfoStockingOption();
              return;
          }
        }}
      >
        <Menu.Item id='menu_option_edit' key={stockingOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' theme={theme} className={styles.icon} />
          <span>{t('stockings.edit')}</span>
        </Menu.Item>

        <Menu.Item id='menu_option_population' key={stockingOptions.POPULATION} className={styles.menuItemOptions}>
          <Icon name='file-list-3' theme={theme} className={styles.icon} />
          <span>{t('stockings.population')}</span>
        </Menu.Item>

        {showMoveModalOption && <Menu.Item id='menu_option_move' key={stockingOptions.MOVE} className={styles.menuItemOptions}>
          <Icon name='drag-move-2' type='fill' theme={theme} className={styles.icon} />
          <span>{t('stockings.move')}</span>
        </Menu.Item>}

        {
          !stocking.isArchived &&
          <Menu.Item id='menu_option_archive' key={stockingOptions.ARCHIVE} className={styles.menuItemOptions}>
            <Icon name='inbox-archive' theme={theme} className={styles.icon} />
            <span>{t('stockings.archive')}</span>
          </Menu.Item>
        }

        {
          (stocking.status === stockingStatuses.ACTIVE || stocking.status === transferTypes.PARTIAL_TRANSFER) &&
          <Menu.Item id='menu_option_finish' key={stockingOptions.FINISH} className={styles.menuItemOptions}>
            <Icon name='checkbox' theme={theme} className={styles.icon} />
            <span>{t('stockings.finish')}</span>
          </Menu.Item>
        }

        {
          (stocking.status === stockingStatuses.HARVESTED || stocking.status === stockingStatuses.DISCARDED) &&
          <Menu.Item id='menu_option_finish' key={stockingOptions.ENABLE} className={styles.menuItemOptions}>
            <Icon name='checkbox' theme={theme} className={styles.icon} />
            <span>{t('stockings.enableStocking')}</span>
          </Menu.Item>
        }

        {
          (stocking.status === transferTypes.FULL_TRANSFER) &&
          <Menu.Item id='menu_option_enable' key={stockingOptions.ENABLE_TRANSFERS} className={styles.menuItemOptions}>
            <Icon name='checkbox' theme={theme} className={styles.icon} />
            <span>{t('stockings.enableStocking')}</span>
          </Menu.Item>
        }

        {
          stocking.phaseType !== stockingPhaseTypes.ADULT &&
          <Menu.Item id='menu_option_assign_binding_code' key={stockingOptions.ASSIGN_BINDING_CODE} className={styles.menuItemOptions}>
            <Icon name='ball-pen' theme={theme} className={styles.icon} />
            <span>{t('stockings.assignBindingCode')}</span>
          </Menu.Item>
        }

        <Menu.Item id='menu_option_customizable_analysis_card' key={stockingOptions.ANALYSIS_CARD} className={styles.menuItemOptions}>
          <Icon name='refund' theme={theme} className={styles.icon} />
          <span>{t('analysisCardCustomMobile.title')}</span>
        </Menu.Item>

        <LrvDivider className={styles.divider} />

        <Menu.Item id='menu_option_view_curves' key={stockingOptions.VIEW_CURVES} className={styles.menuItemOptions}>
          <Icon name='line-chart' theme={theme} className={styles.icon} />
          <span>{t('stockings.viewCurves')}</span>
        </Menu.Item>

        {
          company.showStockingParameterSection &&
          <Menu.Item id='menu_option_view_parameter_curves' key={stockingOptions.VIEW_PARAMETER_CURVES} className={styles.menuItemOptions}>
            <Icon name='bubble-chart' theme={theme} className={styles.icon} />
            <span>{t('stockings.viewParameterCurves')}</span>
          </Menu.Item>
        }

        {
          (stocking?.harvests && stocking.harvests.length > 0) &&
          <Menu.Item id='menu_option_finish_info' key={stockingOptions.HARVESTS_INFO} className={styles.menuItemOptions}>
            <Icon name='pie-chart-2' theme={theme} className={styles.icon} />
            <span>{t('stockings.harvestsInfo')}</span>
          </Menu.Item>
        }

        {
          stocking?.transfers?.length > 0 &&
          <Menu.Item id='menu_option_transfer_info' key={stockingOptions.TRANSFER_INFO} className={styles.menuItemOptions}>
            <Icon name='file-transfer' theme={theme} className={styles.icon} />
            <span>{t('stockings.transferStockingInfo')}</span>
          </Menu.Item>
        }

        {
          (stocking.status === stockingStatuses.HARVESTED || stocking.status === stockingStatuses.DISCARDED || stocking.status === transferTypes.FULL_TRANSFER) &&
          <Menu.Item id='menu_option_finish_info' key={stockingOptions.FINISH_INFO} className={styles.menuItemOptions}>
            <Icon name='information' theme={theme} className={styles.icon} />
            <span>{t('stockings.finishStockingInfo')}</span>
          </Menu.Item>
        }
      </Menu>
    );
  };

  return (
    <Dropdown
      overlay={() => menuItemsStockingOptions()}
      trigger={['click']}
      disabled={isRunningOnboarding}
      placement='bottomRight'
    >
      {page === 'sowings' ?
        <ExtraActionsButton
          id={`button_${stocking._id}`}
          onClick={(e) => e.stopPropagation()}
        /> :
        <IconButton
          id={`button_${stocking._id}`}
          onClick={(e) => e.stopPropagation()}
          iconName='more-2'
        />}
    </Dropdown>
  );
};
