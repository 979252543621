import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../Header';
import Footer from '../Footer';
import { formatLongDateWithOffset } from '../../../utils/date';
import { Store } from '../../../state/store.interfaces';
import * as headerSlice from '../../AppHeader/headerSlice';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import SuccessQuadrantD3 from '../../Reports/SuccessQuadrant/SuccessQuadrantD3';
import * as successQuadrantSlice from '../../Reports/SuccessQuadrant/successQuadrantSlice';
import { COLOR_LEGEND_WIDTH, disableGroupDataCheckbox, filterQuadrantDataByOutliers, getAxisLabel, getHeightOfTheOtherElements, getWidthOfTheOtherElements, getXAxisMinMax, groupStockingQuadrantData } from '../../Reports/SuccessQuadrant/helpers';

import DataDetail from './DataDetail';
import styles from './SuccessQuadrantPdf.module.scss';

let chart: SuccessQuadrantD3 | null;

type TParams = {
  companyId: string;
  campusId: string;
  moduleId: string;
  phaseType: string;
  fromDate: string;
  toDate: string;
  hours: string;
  accessToken: string;
  language: string;
  xAxis: string;
  yAxis: string;
  zAxis: string;
  circleSizeParameter: string;
  filterOutliers: string;
  groupData: string;
};

export const SuccessQuadrantPdf = ({ match }: RouteComponentProps<TParams>) => {
  const {
    companyId,
    campusId,
    moduleId,
    phaseType,
    hours,
    fromDate,
    toDate,
    language,
    accessToken,
    xAxis,
    yAxis,
    zAxis,
    circleSizeParameter,
    filterOutliers,
    groupData,
  } = match.params;

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const refChartMain = useRef<HTMLDivElement>(null);

  const { company } = useSelector((state: Store) => state.header);

  const {
    isLoading,
    quadrantData,
    unit,
    module,
    densityDisabled,
  } = useSelector((state: Store) => state.successQuadrant);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n, i18n.language]);

  useEffect(() => {
    if (!company._id || !quadrantData.length) {
      return;
    }

    setWidth(window.innerWidth - getWidthOfTheOtherElements());
    setHeight(window.innerHeight - getHeightOfTheOtherElements({ densityDisabled }));
  }, [company._id, quadrantData, densityDisabled]);

  useEffect(() => {
    const params = {
      campusId,
      companyId,
      moduleId,
      fromDate,
      hoursOffset: parseInt(hours),
      phaseType,
      toDate,
      accessToken,
    };

    dispatch(successQuadrantSlice.fetchQuadrantData(params));
    dispatch(successQuadrantSlice.fetchUnit({ unitId: campusId, accessToken }));
    dispatch(successQuadrantSlice.fetchModule({ moduleId, accessToken }));
  }, [dispatch, campusId, companyId, moduleId, fromDate, hours, toDate, phaseType, toDate, accessToken]);

  useEffect(() => {
    if (!companyId) {
      return;
    }

    dispatch(headerSlice.fetchCompany(companyId, accessToken));
  }, [dispatch, companyId, accessToken]);

  useEffect(() => {
    const size = width > height ? height : width;
    chart && chart.resize({ height: size, width: size });
  }, [width, height]);

  useEffect(() => {
    if (!quadrantData.length || !company?._id) {
      return;
    }

    const groupDataCheckboxDisabled = disableGroupDataCheckbox(zAxis);
    const filterQuadrantData = filterOutliers === 'true' ? filterQuadrantDataByOutliers({ quadrantData, xAxis, yAxis }) : quadrantData;
    const quadrantDataGrouped = !groupDataCheckboxDisabled && groupData === 'true' ? groupStockingQuadrantData({ quadrantData: filterQuadrantData, zAxis, xAxis, yAxis }) : filterQuadrantData;
    const { max: lastStage, min: firstStage } = getXAxisMinMax({ quadrantData: quadrantDataGrouped, xAxis });
    const size = width > height ? height : width;

    chart = new SuccessQuadrantD3({
      container: refChartMain.current,
      quadrantData: quadrantDataGrouped,
      firstStage,
      lastStage,
      height: size,
      width: size,
      phaseType,
      xAxis,
      yAxis,
      zAxis,
      circleSizeParameter,
      theme: 'light',
      groupData: groupData === 'true',
    });
  }, [quadrantData, chart, company, width, height, phaseType, xAxis, yAxis, zAxis, circleSizeParameter, filterOutliers, groupData, densityDisabled]);

  const getTitle = () => {
    return `${company.name} - ${unit.name} - ${module.name}`;
  };

  const getSubTitle = () => {
    const groupDataCheckboxDisabled = disableGroupDataCheckbox(zAxis);

    if (groupDataCheckboxDisabled) {
      return `${formatLongDateWithOffset(fromDate)} - ${formatLongDateWithOffset(toDate)}`;
    }

    return `${formatLongDateWithOffset(fromDate)} - ${formatLongDateWithOffset(toDate)} - (${i18n.t('quadrant.groupedData')})`;
  };

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <DotSpinner />
      </div>
    );
  }

  const renderTopAxis = () => {
    const svgWidth = (width > height ? height : width) + COLOR_LEGEND_WIDTH;

    return (
      <div
        className={styles.labelAxisY}
        style={{
          width: svgWidth,
        }}
      >
        <LrvText
          text={getAxisLabel({ axis: yAxis, phaseType })}
          theme='light'
        />

        <LrvText
          style={{
            width: COLOR_LEGEND_WIDTH + 11,
          }}
          text={getAxisLabel({ axis: zAxis, phaseType })}
          theme='light'
        />
      </div>
    );
  };

  const renderXAxisLabel = () => {
    return (
      <div className={styles.labelAxisX}>
        <LrvText
          text={getAxisLabel({ axis: xAxis, phaseType })}
          style={{
            right: `${COLOR_LEGEND_WIDTH / 2}px`,
            position: 'relative',
          }}
          theme='light'
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Header
        title={getTitle()}
        subtitle={getSubTitle()}
      />

      <div className={styles.body}>
        <DataDetail />

        <div className={styles.chart}>
          {renderTopAxis()}
          <div className={styles.successQuadrantChart}>
            <div id='chart' ref={refChartMain} />
          </div>
          {renderXAxisLabel()}
        </div>
      </div>

      <Footer />
    </div>
  );
};
