import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getLanguage } from '../../../utils/language';
import { unitStatuses } from '../../../config/commons';
import { downloadFile } from '../../../utils/download';
import { getUserSession } from '../../../utils/userSession';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { CAMPUS_URL, MODULES_URL, QUADRANT_DATA_URL, SUCCESS_QUADRANT_PDF_URL } from '../../../config/config.api';

import { getFromDate, getToDate, SUCCESS_QUADRANT_PARAMETERS } from './helpers';
import { StockingQuadrantState, Unit, StockingQuadrant, AnalysisQuadrantFilter } from './interfaces';

const initialStateFilters: AnalysisQuadrantFilter = {
  fromDate: getFromDate(180),
  toDate: getToDate(),
  campusId: '',
  moduleId: '',
  xAxis: SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH,
  yAxis: SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE,
  zAxis: SUCCESS_QUADRANT_PARAMETERS.DENSITY,
  circleSizeParameter: SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY,
  filterOutliers: false,
  groupData: false,
};

const initialState: StockingQuadrantState = {
  isLoading: false,
  quadrantData: [],
  units: [],
  modules: [],
  filters: initialStateFilters,
  densityDisabled: false,
  module: {
    _id: '',
    name: '',
  },
  unit: {
    _id: '',
    name: '',
  },
  isDownloadingFile: false,
};

export const successQuadrantSlice = createSlice({
  name: 'successQuadrant',
  initialState,
  reducers: {
    setQuadrantData: (state: StockingQuadrantState, action: PayloadAction<StockingQuadrant[]>) => {
      state.quadrantData = action.payload;
    },
    setIsLoading: (state: StockingQuadrantState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUnits: (state: StockingQuadrantState, action: PayloadAction<Unit[]>) => {
      state.units = action.payload;
    },
    setModules: (state: StockingQuadrantState, action: PayloadAction<Unit[]>) => {
      state.modules = action.payload;
    },
    setFilters: (state: StockingQuadrantState, action: PayloadAction<AnalysisQuadrantFilter>) => {
      state.filters = action.payload;
    },
    resetFilters: (state: StockingQuadrantState) => {
      state.modules = [];
      state.quadrantData = [];
      state.filters = initialStateFilters;
    },
    setUnit: (state: StockingQuadrantState, action: PayloadAction<Unit>) => {
      state.unit = action.payload;
    },
    setModule: (state: StockingQuadrantState, action: PayloadAction<Unit>) => {
      state.module = action.payload;
    },
    setIsDownloadingFile: (state: StockingQuadrantState, action: PayloadAction<boolean>) => {
      state.isDownloadingFile = action.payload;
    },
    setDensityDisabled: (state: StockingQuadrantState, action: PayloadAction<boolean>) => {
      state.densityDisabled = action.payload;
    },
  },
});

export const {
  setQuadrantData,
  setIsLoading,
  setUnits,
  setModules,
  setFilters,
  resetFilters,
  setUnit,
  setModule,
  setIsDownloadingFile,
  setDensityDisabled,
} = successQuadrantSlice.actions;

export const fetchUnits = (params: { companyId: string, unitPhaseType: string }) => async (dispatch: Function) => {
  const { companyId, unitPhaseType } = params;
  const userSession = getUserSession();

  const unitParams = {
    $limit: -1,
    companyId: companyId ? companyId : userSession.companyId,
    phaseType: unitPhaseType,
    active: true,
    status: [unitStatuses.ACTIVE],
    '$sort[name]': 1,
    $select: ['name'],
  };

  try {
    const response = await axios.get<Unit[]>(CAMPUS_URL, { params: unitParams });
    dispatch(setUnits(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchModules = (props: { unitId: string, phaseType: string }) => async (dispatch: Function) => {
  const { unitId, phaseType } = props;

  const params = {
    $limit: -1,
    campusId: unitId,
    phaseType,
    active: true,
    '$sort[name]': 1,
    $select: ['name'],
  };

  try {
    const response = await axios.get<Unit[]>(MODULES_URL, { params });
    dispatch(setModules(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchUnit = (props: { unitId: string; accessToken?: string; }) => async (dispatch: Function) => {
  const { accessToken, unitId } = props;

  const params = {
    $select: ['name'],
  };

  const url = `${CAMPUS_URL}/${unitId}`;

  try {
    if (accessToken) {
      const response = await axios.get<Unit>(url, {
        headers: { 'Authorization': accessToken },
        params,
      });

      dispatch(setUnit(response.data));
      return;
    }

    const response = await axios.get<Unit>(url, { params });
    dispatch(setUnit(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchModule = (props: { moduleId: string; accessToken?: string }) => async (dispatch: Function) => {
  const { accessToken, moduleId } = props;

  const params = {
    $select: ['name'],
  };

  const url = `${MODULES_URL}/${moduleId}`;

  try {
    if (accessToken) {
      const response = await axios.get<Unit>(url, {
        headers: { 'Authorization': accessToken },
        params,
      });

      dispatch(setModule(response.data));
      return;
    }

    const response = await axios.get<Unit>(url, { params });
    dispatch(setModule(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

const injectQuadrantType = (stockingQuadrant: StockingQuadrant[]) => {
  const survivalData = stockingQuadrant.map((item) => item.survivalRate);
  const weeklyGrowthData = stockingQuadrant.map((item) => item.weeklyGrowth);

  const survivalDataMin = Math.min(...survivalData);
  const survivalDataMax = Math.max(...survivalData);

  const weeklyGrowthDataMin = Math.min(...weeklyGrowthData);
  const weeklyGrowthDataMax = Math.max(...weeklyGrowthData);

  const averageSurvivalRate = (survivalDataMax + survivalDataMin) / 2;
  const averageWeeklyGrowth = (weeklyGrowthDataMax + weeklyGrowthDataMin) / 2;

  for (let index = 0; index < stockingQuadrant.length; index++) {
    const item = stockingQuadrant[index];

    if (item.survivalRate >= averageSurvivalRate) {
      stockingQuadrant[index].quadrant = item.weeklyGrowth >= averageWeeklyGrowth ? 1 : 2;
      continue;
    }

    stockingQuadrant[index].quadrant = item.weeklyGrowth >= averageWeeklyGrowth ? 3 : 4;
  }

  return stockingQuadrant;
};

export const checkDisabledGroupDensity = (stockingQuadrant: StockingQuadrant[]) => (dispatch: Function) => {
  const uniqueDensityUnits = new Set<string>();
  for (const quadrantData of stockingQuadrant) {
    uniqueDensityUnits.add(quadrantData.densityUnit);
  }
  const densityUnits = Array.from(uniqueDensityUnits);
  const densityDisabled = densityUnits.length > 1; // if it's true, there are differents density units
  dispatch(setDensityDisabled(densityDisabled));
};

export const fetchQuadrantData = (props: { companyId: string; campusId: string; moduleId: string; phaseType: string; fromDate: string; toDate: string; hoursOffset: number; accessToken?: string; }) => async (dispatch: Function) => {
  const { accessToken, campusId, companyId, fromDate, hoursOffset, moduleId, phaseType, toDate } = props;
  dispatch(setIsLoading(true));

  const params = {
    campusId,
    companyId,
    fromDate,
    hoursOffset,
    moduleId,
    phaseType,
    toDate,
  };

  try {
    if (accessToken) {
      const response = await axios.get<StockingQuadrant[]>(QUADRANT_DATA_URL, {
        headers: { 'Authorization': accessToken },
        params,
      });

      const stockingQuadrant = injectQuadrantType(response.data);
      dispatch(setQuadrantData(stockingQuadrant));
      dispatch(checkDisabledGroupDensity(stockingQuadrant));
      dispatch(setIsLoading(false));
      return;
    }

    const response = await axios.get<StockingQuadrant[]>(QUADRANT_DATA_URL, { params });
    const stockingQuadrant = injectQuadrantType(response.data);
    dispatch(setQuadrantData(stockingQuadrant));
    dispatch(checkDisabledGroupDensity(stockingQuadrant));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.log(error?.response);
  }
};

export interface UrlParameterStateProps {
  companyId: string;
  companyName: string;
  campusId: string;
  moduleId: string;
  phaseType: string;
  fromDate: string;
  toDate: string;
  hours: number;
  xAxis: string;
  yAxis: string;
  zAxis: string;
  circleSizeParameter: string;
  filterOutliers: boolean;
  groupData: boolean;
}

export const fetchUrlSuccessQuadrantPdf = (props: UrlParameterStateProps) => async (dispatch: Function) => {
  const { companyId, companyName, campusId, moduleId, phaseType, fromDate, toDate, hours, xAxis, yAxis, zAxis, circleSizeParameter, filterOutliers, groupData } = props;
  dispatch(setIsDownloadingFile(true));

  const language = getLanguage();
  const accessToken = localStorage.getItem('accessToken');

  try {
    const response = await axios.get(SUCCESS_QUADRANT_PDF_URL);
    const url = `${response.data.url}?companyId=${companyId}&campusId=${campusId}&moduleId=${moduleId}&phaseType=${phaseType}&xAxis=${xAxis}&yAxis=${yAxis}&zAxis=${zAxis}&language=${language}&fromDate=${fromDate}&toDate=${toDate}&hours=${hours}&circleSizeParameter=${circleSizeParameter}&filterOutliers=${filterOutliers}&groupData=${groupData}&accessToken=${accessToken}`;
    await downloadFile(url, companyName, 'pdf');
    dispatch(setIsDownloadingFile(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export default successQuadrantSlice.reducer;
