import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/en-au';
import i18next from 'i18next';

import { DATE_FORMATS, LANGUAGES } from '../config/commons';

import { getLanguage } from './language';

moment.updateLocale('es', {
  monthsShort: [
    'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
    'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic',
  ],
});

moment.updateLocale('pt', {
  monthsShort: [
    'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
    'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez',
  ],
});

// MMM DD, YYYY - DD MMM, YYYY
// Aug 7, 2020 - 7 Ago, 2020
export const formatLongDateWithZone = (date: string) => {
  const language = getLanguage();
  moment.locale(language);
  const dateParseZone = moment.parseZone(date);

  if (language.toUpperCase() === LANGUAGES.SPANISH || language.toUpperCase() === LANGUAGES.PORTUGUESE) {
    return dateParseZone.format(DATE_FORMATS.DD_MMM_YYYY);
  }

  return dateParseZone.format(DATE_FORMATS.MMM_DD_YYYY);
};

export const formatLongDateWithOffset = (date: string, hoursOffset = 0) => {
  const language = getLanguage();
  moment.locale(language);

  if (language.toUpperCase() === LANGUAGES.SPANISH || language.toUpperCase() === LANGUAGES.PORTUGUESE) {
    return moment(date).subtract(hoursOffset, 'h').format(DATE_FORMATS.MMM_DD_YYYY);
  }

  return moment(date).subtract(hoursOffset, 'h').format(DATE_FORMATS.MMM_DD_YYYY);
};

//MMM YYYY
export const formatMonthYear = (date: string) => {
  const language = getLanguage();
  moment.locale(language);

  let month = moment(date).utc().format('MMM').replace('.', '');
  month = month.charAt(0).toUpperCase() + month.slice(1);
  const year = moment(date).utc().format('YYYY');

  return month + ', ' + year;
};

//YYYY-MM-DD
export const formatYearMonthDay = (date: string) => {
  return moment(date).format(DATE_FORMATS.YYYY_MM_DD);
};

//DD-MM-YY
export const formatDayMonthYear = (date: string) => {
  return moment(date).format(DATE_FORMATS.DD_MM_YY);
};

//HH:mm
export const formatUTCHour = (date: string, hoursOffset = 0) => {
  return moment(date).utc().add(hoursOffset, 'h').format('HH:mm');
};

//HH:mm
export const formatLocaleHour = (date: string) => {
  return moment(date).format('HH:mm');
};

//Aug 7 - 7 Ago
export const formatMonth = (date: string) => {
  const language = getLanguage();
  moment.locale(language);

  let month = moment(date).utc().format('MMM').replace('.', '');
  month = month.charAt(0).toUpperCase() + month.slice(1);
  const day = moment(date).utc().format('D');

  if (language.toLowerCase() === 'es') {
    return `${day} ${month}`;
  }

  return `${month} ${day}`;
};

export function getMonthName (month: string) {
  let value = '';
  switch (month) {
    case '1':
      value = i18next.t('months.january');
      break;

    case '2':
      value = i18next.t('months.february');
      break;

    case '3':
      value = i18next.t('months.march');
      break;

    case '4':
      value = i18next.t('months.april');
      break;

    case '5':
      value = i18next.t('months.may');
      break;

    case '6':
      value = i18next.t('months.june');
      break;

    case '7':
      value = i18next.t('months.july');
      break;

    case '8':
      value = i18next.t('months.august');
      break;

    case '9':
      value = i18next.t('months.september');
      break;

    case '10':
      value = i18next.t('months.octuber');
      break;

    case '11':
      value = i18next.t('months.november');
      break;

    case '12':
      value = i18next.t('months.december');
      break;
  }

  return value;
}

export const getHoursOffset = (date?: string) => {
  let today = moment();
  const minutesInHour = 60;

  if (date) {
    today = moment(date);
  }

  return today.utcOffset() / minutesInHour;
};

export const getStartDate = (props: { monthsToSubtract: number }) => {
  const { monthsToSubtract } = props;
  const date = moment().subtract(monthsToSubtract, 'M').utcOffset(0).startOf('month').toDate();
  return date;
};

export const getEndDate = (props: { monthsToSubtract: number }) => {
  const { monthsToSubtract } = props;
  const date = moment().subtract(monthsToSubtract, 'M').utcOffset(0).endOf('month').toDate();
  return date;
};

export const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const setUtcDate = (date: string) => {
  const utcDate = new Date(date);
  const timezoneOffset = utcDate.getTimezoneOffset() / 60;
  const hours = utcDate.getUTCHours();
  utcDate.setUTCHours(hours - timezoneOffset);
  return utcDate;
};

export const getStartDateOfWeek = (week: moment.Moment) => {
  const year = week.year();
  const weekNumber = week.week();

  return moment().isoWeekYear(year).isoWeek(weekNumber).startOf('week').format(DATE_FORMATS.YYYY_MM_DD);
};

export const getEndDateOfWeek = (week: moment.Moment) => {
  const year = week.year();
  const weekNumber = week.week();

  return moment().isoWeekYear(year).isoWeek(weekNumber).endOf('week').format(DATE_FORMATS.YYYY_MM_DD);
};

export const getDayOfMonth = (value: string) => {
  return moment(value).date();
};

export const getDayLabel = (value: string) => {
  const language = getLanguage();
  moment.locale(language);

  if (language.toLowerCase() === 'es') {
    return moment(value).format('ddd').replace('.', '');
  }

  return moment(value).format('ddd');
};

export const getMonthLabel = (value: string) => {
  const language = getLanguage();
  moment.locale(language);

  if (language.toLowerCase() === 'es') {
    const month = moment(value).format('MMM').replace('.', '');
    return month.charAt(0).toUpperCase() + month.slice(1);
  }

  return moment(value).format('MMM');
};

export const areDatesEqual = (props: { date1: string; date2: string; }): boolean => {
  const { date1, date2 } = props;

  const formattedDate1 = moment(date1).format(DATE_FORMATS.YYYY_MM_DD);
  const formattedDate2 = moment(date2).format(DATE_FORMATS.YYYY_MM_DD);

  return formattedDate1 === formattedDate2;
};