import { DataSource } from '../pages/home/interfaces';

export function removeSpaces (word: string) {
  return word.replace(/\s+/g, '');
}

export const dollarUS = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const applyThousandsSeparator = (value: string | number | undefined) => {
  if (value === undefined) return '';

  const [integer, decimal] = `${value}`.split('.');

  const integerWithSeparator = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return decimal ? `${integerWithSeparator}.${decimal}` : integerWithSeparator;
};

const removeThousandsSeparator = (value: string | undefined) => {
  return value?.replace(/\$\s?|(,*)/g, '');
};

export const applyParserThousandsSeparator = (value: string | undefined) => {
  const number = removeThousandsSeparator(value);
  if (number) {
    return parseInt(number);
  }
  return '';
};

export const charactersToSeparate = '_L@rvia_';

export const matchNamesWithSpecialCharacters = (dataSource: DataSource[]) => {
  let names = '';

  for (let index = 0; index < dataSource.length; index++) {
    const data = dataSource[index];

    if (!(data?.enabled && data?.show)) {
      continue;
    }

    if (names === '') {
      names = data.id;
    } else {
      names = names + charactersToSeparate + data.id;
    }
  }

  return names;
};

const billion = 1000000000;
const million = 1000000;

export const formatMillion = (value: number) => {
  if (!value) {
    return '';
  }

  if (value < 100000) {
    return value.toString();
  }

  if (value < billion) {
    return (value / million).toFixed(2) + 'M';
  }

  return (value / billion).toFixed(2) + 'B';
};
