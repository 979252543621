import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Select, Row, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { sortByName } from '../../utils/sort';
import { Store } from '../../state/store.interfaces';
import { getCurrentTheme } from '../../helpers/theme';
import Icon from '../../common/components/Icon/Icon';
import { DropdownProps } from '../../common/interfaces/commons';
import { LrvTag } from '../../common/components/LrvTag/LrvTag';
import IconButton from '../../common/components/buttons/IconButton';
import CleanButton from '../../common/components/buttons/CleanButton';
import { getEndDateOfWeek, getStartDateOfWeek } from '../../utils/date';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvFilterPanel } from '../../common/components/LrvSideFloatingPanel/LrvFilterPanel';
import { setFiltersSampleByUser, setShowSamplesByUserModal } from '../SamplesByUser/samplesByUserSlice';
import { isAdminUser, isBusinessManagerRole, isOwnerRole, unitStatuses, isDistributorCompany } from '../../config/commons';

import { Campus } from './interfaces';
import styles from './ActivityDashboardFilters.module.scss';
import { disabledDateFrom, disabledDateTo } from './helpers';
import * as activityDashboardSlice from './activityDashboardSlice';
import { FiltersUserActivity } from './UserActivityDashboard/interfaces';
import * as userActivityDashboardSlice from './UserActivityDashboard/userActivityDashboardSlice';

const { Option } = Select;

export default function ActivityDashboardFilters () {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    fromDate,
    toDate,
    campuses,
    campus,
  } = useSelector((state: Store) => state.activityDashboard.filters);
  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);

  const theme = getCurrentTheme();

  function renderSamplesByUserButton () {
    return (
      <IconButton
        id='samples_by_user_button'
        type='text'
        className={styles.button}
        icon={<Icon name='bubble-chart' theme={theme} />}
        onClick={() => {
          dispatch(setFiltersSampleByUser({ company: selectedCompany, toDate, fromDate, analysisPhaseType: phaseType }));
          dispatch(setShowSamplesByUserModal(true));
        }}
        tooltipText={t('clientDashboard.samplesByUser.title')}
        placement='left'
      />
    );
  }

  function renderUserActivityDashboardButton () {
    return (
      <IconButton
        id='user_dashboard_activity_button'
        className={styles.button}
        iconName='user-star'
        onClick={() => {
          const user = { _id: '', firstName: '', lastName: '' };
          const currentWeek = moment().startOf('week');
          const startWeek = getStartDateOfWeek(currentWeek);
          const endWeek = getEndDateOfWeek(currentWeek);

          const params: FiltersUserActivity = { users: [], toDate: endWeek, fromDate: startWeek, user, dateSelected: currentWeek.toString(), frequency: 'WEEKLY' };

          dispatch(userActivityDashboardSlice.setUserActivity([]));
          dispatch(userActivityDashboardSlice.setFiltersUserActivityDashboard(params));
          dispatch(userActivityDashboardSlice.setShowUserActivityDashboardModal(true));
          dispatch(userActivityDashboardSlice.fetchUsers(selectedCompany._id));
        }}
        tooltipText={t('clientDashboard.userActivityDashboard.title')}
        placement='bottomLeft'
      />
    );
  }

  const campusData = campuses ? campuses
    .map((campus: Campus, index: number) => {
      return {
        key: index,
        _id: campus._id,
        code: campus.code,
        name: campus.name,
        status: campus.status
      };
    }) : [];

  campusData.sort(sortByName);
  const childrenCampus: JSX.Element[] = [];
  for (let i = 0; i < campusData.length; i++) {
    childrenCampus.push(
      <Option key={campusData[i]._id} value={campusData[i].name} label={campusData[i].name}>
        <div className={styles.optionSelect}>
          {campusData[i].name}
          {
            campusData[i].status === unitStatuses.INACTIVE
            &&
            <>
              &nbsp; &nbsp;
              <LrvTag color='#e04d00'>{t('campus.inactive')}</LrvTag>
            </>
          }
        </div>
      </Option>
    );
  }

  const renderDropdownUnit = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_campus'
        key='dropdown_unit'
        theme={theme}
        className={className}
        title={t('clientDashboard.selectCampus')}
        showSearch
        value={campus?.name || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        placeholder={t('clientDashboard.selectCampus')}
        optionLabelProp='label'
        onChange={(value) => {
          const campus = campuses.find((campus) => campus.name === value);

          if (!campus) {
            return;
          }

          dispatch(activityDashboardSlice.setSelectedCampus(campus));
        }}
        dropdownMatchSelectWidth={false}
      >
        {childrenCampus}
      </LrvSelect>
    );
  };

  const renderFromDatePicker = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvDatePicker
        id='from_date_picker'
        title={t('clientDashboard.dateFrom')}
        theme={theme}
        className={className}
        value={moment(fromDate)}
        defaultValue={moment(fromDate)}
        allowClear={false}
        disabledDate={(value) => disabledDateFrom(value, toDate)}
        placeholder={t('clientDashboard.dateFrom')}
        onChange={(date) => {
          const dateSelected = date?.toString() as string;
          dispatch(activityDashboardSlice.setFromDate(dateSelected));
        }}
      />
    );
  };

  const renderToDatePicker = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvDatePicker
        id='to_date_picker'
        title={t('clientDashboard.dateTo')}
        theme={theme}
        className={className}
        value={moment(toDate)}
        defaultValue={moment(toDate)}
        allowClear={false}
        disabledDate={(value) => disabledDateTo(value, fromDate)}
        placeholder={t('clientDashboard.dateTo')}
        onChange={(date) => {
          const dateSelected = date?.toString() as string;
          dispatch(activityDashboardSlice.setToDate(dateSelected));
        }}
      />
    );
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon={true}
          title={<div className={styles.title}>{t('clientDashboard.title')}</div>}
          cleanButtonProps={{
            onClick: () => {
              dispatch(activityDashboardSlice.resetActivityDashboardFilters());
            },
          }}
        >
          <Space direction='vertical' className={styles.bodyPanel}>
            {renderDropdownUnit({ theme: 'light' })}
            {renderFromDatePicker({ theme: 'light' })}
            {renderToDatePicker({ theme: 'light' })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={theme}
        onClick={() => dispatch(activityDashboardSlice.resetActivityDashboardFilters())}
      />
    );
  };

  return (
    <Row className={styles.container}>
      <Space className={styles.filters} align='end'>
        {renderDropdownUnit({ className: styles.select, theme })}
        {renderFromDatePicker({ className: styles.select, theme })}
        {renderToDatePicker({ className: styles.select, theme })}
        {renderCleanButton()}
      </Space>
      {renderSidePanel()}

      <Row className={styles.options}>
        {(isAdminUser() || isBusinessManagerRole() || (isOwnerRole() && isDistributorCompany())) && renderSamplesByUserButton()}
        {(isAdminUser() || isBusinessManagerRole() || (isOwnerRole())) && renderUserActivityDashboardButton()}
      </Row>
    </Row>
  );
}
