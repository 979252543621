import cx from 'classnames';
import React, { useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Menu, Dropdown, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';

import { Store } from '../../state/store.interfaces';
import { formatLongDateWithOffset } from '../../utils/date';
import Icon from '../../common/components/Icon/Icon';
import { ENVIRONMENT } from '../../config/config.api';
import { getCurrentTheme } from '../../helpers/theme';
import { LrvTag } from '../../common/components/LrvTag/LrvTag';
import { hasSameAnalysis } from '../../helpers/analysis.helpers';
import TinyLabel from '../../common/components/TinyLabel/TinyLabel';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { lrvConfirm } from '../../common/components/LrvConfirm/LrvConfirm';
import { LrvPopover } from '../../common/components/LrvPopover/LrvPopover';
import { calcAvgGrowth, getUnitPhaseTypeFromStocking } from '../../helpers/stocking.helpers';
import { ExtraActionsButton } from '../../common/components/buttons/ExtraActionsButton';
import { analysisStatuses, analysisPatch, analysisTags, isSuperAdmin, getMainRole, roles, environments, stockingPhaseTypes, unitStatuses, roundWeight, THEME, roundLength } from '../../config/commons';

import styles from './Sowing.module.scss';
import * as analysisSlice from './sowingSlice';
import { getLabelForKey } from './Detail/helper';
import * as analysisFromConsolidatedSlice from './analysisFromConsolidatedSlice';
import { Analysis as IAnalysis, AnalysisList, AnalysisMapData, EditAnalysis } from './interfaces';
import { getPlgColumnTitle, getPlgDataIndex, getPostLarvaeColumnTitle, getStageColumnTitle, isConsolidatedAnalysis } from './helpers';

interface Props {
  stockingId: string;
}

export const AnalysisTable = (props: Props) => {
  const { stockingId } = props;

  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [consolidatedAnalysisSelected, setConsolidatedAnalysisSelected] = useState<{ _id: string; analysisIds: string[] }>({ _id: '', analysisIds: [] });

  const {
    analysis,
    total,
    limit,
    isLoading,
    currentPage,
    analysisCards,
  } = useSelector((state: Store) => state.analysis);

  const { selectedStocking: stocking } = useSelector((state: Store) => state.stockings);

  const { company: selectCompany, phaseType: phaseTypeGlobal } = useSelector((state: Store) => state.header);

  const isCampusInactive = stocking.campusId.status === unitStatuses.INACTIVE;
  const stockingIsArchived = stocking.isArchived;
  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const onChangePage = (page: number) => {
    dispatch(analysisSlice.setCurrentPage(page));
    dispatch(analysisSlice.fetchAnalysis({ page, stockingId, companyId: stocking.companyId }));
  };

  const analysisData: AnalysisMapData[] = analysis.map((item: IAnalysis, index) => {
    const isAnalysisCompleted = item.status === analysisStatuses.COMPLETED;

    return {
      key: index,
      _id: item._id,
      code: item.code,
      type: item.type,
      status: item.status,
      createdAt: formatLongDateWithOffset(item.createdAt),
      sampleWeight: item.inputData?.sampleWeight,
      stage: item.inputData?.stage,
      maturationId: item.inputData?.maturationId,
      larvaeNumber: item.resultData?.larvaeNumber,
      larvaePerGram: item.resultData?.larvaePerGram,
      averageLength: isAnalysisCompleted ? roundLength({ value: item.resultData.averageLength }) as string : '',
      averageWeight: isAnalysisCompleted ? roundWeight({ value: item.resultData.averageWeight }) as string : '',
      uniformityWeight: isAnalysisCompleted ? `${item.resultData.uniformity || 0} %` : '',
      uniformityLength: isAnalysisCompleted ? `${100 - item.resultData.variationCoefficientLength} %` : '',
      variationCoefficientLength: isAnalysisCompleted ? `${item.resultData.variationCoefficientLength} %` : '',
      variationCoefficientWeight: isAnalysisCompleted ? `${item.resultData.variationCoefficient} %` : '',
      stockingId: item.sowingId,
      hidden: item.hidden,
      isArchived: item.isArchived,
      coordinatesRemoved: item.coordinatesRemoved,
      unusualAverageWeight: item.unusualAverageWeight,
      analysisIds: item.analysisIds,
      avgGrowth: isAnalysisCompleted ? calcAvgGrowth({ averageWeight: item.resultData?.averageWeight, stage: item.inputData.stage, phaseType: item.phaseType }) : '',
    };
  });

  const renderWarningIcon = (record: AnalysisMapData) => {
    if (!record.unusualAverageWeight) {
      return null;
    }

    return (
      <LrvPopover
        placement='left'
        theme={isLightTheme ? 'dark' : 'light'}
        content={() =>
          <div>
            <div>{t('analysis.warnings.weightOutOfRange')}.</div>
            <div>{t('analysis.move.warning')}</div>
            <div
              className={styles.moveLabel}
              onClick={(e) => {
                e.stopPropagation();
                onMoveAnalysis(record);
              }}
            >
              {t('analysis.move.title')}
            </div>
          </div>
        }
      >
        <WarningOutlined
          className={styles.warningIcon}
          onClick={(e) => e.stopPropagation()}
        />
      </LrvPopover>
    );
  };

  const updateConsolidatedAnalysis = (analysis: AnalysisMapData) => {
    if (hasSameAnalysis(consolidatedAnalysisSelected.analysisIds, analysis.analysisIds)) {
      setConsolidatedAnalysisSelected({ _id: '', analysisIds: [] });
      return;
    }

    setConsolidatedAnalysisSelected({ _id: analysis._id, analysisIds: analysis.analysisIds });
  };

  const renderTagsColumn = (record: AnalysisMapData) => {
    const { code, hidden, type } = record;
    const hasError = record.status === analysisStatuses.ERROR;

    if (hasError) {
      return <LrvTag type='error'>{t('analysis.error')}</LrvTag>;
    }

    if (isConsolidatedAnalysis(type)) {
      return (
        <>
          <LrvTag id={`consolidated_${code}`} className={isLightTheme ? styles.consolidatedLight : styles.consolidatedDark}>{t('analysis.consolidated')}</LrvTag>
          <ExtraActionsButton
            id={`consolidated_analysis_${code}`}
            className={styles.eyeIcon}
            onClick={(e) => {
              e.stopPropagation();
              updateConsolidatedAnalysis(record);
            }}
            icon={
              <Icon
                name={consolidatedAnalysisSelected._id === record._id ? 'eye' : 'eye-off'}
                theme={theme}
                className={consolidatedAnalysisSelected._id === record._id ? styles.icon : ''}
              />
            }
          />
        </>
      );
    }

    if (hidden) {
      return (
        <Space size={8}>
          <LrvTag id={`exclude_chart_tag_${code}`} type='info'>{t(analysisTags.REMOVED_FROM_CHART)}</LrvTag>
          {renderWarningIcon(record)}
        </Space>
      );
    }

    return renderWarningIcon(record);
  };

  const renderDropdownAnalysisOption = (record: AnalysisMapData) => {
    return (
      <Dropdown overlay={() => menuItemAnalysisOption(record)} trigger={['click']} placement='bottomRight'>
        <ExtraActionsButton
          onClick={(e) => e.stopPropagation()}
          id={`button_${record.code}`}
          className={consolidatedAnalysisSelected._id === record._id || consolidatedAnalysisSelected.analysisIds.includes(record._id) ? styles.icon : ''}
        />
      </Dropdown>
    );
  };

  const fillEditFormAnalysis = (analysis: AnalysisList) => {
    const editAnalysis: EditAnalysis = {
      analysisId: analysis._id,
      code: analysis.code,
      maturationId: analysis.maturationId,
      sampleWeight: analysis.sampleWeight?.toString(),
      stage: analysis.stage?.toString()
    };

    dispatch(analysisSlice.setEditDataAnalysis(editAnalysis));
  };

  function showConfirmHideAnalysis (record: AnalysisMapData) {
    const title = record.hidden ? t('analysis.includeConfirm.title.chart') : t('analysis.excludeConfirm.title.chart');
    let content: string | JSX.Element = record.hidden ? t('analysis.includeConfirm.description.chart') : t('analysis.excludeConfirm.description.chart');
    const idOkButton = record.hidden ? 'submit_include_chart' : 'submit_exclude_chart';
    const idCancelButton = record.hidden ? 'cancel_include_chart' : 'cancel_exclude_chart';

    if (isCampusInactive) {
      content = (
        <div className={styles.confirmModal}>
          <div className={styles.description}>
            {content}
          </div>
          <br />
          <div>
            <TinyLabel className={styles.campusInactive} text={t('analysis.inactiveCampus')} />
          </div>
        </div>
      );
    }

    lrvConfirm({
      theme: 'light',
      title: title,
      icon: <ExclamationCircleOutlined className={styles.exclamationIcon} />,
      content: content,
      okText: t('analysis.accept').toUpperCase(),
      cancelText: t('analysis.cancel').toUpperCase(),
      okButtonProps: { id: idOkButton, disabled: isCampusInactive },
      cancelButtonProps: { id: idCancelButton },
      onOk () {
        if (record.hidden) {
          dispatch(analysisSlice.includeAnalysisFromChart({ analysisId: record._id, stockingId, currentPage, companyId: stocking.companyId }));
        } else {
          dispatch(analysisSlice.excludeAnalysisFromChart({ analysisId: record._id, stockingId, currentPage, companyId: stocking.companyId }));
        }
      },
    });
  }

  function showConfirmArchiveAnalysis (record: AnalysisMapData) {
    const title = t('analysis.archiveConfirm.title');
    let content: string | JSX.Element = selectCompany.allowAutomaticConsolidation ? `${t('analysis.archiveConfirm.description.1')} ${t('analysis.archiveConfirm.description.3')}` : `${t('analysis.archiveConfirm.description.1')} ${t('analysis.archiveConfirm.description.2')}`;
    const idOkButton = 'submit_archive';
    const idCancelButton = 'cancel_archive';

    if (isCampusInactive) {
      content = (
        <div className={styles.confirmModal}>
          <div className={styles.description}>
            {content}
          </div>
          <br />
          <div>
            <TinyLabel className={styles.campusInactive} text={t('analysis.inactiveCampus')} />
          </div>
        </div>
      );
    }

    lrvConfirm({
      theme: 'light',
      title: title,
      icon: <ExclamationCircleOutlined className={styles.exclamationIcon} />,
      content: content,
      okText: t('analysis.accept').toUpperCase(),
      cancelText: t('analysis.cancel').toUpperCase(),
      okButtonProps: { id: idOkButton, disabled: isCampusInactive },
      cancelButtonProps: { id: idCancelButton },
      onOk () {
        if (record.isArchived) {
          dispatch(analysisSlice.unarchiveAnalysis({ analysisId: record._id, stockingId, currentPage, companyId: stocking.companyId }));
        } else {
          dispatch(analysisSlice.archiveAnalysis({ analysisId: record._id, stockingId, currentPage, companyId: stocking.companyId }));
        }
      },
    });
  }

  function showConfirmDesconsolidateAnalysis (record: AnalysisMapData) {
    const title = t('analysis.deconsolidateConfirm.title');
    const content = t('analysis.deconsolidateConfirm.description');
    const idOkButton = 'submit_desconsolidate';
    const idCancelButton = 'cancel_desconsolidate';

    lrvConfirm({
      theme: 'light',
      title: title,
      icon: <ExclamationCircleOutlined className={styles.exclamationIcon} />,
      content: content,
      okText: t('analysis.accept').toUpperCase(),
      cancelText: t('analysis.cancel').toUpperCase(),
      okButtonProps: { id: idOkButton },
      cancelButtonProps: { id: idCancelButton },
      onOk () {
        dispatch(analysisSlice.desconsolidateAnalysis({ analysisId: record._id, stockingId, currentPage, companyId: stocking.companyId }));
      },
    });
  }

  const onMoveAnalysis = (record: AnalysisMapData) => {
    dispatch(analysisSlice.setCurrentPage(currentPage));
    dispatch(analysisSlice.setAnalysisId(record._id));
    dispatch(analysisSlice.setShowModalMove(true));
    dispatch(analysisSlice.fetchStocking(record.stockingId));

    const unitPhaseType = getUnitPhaseTypeFromStocking(phaseTypeGlobal);
    dispatch(analysisSlice.fetchCampuses(stocking.companyId, unitPhaseType));
  };

  function menuItemAnalysisOption (record: AnalysisMapData) {
    const showRelaunchButton = isSuperAdmin() && ENVIRONMENT === environments.DEV;
    const showExcludeHideButton = getMainRole() !== roles.CLIENT_OPERATOR && phaseTypeGlobal !== stockingPhaseTypes.ADULT && record.status === analysisStatuses.COMPLETED;
    const showArchiveButton = record.status === analysisStatuses.COMPLETED;

    const idExcludeIncludeChart = record.hidden ? 'menu_option_show_chart' : 'menu_option_hide_chart';
    const iconExcludeIncludeChart = record.hidden ? 'eye' : 'eye-off';
    const titleExcludeIncludeChart = record.hidden ? t('analysis.showInGraph') : t('analysis.hideFromGraph');

    const idArchiveAnalysis = record.isArchived ? 'menu_option_unarchive' : 'menu_option_archive';
    const iconArchiveAnalysis = record.isArchived ? 'inbox-unarchive' : 'inbox-archive';
    const titleArchiveAnalysis = record.isArchived ? t('analysis.unarchive') : t('analysis.archive');

    if (isConsolidatedAnalysis(record.type)) {
      return (
        <Menu
          onClick={({ key, domEvent }) => {
            domEvent.stopPropagation();

            switch (key) {
              case analysisPatch.DESCONSOLIDATE:
                showConfirmDesconsolidateAnalysis(record);
                break;

              case analysisPatch.REMOVE_ANALYSIS:
                dispatch(analysisFromConsolidatedSlice.setAnalysis([]));
                dispatch(analysisFromConsolidatedSlice.setAnalysisConsolidated(record));
                dispatch(analysisFromConsolidatedSlice.setShowModal(true));
                break;
            }
          }}
        >
          <Menu.Item id='menu_option_desconsolidate' key={analysisPatch.DESCONSOLIDATE} className={styles.menuItemOptions}>
            <span>{t('analysis.desconsolidate')}</span>
          </Menu.Item>
          {
            (record.analysisIds && record.analysisIds.length > 2) &&
            <Menu.Item id='menu_option_remove_analysis_from_consolidated' key={analysisPatch.REMOVE_ANALYSIS} className={styles.menuItemOptions}>
              <span>{t('analysis.editAnalysis')}</span>
            </Menu.Item>
          }
        </Menu>
      );
    }

    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case analysisPatch.EDIT:
              fillEditFormAnalysis(record);
              dispatch(analysisSlice.setShowModalEdit(true));
              dispatch(analysisSlice.fetchSampleWeightLimits());
              break;

            case analysisPatch.MOVE:
              onMoveAnalysis(record);
              break;

            case analysisPatch.EXCLUDE_CHART:
              showConfirmHideAnalysis(record);
              break;

            case analysisPatch.ARCHIVE:
              showConfirmArchiveAnalysis(record);
              break;

            case analysisPatch.RELAUNCH:
              dispatch(analysisSlice.relaunchAnalysis(record.code, { analysisId: record._id }));
              break;
          }
        }}
      >
        {
          !record.coordinatesRemoved &&
          <Menu.Item id='menu_option_edit' key={analysisPatch.EDIT} className={styles.menuItemOptions}>
            <Icon name='edit' type='line' theme={theme} className={styles.icon} />
            <span>{t('analysis.edit')}</span>
          </Menu.Item>
        }

        <Menu.Item id='menu_option_move' key={analysisPatch.MOVE} className={styles.menuItemOptions}>
          <Icon name='drag-move-2' type='fill' theme={theme} className={styles.icon} />
          <span>{t('analysis.move.accept')}</span>
        </Menu.Item>

        {showExcludeHideButton && <Menu.Item id={idExcludeIncludeChart} key={analysisPatch.EXCLUDE_CHART} className={styles.menuItemOptions}>
          <Icon name={iconExcludeIncludeChart} type='line' theme={theme} className={styles.icon} />
          <span>{titleExcludeIncludeChart}</span>
        </Menu.Item>}

        {showArchiveButton && <Menu.Item id={idArchiveAnalysis} key={analysisPatch.ARCHIVE} className={styles.menuItemOptions}>
          <Icon name={iconArchiveAnalysis} type='line' theme={theme} className={styles.icon} />
          <span>{titleArchiveAnalysis}</span>
        </Menu.Item>}

        {showRelaunchButton && <Menu.Item id='menu_option_relaunch' key={analysisPatch.RELAUNCH} className={styles.menuItemOptions}>
          <Icon name='rocket' type='fill' theme={theme} className={cx(styles.icon, 'superAdminAction')} />
          <span>{t('analysis.relaunch')}</span>
        </Menu.Item>}
      </Menu>
    );
  }

  const getColumns = () => {
    const columnName = {
      key: 1,
      title: t('analysis.name'),
      dataIndex: 'code',
      ellipsis: { showTitle: false },
      width: '22%',
    };

    const columnSampleWeight = {
      key: 2,
      title: t('analysis.inputData.sampleWeight'),
      dataIndex: 'sampleWeight',
      width: '14%',
      ellipsis: { showTitle: false },
      responsive: ['md'] as Breakpoint[],
      render: (name: string) => name ? `${name} g` : '',
    };

    const columnStage = {
      key: 3,
      title: getStageColumnTitle(phaseTypeGlobal),
      dataIndex: 'stage',
      width: '9%',
      ellipsis: { showTitle: false },
      responsive: ['sm'] as Breakpoint[],
    };

    const columnLarvaeNumber = {
      key: 4,
      title: getPostLarvaeColumnTitle(phaseTypeGlobal),
      dataIndex: 'larvaeNumber',
      width: '12%',
      ellipsis: { showTitle: false },
      responsive: ['xl'] as Breakpoint[],
    };

    const columnPlg = {
      key: 5,
      title: getPlgColumnTitle(phaseTypeGlobal),
      dataIndex: getPlgDataIndex(phaseTypeGlobal),
      width: '7%',
      ellipsis: { showTitle: false },
      responsive: ['xl'] as Breakpoint[],
    };

    const columnDate = {
      key: 6,
      title: t('analysis.date'),
      dataIndex: 'createdAt',
      width: '15%',
      ellipsis: { showTitle: false },
      responsive: ['sm'] as Breakpoint[],
    };

    const columnOptions = {
      key: 7,
      dataIndex: 'option',
      ellipsis: { showTitle: false },
      width: '15%',
      render: (name: string, record: AnalysisMapData) => {
        return (
          <Space className={styles.tagsCell} align='center' size={8}>
            {renderTagsColumn(record)}
            {!stockingIsArchived && renderDropdownAnalysisOption(record)}
          </Space>
        );
      },
    };

    const analysisCard = analysisCards.find((item) => item.phaseType === phaseTypeGlobal);

    if (!analysisCard?.fields?.length) {
      const columns: ColumnsType<AnalysisMapData> = [
        columnName,
        columnSampleWeight,
        columnStage,
        columnLarvaeNumber,
        columnPlg,
        columnDate,
        columnOptions,
      ];

      return columns;
    }

    const columns: ColumnsType<AnalysisMapData> = [
      columnName,
    ];

    for (let index = 0; index < analysisCard.fields.length; index++) {
      const field = analysisCard.fields[index];

      const column = {
        key: index + 2,
        title: getLabelForKey({ phaseType: phaseTypeGlobal, field }),
        dataIndex: field,
        width: '15%',
        ellipsis: { showTitle: false },
        responsive: ['md'] as Breakpoint[],
      };

      columns.push(column);
    }

    columns.push(columnDate);
    columns.push(columnOptions);
    return columns;

  };

  const getRowClassName = (record: IAnalysis) => {
    if (consolidatedAnalysisSelected._id === record._id) {
      return cx(styles.analysisRow, styles.selectedRow, isLightTheme ? styles.selectedRowLight : styles.selectedRowDark);
    }

    if (consolidatedAnalysisSelected.analysisIds.includes(record._id)) {
      return cx(styles.analysisRow, styles.showAnalysis, isLightTheme ? styles.showAnalysisLight : styles.showAnalysisDark);
    }

    return styles.analysisRow;
  };

  return (
    <LrvTable
      columns={getColumns()}
      rowClassName={getRowClassName}
      className={styles.table}
      dataSource={analysisData}
      loading={isLoading}
      size='small'
      theme={theme}
      scroll={{ y: '' }}
      onRow={(record: AnalysisMapData) => {
        return {
          onClick: (e) => {
            e.stopPropagation();

            if (stockingIsArchived) {
              history.push(`/archive/analysis/${record._id}`);
            } else {
              history.push(`/production/analysis/${record._id}`);
            }
          },
        };
      }}
      pagination={{
        size: 'default',
        defaultPageSize: limit,
        pageSize: limit,
        total: total,
        onChange: onChangePage,
        showSizeChanger: false,
        current: currentPage
      }}
    />
  );
};
