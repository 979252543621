import moment from 'moment';
import { useState, useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Button, Collapse, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { dollarUS } from '../../utils/strings';
import { formatMonthYear } from '../../utils/date';
import { useDragger } from '../../hooks/useDragger';
import Icon from '../../common/components/Icon/Icon';
import { Store } from '../../state/store.interfaces';
import { getUserSession } from '../../utils/userSession';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { LrvSwitch } from '../../common/components/LrvSwitch/LrvSwitch';
import { LrvPopover } from '../../common/components/LrvPopover/LrvPopover';
import { LrvDragger } from '../../common/components/LrvDragger/LrvDragger';
import { LrvCollapse } from '../../common/components/LrvCollapse/LrvCollapse';
import { LrvRadioGroup } from '../../common/components/LrvRadioGroup/LrvRadioGroup';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvRangePicker } from '../../common/components/LrvRangePicker/LrvRangePicker';
import { LrvRadioButton } from '../../common/components/LrvRadioButton/LrvRadioButton';
import { applyIVA, isDistributorCompany, plansTypes, roundTwoDecimals } from '../../config/commons';

import * as paymentSlice from './paymentSlice';
import styles from './NewPostpaidPayment.module.scss';
import { CompanyPlan, DetailDistributorCompany, PlanPrice } from './interfaces';
import { calcIndividualPlanPrice, calcPlanPrice, editLocalStorage, generateIntermediateDates } from './helpers';

interface Props {
  theme?: 'dark' | 'light';
}

enum PaymentMethod {
  TRANSFER = 'TRANSFER',
  CARD = 'CARD',
  PAYPHONE = 'PAYPHONE',
}

const { Panel } = Collapse;

export default function NewPostpaidPayment (props: Props) {
  const { theme = 'dark' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [formPostpaidPayment] = Form.useForm();

  const { company } = useSelector((state: Store) => state.header);
  const {
    payphone,
    companyPlans,
    showPostpaidTransferModal,
    isLoadingPostpaidPayment,
    existsPaymentInTheSelectedMonth,
    isLoadingCompanyPlans,
    detailDistributorCompany,
    discountPercentage,
  } = useSelector((state: Store) => state.payments);

  const [months, setMonths] = useState<[string, string]>(['', '']);
  const [disabledButtonForm, setDisabledButtonForm] = useState(true);
  const [multipleMonths, setMultipleMonths] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.TRANSFER);

  const [subtotalPrice, setSubtotalPrice] = useState(0);
  const [bonusPrice, setBonusPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [planPrices, setPlanPrices] = useState<PlanPrice[]>([]);
  const [intermediateDates, setIntermediateDates] = useState<string[]>([]);

  const userSession = getUserSession();

  const {
    canvasRef,
    handleCustomRequest,
    handleOnChange,
    imageName,
    imageUrl,
    loadingImage,
    reducedImage,
    voucherHeight,
    voucherWidth,
    cleanImage,
  } = useDragger({});

  useEffect(() => {
    const isValidMonths = months.every(month => month.trim() !== '');

    if (!isValidMonths) {
      setIntermediateDates([]);
      return;
    }

    const intermediateDates: string[] = generateIntermediateDates(months);
    setIntermediateDates(intermediateDates);
  }, [months]);

  useEffect(() => {
    if (!showPostpaidTransferModal) {
      setIntermediateDates([]);
      return;
    }

    resetFormWhenCloseModal();
  }, [showPostpaidTransferModal]);

  useEffect(() => {
    if (!company._id || !intermediateDates?.length) {
      return;
    }

    dispatch(paymentSlice.checkPayments({ companyId: company._id, months: intermediateDates }));
    dispatch(paymentSlice.fetchCompanyPlans({ companyId: company._id, months: intermediateDates }));

    if (company.isDistributor && company.assignedCompanies?.length) {
      dispatch(paymentSlice.fetchDiscountPorcentageCompanyBalance(company._id));
      dispatch(paymentSlice.fetchDetailDistributorCompany({ companyId: company._id, months: intermediateDates }));
    }
  }, [dispatch, company._id, company.isDistributor, company.assignedCompanies, intermediateDates]);

  useEffect(() => {
    if (!company?._id || isLoadingCompanyPlans || !companyPlans.length || !intermediateDates?.length) {
      return;
    }
    
    setTotalPrice(0);
    if (existsPaymentInTheSelectedMonth) {
      formPostpaidPayment.setFieldsValue(
        {
          amount: '',
        }
      );
      return;
    }
    
    const planPrices = company.isDistributor ? calcPlanPrice(detailDistributorCompany) : calcPlanPrice(companyPlans);
    setPlanPrices(planPrices);
    
    let bonusPrice: number = planPrices.reduce((acc, planPrice) => acc + planPrice.bonusPrice, 0);
    let subtotalPrice: number = planPrices.reduce((acc, planPrice) => acc + planPrice.subtotalPrice, 0);
    let totalPrice: number = planPrices.reduce((acc, planPrice) => acc + planPrice.totalPrice, 0);
    
    if (company.isDistributor) {
      bonusPrice = bonusPrice - ((discountPercentage * bonusPrice) / 100);
      subtotalPrice = subtotalPrice - ((discountPercentage * subtotalPrice) / 100);
      totalPrice = totalPrice - ((discountPercentage * totalPrice) / 100);
    }
    
    let value = totalPrice;

    if (!company.isInternational) {
      value = applyIVA(value);
    }

    setBonusPrice(bonusPrice);
    setSubtotalPrice(subtotalPrice);
    setTotalPrice(value);

    formPostpaidPayment.setFieldsValue(
      {
        amount: dollarUS.format(value),
      }
    );

    if (value > 0 && (paymentMethod === PaymentMethod.CARD || paymentMethod === PaymentMethod.PAYPHONE)) {
      dispatch(paymentSlice.fetchButtonPrepare({ amount: Math.round(value * 100) }));
    }

  }, [dispatch, company._id, company.isInternational, company.isDistributor, existsPaymentInTheSelectedMonth, formPostpaidPayment, companyPlans, detailDistributorCompany, isLoadingCompanyPlans, paymentMethod, intermediateDates]);

  const renderDraggerFile = () => {
    return (
      <div>
        <p>
          <InboxOutlined />
        </p>
        <p >{t('payments.drag')}</p>
      </div>
    );
  };

  const onFieldsChangeForm = () => {
    if (paymentMethod === PaymentMethod.CARD || paymentMethod === PaymentMethod.PAYPHONE) {
      return;
    }

    const amount = formPostpaidPayment.getFieldValue('amount');
    const months = formPostpaidPayment.getFieldValue('months');
    const image = formPostpaidPayment.getFieldValue('image');

    const isValidForm = !!(amount && months && image);
    setDisabledButtonForm(!(isValidForm));
  };

  const resetFormWhenCloseModal = () => {
    cleanImage();
    formPostpaidPayment.resetFields();

    formPostpaidPayment.setFieldsValue(
      {
        amount: '',
        payWith: PaymentMethod.TRANSFER,
      }
    );

    dispatch(paymentSlice.setCompanyPlans([]));
    dispatch(paymentSlice.setDetailDistributorCompany([]));

    setMonths(['', '']);
    setMultipleMonths(false);
    setPaymentMethod(PaymentMethod.TRANSFER);

    setTotalPrice(0);
    setBonusPrice(0);
    setSubtotalPrice(0);
  };

  const resetFormWhenSelectDate = () => {
    setTotalPrice(0);
    setBonusPrice(0);
    setSubtotalPrice(0);

    dispatch(paymentSlice.setCompanyPlans([]));

    formPostpaidPayment.setFieldsValue(
      {
        amount: '',
      }
    );
  };

  const resetFormWhenChangeMethodPayment = () => {
    setTotalPrice(0);
    setBonusPrice(0);
    setSubtotalPrice(0);

    dispatch(paymentSlice.setCompanyPlans([]));
    setMonths(['', '']);

    formPostpaidPayment.setFieldsValue(
      {
        months: '',
        amount: '',
      }
    );
  };

  const onCloseModal = () => {
    dispatch(paymentSlice.setShowPostpaidTransferModal(false));
    resetFormWhenCloseModal();
  };

  const onSavePayment = () => {
    const prices = planPrices.map((planPrice) => {
      if (!company.isInternational) {
        return applyIVA(planPrice.totalPrice);
      }

      return planPrice.totalPrice;
    });

    switch (paymentMethod) {
      case PaymentMethod.TRANSFER:
        onSavePostpaidTransfer();
        break;

      case PaymentMethod.CARD:
        editLocalStorage({ paymentType: plansTypes.POSTPAID, months: JSON.stringify(intermediateDates), prices: JSON.stringify(prices), quota: 0, url: payphone.payWithCard });
        break;

      case PaymentMethod.PAYPHONE:
        editLocalStorage({ paymentType: plansTypes.POSTPAID, months: JSON.stringify(intermediateDates), prices: JSON.stringify(prices), quota: 0, url: payphone.payWithPayPhone });
        break;
    }
  };

  const onSavePostpaidTransfer = () => {
    const totalPrices = planPrices.map((planPrice) => {
      if (!company.isInternational) {
        return applyIVA(planPrice.totalPrice);
      }

      return planPrice.totalPrice;
    });

    const params = {
      key: imageName,
      dataUrl: reducedImage,
      amounts: totalPrices,
      months: intermediateDates,
      companyId: company._id,
      userId: userSession._id,
    };

    dispatch(paymentSlice.savePostpaidTransfer(params));
  };

  const renderRadioGroup = () => {
    return (
      <Form.Item
        name='payWith'
        label={t('payments.payWith')}
      >
        <LrvRadioGroup
          theme={theme}
          id='radio_group'
          value={paymentMethod}
          className={styles.radioGroup}
          onChange={(event) => {
            const value = event.target.value;
            setPaymentMethod(value);
            resetFormWhenChangeMethodPayment();
          }}
        >
          <LrvRadioButton
            theme={theme}
            className={styles.radioButtonLeft}
            value={PaymentMethod.TRANSFER}
          >
            <div id='transfer_button'>{t('payments.transfer')}</div>
          </LrvRadioButton>

          <LrvRadioButton
            theme={theme}
            className={styles.radioButton}
            value={PaymentMethod.CARD}
          >
            <div id='card_button'>{t('payments.card')}</div>
          </LrvRadioButton>

          <LrvRadioButton
            theme={theme}
            className={styles.radioButtonRight}
            value={PaymentMethod.PAYPHONE}
          >
            <div id='payphone_button'>{t('payments.payphone')}</div>
          </LrvRadioButton>
        </LrvRadioGroup>
      </Form.Item>
    );
  };

  const renderMultipleMonths = () => {
    return (
      <Form.Item
        name='multipleMonths'
        label={t('payments.multipleMonths')}
      >
        <LrvSwitch
          theme={theme}
          checked={multipleMonths}
          onChange={(value) => {
            setDisabledButtonForm(true);
            cleanImage();

            formPostpaidPayment.setFieldsValue(
              {
                months: '',
                amount: '',
              }
            );

            setMonths(['', '']);
            setMultipleMonths(value);
          }}
        />
      </Form.Item>
    );
  };

  const renderDate = () => {
    if (multipleMonths) {
      return (
        <LrvRangePicker
          theme={theme}
          picker='month'
          allowClear={false}
          placeholder={['', '']}
          onChange={(date, dateString) => {
            resetFormWhenSelectDate();
            setMonths(dateString);
          }}
        />
      );
    }

    return (
      <LrvDatePicker
        theme={theme}
        picker='month'
        allowClear={false}
        disabledDate={disabledDate}
        placeholder=''
        onChange={(date) => {
          const dateSelected = date?.toString() as string;
          resetFormWhenSelectDate();
          setMonths([dateSelected, dateSelected]);
        }}
      />
    );
  };

  const renderMonths = () => {
    return (
      <Form.Item
        name='months'
        label={multipleMonths ? t('payments.paymentMonths') : t('payments.paymentMonth')}
        required
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        {renderDate()}
      </Form.Item>
    );
  };

  const renderVoucher = () => {
    return (
      <Form.Item
        name='image'
        label={t('payments.voucher')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <div>
          <LrvDragger
            theme={theme}
            name='file'
            accept='image/png, image/jpeg'
            maxCount={1}
            listType='picture-card'
            showUploadList={false}
            beforeUpload={() => true}
            onChange={handleOnChange}
            customRequest={handleCustomRequest}
          >
            {imageUrl && !loadingImage ? <img src={imageUrl} alt='imageVoucher' style={{ width: '100%', height: 'inherit' }} /> : renderDraggerFile()}
          </LrvDragger>

          <canvas
            className={styles.canvas}
            ref={canvasRef}
            width={voucherWidth}
            height={voucherHeight}
          />
        </div>
      </Form.Item>
    );
  };

  const disableCreatePaymentButton = () => {
    const isValidMonths = months.every(month => month.trim() !== '');
    const disabledPayWithCard = !payphone.payWithCard || !isValidMonths || (existsPaymentInTheSelectedMonth || totalPrice <= 0);
    const disabledPayWithPayPhone = !payphone.payWithPayPhone || !isValidMonths || (existsPaymentInTheSelectedMonth || totalPrice <= 0);

    switch (paymentMethod) {
      case PaymentMethod.TRANSFER:
        return disabledButtonForm;

      case PaymentMethod.CARD:
        return disabledPayWithCard;

      case PaymentMethod.PAYPHONE:
        return disabledPayWithPayPhone;

      default:
        return true;
    }
  };

  const renderCreatePaymentButton = () => {
    return (
      <Form.Item>
        <Button
          type='primary'
          disabled={disableCreatePaymentButton()}
          loading={isLoadingPostpaidPayment}
          onClick={onSavePayment}
          className={styles.createButton}
        >
          &nbsp;{t('payments.register')}
        </Button>
      </Form.Item>
    );
  };

  const disabledDate = (currentDate: moment.Moment) => {
    return currentDate && currentDate > moment().add(1, 'month').endOf('month');
  };

  const renderAmount = () => {
    let value = dollarUS.format(totalPrice);

    if (totalPrice !== 0 && company.isDistributor) {
      value = `${dollarUS.format(totalPrice)} (${t('clients.discount')} ${discountPercentage}%)`;
    }

    return (
      <Form.Item
        className={styles.itemForm}
        name='amount'
        required
        label={
          <div className={styles.containerLabel}>
            <div>{t('payments.amount')} &nbsp; </div>
            {
              totalPrice !== subtotalPrice &&
              <LrvPopover
                content={() =>
                  <div>
                    <div>
                      {t('payments.detail.subtotalPrice', { subtotalPrice: dollarUS.format(subtotalPrice) })}
                    </div>
                    <div>
                      {t('payments.detail.bonusPrice', { bonusPrice: dollarUS.format(roundTwoDecimals(bonusPrice)) })}
                    </div>
                    <div>
                      {t('payments.detail.valueWithoutIVA', { valueWithoutIVA: dollarUS.format(roundTwoDecimals(subtotalPrice + bonusPrice)) })}
                    </div>
                    {
                      !company.isInternational &&
                      <div>
                        {t('payments.detail.valueWithIVA', { valueWithIVA: dollarUS.format(roundTwoDecimals(totalPrice)) })}
                      </div>
                    }
                  </div>
                }
                title={t('payments.detail.detailPayment')}
              >
                <QuestionCircleOutlined />
              </LrvPopover>
            }
          </div>
        }
      >
        <div className={styles.price}>
          <LrvText
            text={value}
            theme={theme}
          />
        </div>
      </Form.Item>
    );
  };

  const columns: ColumnsType<DetailDistributorCompany> = [
    {
      key: 1,
      title: t('payments.userPayer'),
      dataIndex: 'companyName',
      width: '35%',
    },
    {
      key: 2,
      title: t('payments.paymentDate'),
      dataIndex: 'paymentDate',
      width: '32%',
      render: (_, record: DetailDistributorCompany) => formatMonthYear(record.paymentDate),
    },
    {
      key: 3,
      title: t('users.quota'),
      dataIndex: 'quota',
      width: '15%',
    },
    {
      key: 4,
      title: t('payments.amount'),
      dataIndex: 'total',
      width: '18%',
      render: (_, record: DetailDistributorCompany) => {
        const companyPlan: CompanyPlan = {
          bonusQuota: record.bonusQuota,
          quota: record.quota,
          planPrice: record.planPrice,
          planPriceOverride: record.planPriceOverride,
        };

        const price = calcIndividualPlanPrice(companyPlan);
        return company.isInternational ? dollarUS.format(price.totalPrice) : dollarUS.format(applyIVA(price.totalPrice));
      },
    },
  ];

  const renderDetailPayments = () => {
    if (!isDistributorCompany() || !intermediateDates.length) {
      return null;
    }

    return (
      <Form.Item>
        <LrvCollapse
          theme={theme}
        >
          <Panel key='1' header={t('payments.detail.detailPayment')}>
            <LrvTable
              id='table_detailt_payment'
              className={styles.table}
              columns={columns}
              dataSource={detailDistributorCompany}
              pagination={false}
              size='small'
              scroll={{ y: 200 }}
              theme={theme}
            />
          </Panel>
        </LrvCollapse>
      </Form.Item>
    );
  };

  return (
    <LrvModal
      theme={theme}
      className={styles.postpaidModal}
      title={t('payments.newPayment')}
      open={showPostpaidTransferModal}
      destroyOnClose={true}
      cancelButtonProps={{ id: 'cancel_new_postpaid_payment' }}
      onCancel={onCloseModal}
      closeIcon={<Icon name='close' />}
      footer={() => (
        <div></div>
      )}
    >
      <LrvForm
        theme={theme}
        form={formPostpaidPayment}
        name='formPostpaidPayment'
        id='formPostpaidPayment'
        layout='vertical'
        onFieldsChange={onFieldsChangeForm}
      >
        {renderRadioGroup()}
        {renderMultipleMonths()}
        {renderMonths()}
        {renderAmount()}
        {renderDetailPayments()}
        {paymentMethod === PaymentMethod.TRANSFER && renderVoucher()}
        {renderCreatePaymentButton()}
      </LrvForm>
    </LrvModal>
  );
}
