import * as d3 from 'd3';
import cx from 'classnames';

import { applyThousandsSeparator } from '../../../utils/strings';

import { TransferMetricEntry } from './interfaces';
import styles from './TransferGraphD3.module.scss';
import { renderTickFormatXAxis } from './helpers';

const TIME_TRANSITION = 300;
const TICK_PADDING = 6;
const START_POSITION_Y = 0;

interface Props {
  container: HTMLDivElement | null;
  transferMetrics: TransferMetricEntry[];
  width: number;
  height: number;
}

export class TransferGraphD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined> = d3.select<SVGSVGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'svg'));
  groupMain: d3.Selection<SVGGElement, unknown, null, undefined>;

  x: d3.ScaleBand<string> = d3.scaleBand();
  y: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never> = d3.scaleLinear();

  axisBottom: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  axisLeft: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  transferMetrics: TransferMetricEntry[] = [];
  width: number;
  height: number;
  margin = { top: 20, right: 30, bottom: 22, left: 60 };

  // eslint-disable-next-line
  constructor(props: Props) {
    const { container, transferMetrics, width, height } = props;

    this.container = container;
    this.transferMetrics = transferMetrics;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    d3.select(container).select('svg').remove();

    this.svg = d3.select(container)
      .append('svg')
      .attr('id', 'svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'content')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top} )`);

    this.updateDataPoints();
  }

  updateDataPoints = () => {
    this.renderLinesAxis();

    this.buildAxisX();
    this.buildAxisY();

    this.deleteDomainAxisX();

    this.drawXAxis();
    this.drawYAxis();

    this.renderLines();
    this.renderPoints();
  };

  generateLines = () => {
    const line = d3.line<TransferMetricEntry>()
      .x(d => (this.x(d.name) || 0) + (this.x.bandwidth() / 2))
      .y(d => this.y(d.value))
      .curve(d3.curveCatmullRom);

    return { line };
  };

  renderLines = () => {
    const { transferMetrics, groupMain } = this;

    const { line } = this.generateLines();

    groupMain
      .append('path')
      .datum(transferMetrics)
      .attr('class', styles.transferLines)
      .attr('d', line);
  };

  renderPoints () {
    const { groupMain, transferMetrics } = this;

    groupMain.append('g')
      .selectAll('circle')
      .data(transferMetrics)
      .enter()
      .append('circle')
      .attr('class', cx('points', styles.circle))
      .attr('r', 7)
      .attr('cx', (capsule) => (this.x(capsule.name) || 0) + (this.x.bandwidth() / 2))
      .attr('cy', (point) => this.y(point.value));
  }

  deleteDomainAxisX () {
    const { container } = this;
    d3.select(container).select('#content').select('#axisX').selectAll('.domain').remove();
  }

  renderLinesAxis = () => {
    const { container, groupMain, height, width } = this;

    d3.select(container).selectAll('.lineAxisY').remove();
    d3.select(container).selectAll('.lineAxisX').remove();

    groupMain.append('line')
      .attr('class', cx(styles.lines, 'lineAxisY'))
      .attr('y1', START_POSITION_Y)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', 0);

    groupMain.append('line')
      .attr('class', cx(styles.lines, 'lineAxisY'))
      .attr('y1', START_POSITION_Y)
      .attr('y2', height)
      .attr('x1', width)
      .attr('x2', width);

    groupMain.append('line')
      .attr('class', cx(styles.lines, 'lineAxisX'))
      .attr('y1', height)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', width);

    groupMain.append('line')
      .attr('class', cx(styles.lines, 'lineAxisX'))
      .attr('y1', START_POSITION_Y)
      .attr('y2', START_POSITION_Y)
      .attr('x1', 0)
      .attr('x2', width);
  }

  updateAxis () {
    const { height } = this;

    this.renderLinesAxis();

    this.buildAxisX();
    this.buildAxisY();

    const axisBottom = d3.axisBottom(this.x)
      .tickFormat((d) => renderTickFormatXAxis({ domainValue: d }))
      .tickSize(-height)
      .tickPadding(TICK_PADDING);

    this.axisBottom
      .attr('fill', 'transparent')
      .attr('transform', `translate(0, ${this.height})`)
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    this.deleteDomainAxisX();

    const axiLeft = d3.axisLeft(this.y)
      .tickFormat((d) => d3.format('')(d as number))
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.axisLeft.transition()
      .duration(TIME_TRANSITION)
      .call(axiLeft);
  }

  updateData () {
    this.updateAxis();
  }

  refreshChart (props: { transferMetrics: TransferMetricEntry[]; width: number; height: number; }) {
    const { transferMetrics, width, height } = props;
    const { container } = this;

    this.transferMetrics = transferMetrics;

    d3.select(container).select('#tooltipContent').remove();
    d3.select(container).selectAll('.points').remove();

    this.updateSize(width, height);
    this.updateData();
  }

  buildAxisX () {
    const { width, transferMetrics } = this;

    this.x = d3.scaleBand()
      .domain(transferMetrics.map(capsule => capsule.name))
      .range([0, width])
      .padding(0.1);
  }

  buildAxisY () {
    const { height } = this;
    const { maxY, minY } = this.getYDomainData();

    this.y = d3.scaleLinear()
      .domain([minY, maxY])
      .range([height, 0]);
  }

  getYDomainData = () => {
    const { transferMetrics } = this;

    const margin = 0.02;
    const dataY: number[] = transferMetrics.map((item) => item.value);

    const minY = Math.min(...dataY);
    const maxY = Math.max(...dataY);

    if (minY === 0) {
      return {
        minY,
        maxY: maxY + (maxY * margin),
      };
    }

    return {
      minY: minY - (minY * margin),
      maxY: maxY + (maxY * margin),
    };
  }

  drawXAxis () {
    const { height, groupMain } = this;

    const axisBottom = d3.axisBottom(this.x)
      .tickFormat((d) => renderTickFormatXAxis({ domainValue: d }))
      .tickSize(-height)
      .tickPadding(TICK_PADDING);

    this.axisBottom = groupMain.append('g')
      .attr('id', 'axisX')
      .attr('class', cx(styles.axisX, styles.axisLight))
      .attr('transform', `translate(0, ${this.height})`)
      .call(axisBottom);

    this.deleteDomainAxisX();
  }

  drawYAxis () {
    const { groupMain } = this;

    const axis = d3.axisLeft(this.y)
      .tickFormat((d) => applyThousandsSeparator(d.toString()))
      .ticks(5)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.axisLeft = groupMain.append('g')
      .attr('class', cx(styles.axisY, styles.axisLight))
      .call(axis);
  }

  resize = (props: { width: number, height: number }) => {
    const { width, height } = props;
    const { container } = this;

    d3.select(container).select('#content').selectAll('*').remove();

    this.updateSize(width, height);
    this.updateAxis();
    this.updateDataPoints();
  };

  updateSize = (width: number, height: number) => {
    const { container } = this;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    const _width = this.width + this.margin.left + this.margin.right;
    const _height = this.height + this.margin.top + this.margin.bottom;

    d3.select(container).select('svg')
      .attr('width', _width)
      .attr('height', _height);
  };
}
