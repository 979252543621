import moment from 'moment';
import React, { useEffect } from 'react';
import { Row, Select, Space, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { filterOptionSelect } from '../../../utils/select';
import { changeHeader } from '../../AppHeader/headerSlice';
import { DropdownProps } from '../../../common/interfaces/commons';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import { LrvTabs } from '../../../common/components/LrvTabs/LrvTabs';
import IconButton from '../../../common/components/buttons/IconButton';
import CleanButton from '../../../common/components/buttons/CleanButton';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { LrvDatePicker } from '../../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvFilterPanel } from '../../../common/components/LrvSideFloatingPanel/LrvFilterPanel';
import { CONTAINER_LABEL, species, stockingPhaseTypes, unitStatuses } from '../../../config/commons';

import styles from './SurvivalRate.module.scss';
import SurvivalRateChart from './SurvivalRateChart';
import * as survivalRateSlice from './survivalRateSlice';
import SurvivalRateHistogram from './SurvivalRateHistogram';
import { disabledFromDateHistogram, disabledToDateHistogram, SURVIVAL_AVG } from './helpers';

const { TabPane } = Tabs;
const { Option } = Select;

const SurvivalRate = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const {
    filters: filtersSurvivalRate,
    tabSelected,
    campuses,
    modules,
    tanks,
    company,
    isLoadingStockingPdf,
  } = useSelector((state: Store) => state.survivalRate);

  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);
  const companyAvg = company?.survivalAvg;

  const theme = getCurrentTheme();

  useEffect(() => {
    dispatch(changeHeader({ title: 'survivalRate.title' }));
  }, [dispatch]);

  useEffect(() => {
    if (!selectedCompany._id) {
      return;
    }

    dispatch(survivalRateSlice.resetSurvivalRate({ company: selectedCompany, phaseType }));
  }, [dispatch, selectedCompany, phaseType]);

  useEffect(() => {
    if (!selectedCompany._id) {
      return;
    }

    const paramsSurvivalRate = {
      fromDate: filtersSurvivalRate.fromDate,
      toDate: filtersSurvivalRate.toDate,
      type: SURVIVAL_AVG,
      species: species.LITOPENAEUS_VANNAMEI,
      companyId: selectedCompany._id,
      phaseType: phaseType,
      campusId: filtersSurvivalRate.campus?._id,
      moduleId: filtersSurvivalRate.moduleId,
      tankId: filtersSurvivalRate.tankId,
    };

    dispatch(survivalRateSlice.fetchSurvivalRate(paramsSurvivalRate));

    const paramsHistogram = {
      fromDate: filtersSurvivalRate.fromDate,
      toDate: filtersSurvivalRate.toDate,
      companyId: selectedCompany._id,
      phaseType: phaseType,
      campusId: filtersSurvivalRate.campus?._id,
      moduleId: filtersSurvivalRate.moduleId,
      tankId: filtersSurvivalRate.tankId,
    };

    dispatch(survivalRateSlice.fetchSurvivalRateHistogram(paramsHistogram));
  }, [dispatch, selectedCompany, phaseType, filtersSurvivalRate.fromDate, filtersSurvivalRate.toDate, filtersSurvivalRate.campus?._id, filtersSurvivalRate.moduleId, filtersSurvivalRate.tankId]);

  const renderUnitsDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_campuses_survival_rate'
        theme={theme}
        className={className}
        showSearch
        value={filtersSurvivalRate?.campus?.name || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('survivalRate.selectCampus')}
        placeholder={t('survivalRate.selectCampus')}
        onChange={changeCampus}
        dropdownMatchSelectWidth={false}
        disabled={!companyAvg}
        filterOption={filterOptionSelect}
      >
        {campuses.map((campus) => {
          return (
            <Option key={campus._id} value={campus._id} label={campus.name}>
              {campus.name}
              {
                campus.status === unitStatuses.INACTIVE
                &&
                <>
                  &nbsp; &nbsp;
                  <LrvTag color='#e04d00'>{t('campus.inactive')}</LrvTag>
                </>
              }
            </Option>
          );
        })}
      </LrvSelect>
    );
  };

  const renderModulesDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        showSearch
        id='dropdown_modules_survival_rate'
        theme={theme}
        className={className}
        value={filtersSurvivalRate?.moduleId || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('survivalRate.selectModule')}
        placeholder={t('survivalRate.selectModule')}
        onChange={changeModule}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
        disabled={!companyAvg || !filtersSurvivalRate?.campus?._id}
      >
        {modules.map((module) => <Option key={module._id} value={module._id}>{module.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderTanksDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_tanks_survival_rate'
        theme={theme}
        className={className}
        showSearch
        value={filtersSurvivalRate?.tankId || undefined}
        suffixIcon={< Icon name='arrow-down-s' />}
        title={t(`survivalRate.selectTank.${CONTAINER_LABEL[phaseType || stockingPhaseTypes.LARVAE]}`)}
        placeholder={t(`survivalRate.selectTank.${CONTAINER_LABEL[phaseType || stockingPhaseTypes.LARVAE]}`)}
        onChange={changeTank}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
        disabled={!companyAvg || !filtersSurvivalRate.moduleId}
      >
        {tanks.map((tank) => <Option key={tank._id} value={tank._id}>{tank.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderFromDatePicker = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvDatePicker
        id='from_date_picker'
        theme={theme}
        className={className}
        value={moment(filtersSurvivalRate.fromDate)}
        defaultValue={moment(filtersSurvivalRate.fromDate)}
        disabledDate={(value) => disabledFromDateHistogram(value, filtersSurvivalRate.toDate)}
        allowClear={false}
        title={t('survivalRate.dateFrom')}
        placeholder={t('survivalRate.dateFrom')}
        disabled={!companyAvg}
        onChange={onChangeFromDate}
      />
    );
  };

  const renderToDatePicker = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvDatePicker
        id='to_date_picker'
        theme={theme}
        className={className}
        value={moment(filtersSurvivalRate.toDate)}
        defaultValue={moment(filtersSurvivalRate.toDate)}
        disabledDate={(value) => disabledToDateHistogram(value, filtersSurvivalRate.fromDate)}
        allowClear={false}
        title={t('survivalRate.dateTo')}
        placeholder={t('survivalRate.dateTo')}
        disabled={!companyAvg}
        onChange={onChangeToDate}
      />
    );
  };

  const changeCampus = (value: string) => {
    const campus = campuses.find((campus) => campus._id === value);

    if (campus) {
      const filters = { ...filtersSurvivalRate };
      filters.campus = campus;
      filters.moduleId = undefined;
      filters.tankId = undefined;

      dispatch(survivalRateSlice.setSurvivalRateFilters(filters));
      dispatch(survivalRateSlice.fetchModules(campus._id));
    }
  };

  const changeModule = (value: string) => {
    const filters = { ...filtersSurvivalRate };
    filters.moduleId = value;
    filters.tankId = undefined;

    dispatch(survivalRateSlice.setSurvivalRateFilters(filters));
    dispatch(survivalRateSlice.fetchTanks(value));
  };

  const changeTank = (value: string) => {
    const filters = { ...filtersSurvivalRate };
    filters.tankId = value;

    dispatch(survivalRateSlice.setSurvivalRateFilters(filters));
  };

  const onChangeFromDate = (date: moment.Moment | null) => {
    const dateSelected = date?.toString() as string;
    const filters = { ...filtersSurvivalRate };
    filters.fromDate = dateSelected;
    dispatch(survivalRateSlice.setSurvivalRateFilters(filters));
  };

  const onChangeToDate = (date: moment.Moment | null) => {
    const dateSelected = date?.toString() as string;
    const filters = { ...filtersSurvivalRate };
    filters.toDate = dateSelected;
    dispatch(survivalRateSlice.setSurvivalRateFilters(filters));
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon={true}
          title={<div className={styles.title}>{t('survivalRate.title')}</div>}
          cleanButtonProps={{
            onClick: () => {
              dispatch(survivalRateSlice.resetSurvivalRate({ company: selectedCompany, phaseType }));
            },
          }}
        >
          <Space
            direction='vertical'
            className={styles.bodyPanel}
          >
            {renderUnitsDropdown({ theme: 'light' })}
            {renderModulesDropdown({ theme: 'light' })}
            {renderTanksDropdown({ theme: 'light' })}
            {renderFromDatePicker({ theme: 'light' })}
            {renderToDatePicker({ theme: 'light' })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const generatePdf = () => {
    const params = {
      phaseType: phaseType,
      fromDate: filtersSurvivalRate.fromDate,
      toDate: filtersSurvivalRate.toDate,
      companyId: selectedCompany._id,
      campusId: filtersSurvivalRate.campus?._id,
      moduleId: filtersSurvivalRate.moduleId,
      tankId: filtersSurvivalRate.tankId,
    };
    dispatch(survivalRateSlice.fetchStockingsHarvested(params));
  };

  const renderButtonGeneratePdf = () => {
    if (selectedCompany.allowXlsxAnalysisReport !== true) {
      return null;
    }

    return (
      <IconButton
        className={styles.generatePdf}
        loading={isLoadingStockingPdf}
        onClick={generatePdf}
        iconName='download'
        placement='left'
        tooltipText={t('survivalRate.generate')}
      />
    );
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={theme}
        onClick={() => dispatch(survivalRateSlice.resetSurvivalRate({ company: selectedCompany, phaseType }))}
      />
    );
  };

  const renderSubHeader = () => {
    return (
      <Row className={styles.header}>
        <Space className={styles.filters} align='end'>
          {renderUnitsDropdown({ className: styles.select, theme })}
          {renderModulesDropdown({ className: styles.select, theme })}
          {renderTanksDropdown({ className: styles.select, theme })}
          {renderFromDatePicker({ className: styles.select, theme })}
          {renderToDatePicker({ className: styles.select, theme })}
          {renderCleanButton()}
        </Space>
        {renderSidePanel()}

        <Row className={styles.rowRight}>
          {renderButtonGeneratePdf()}
        </Row>
      </Row>
    );
  };

  return (
    <div className={styles.survivalRate}>
      {renderSubHeader()}

      <LrvTabs
        defaultActiveKey={tabSelected}
        tabPosition='top'
        theme={theme}
        onChange={(key) => dispatch(survivalRateSlice.setTabSelected(key))}
      >
        <TabPane tab={<div id='tab_survival_rate_chart'>{t('survivalRate.byWeek')}</div>} key='1'>
          <SurvivalRateChart theme={theme} />
        </TabPane>

        <TabPane tab={<div id='tab_survival_rate_histogram'>{t('survivalRate.histogram')}</div>} key='2'>
          <SurvivalRateHistogram theme={theme} />
        </TabPane>
      </LrvTabs>
    </div>
  );
};

export default SurvivalRate;
