import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { changeHeader } from '../../AppHeader/headerSlice';
import { weightUnitsByCompany } from '../../../config/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { LrvCheckbox } from '../../../common/components/LrvCheckbox/LrvCheckbox';

import styles from './WeightUnit.module.scss';
import * as weigthUnitSlice from './weigthUnitSlice';

export const WeightUnit = () => {
  const theme = getCurrentTheme();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [poundUnitSelected, setPoundUnitSelected] = useState<boolean>(false);
  const [kilogramUnitSelected, setKilogramUnitSelected] = useState<boolean>(false);

  const { company } = useSelector((state: Store) => state.weigthUnit);
  const disableSaveButton = !poundUnitSelected && !kilogramUnitSelected;

  useEffect(() => {
    dispatch(weigthUnitSlice.fetchCompanySetting());
    dispatch(changeHeader({ title: 'weightUnit.unit' }));
  }, [dispatch]);

  useEffect(() => {
    switch (company.weightUnit) {
      case weightUnitsByCompany.KILOGRAM:
        setKilogramUnitSelected(true);
        break;

      case weightUnitsByCompany.POUND:
        setPoundUnitSelected(true);
        break;

      case weightUnitsByCompany.KILOGRAM_AND_POUND:
        setKilogramUnitSelected(true);
        setPoundUnitSelected(true);
        break;
    }
  }, [company]);

  return (
    <div className={styles.container}>
      <LrvForm
        id='formCompanySetting'
        layout='vertical'
        theme={theme}
        onFinish={() => {
          if (disableSaveButton) {
            return;
          }

          if (poundUnitSelected && kilogramUnitSelected) {
            dispatch(weigthUnitSlice.updateCompanySetting({ weightUnit: weightUnitsByCompany.KILOGRAM_AND_POUND }));
            return;
          }

          if (poundUnitSelected) {
            dispatch(weigthUnitSlice.updateCompanySetting({ weightUnit: weightUnitsByCompany.POUND }));
            return;
          }

          dispatch(weigthUnitSlice.updateCompanySetting({ weightUnit: weightUnitsByCompany.KILOGRAM }));
        }}
      >
        <Form.Item>
          <div>
            <LrvText theme={theme} text={t('weightUnit.description')} />
          </div>
        </Form.Item>

        <Form.Item
          required
          label={t('weightUnit.unit')}
        >
          <div className={styles.weightUnit}>
            <div>
              <LrvCheckbox
                theme={theme}
                onChange={event => setKilogramUnitSelected(event.target.checked)}
                checked={kilogramUnitSelected}
                className={styles.checkBox}
              >
                {t('weightUnit.kilogram')}
              </LrvCheckbox>
            </div>

            <div>
              <LrvCheckbox
                theme={theme}
                onChange={event => setPoundUnitSelected(event.target.checked)}
                checked={poundUnitSelected}
                className={styles.checkBox}
              >
                {t('weightUnit.pound')}
              </LrvCheckbox>
            </div>
          </div>
        </Form.Item>

        <Form.Item>
          <div className={styles.alignButtonRight}>
            <ActionButton
              id='submit_unit_button'
              type='primary'
              htmlType='submit'
              disabled={disableSaveButton}
            >
              {t('weightUnit.save')}
            </ActionButton>
          </div>
        </Form.Item>
      </LrvForm>
    </div>

  );
};
