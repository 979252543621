import moment from 'moment';
import i18next from 'i18next';
import { groupBy, meanBy, sumBy } from 'lodash';

import { DATE_FORMATS, roundFourDecimals, roundTwoDecimals, stockingPhaseTypes } from '../../../config/commons';
import { getCurrentElementHeight } from '../../../utils/dimensions';
import { applyThousandsSeparator } from '../../../utils/strings';
import { calculatePercentile } from '../../../utils/percentile';

import { StockingQuadrant } from './interfaces';

export const COLOR_LEGEND_WIDTH = 200;

export const SUCCESS_QUADRANT_PARAMETERS = {
  SURVIVAL_RATE: 'SURVIVAL_RATE',
  WEEKLY_GROWTH: 'WEEKLY_GROWTH',
  DENSITY: 'DENSITY',
  HARVEST_DENSITY: 'HARVEST_DENSITY',
  UNIFORMITY: 'UNIFORMITY',
  MATURATION_NAME: 'MATURATION_NAME',
  GENETIC_CODE: 'GENETIC_CODE',
  PREVIOUS_ORIGIN: 'PREVIOUS_ORIGIN',
  LABORATORIES: 'LABORATORIES',
  CONTAINER: 'CONTAINER',
  MODULE: 'MODULE',
  PRODUCTIVITY: 'PRODUCTIVITY',
  STOCKING_ANIMALS: 'STOCKING_ANIMALS',
  HARVEST_QUANTITY: 'HARVEST_QUANTITY',
};

const numericFields: (keyof StockingQuadrant)[] = ['uniformity', 'survivalRate', 'stockingDensity', 'harvestDensity', 'productivity', 'weeklyGrowth', 'quadrant'];

export const getXAxisValues = (props: { quadrantData: StockingQuadrant[]; xAxis: string; }) => {
  const { quadrantData, xAxis } = props;

  switch (xAxis) {
    case SUCCESS_QUADRANT_PARAMETERS.DENSITY: {
      return quadrantData.map((item) => item.stockingDensity);
    }

    case SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY: {
      return quadrantData.map((item) => item.harvestDensity);
    }

    case SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY: {
      return quadrantData.map((item) => item.uniformity);
    }

    case SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE: {
      return quadrantData.map((item) => item.survivalRate);
    }

    case SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY: {
      return quadrantData.map((item) => item.productivity);
    }

    case SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH:
    default: {
      return quadrantData.map((item) => item.weeklyGrowth);
    }
  }
};

export const getAxisValue = (props: { quadrantData: StockingQuadrant; axis: string; }) => {
  const { quadrantData, axis } = props;

  switch (axis) {
    case SUCCESS_QUADRANT_PARAMETERS.DENSITY: {
      return quadrantData.stockingDensity;
    }

    case SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY: {
      return quadrantData.harvestDensity;
    }

    case SUCCESS_QUADRANT_PARAMETERS.STOCKING_ANIMALS: {
      return quadrantData.stockingAnimals;
    }

    case SUCCESS_QUADRANT_PARAMETERS.HARVEST_QUANTITY: {
      return quadrantData.harvestQuantity;
    }

    case SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY: {
      return quadrantData.uniformity;
    }

    case SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE: {
      return quadrantData.survivalRate;
    }

    case SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY: {
      return quadrantData.productivity;
    }

    case SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH:
    default: {
      return quadrantData.weeklyGrowth;
    }
  }
};

export const getMargin = (props: { axis: string; }) => {
  const { axis } = props;

  switch (axis) {
    case SUCCESS_QUADRANT_PARAMETERS.DENSITY:
    case SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY:
    case SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY:
    case SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE: {
      return 2;
    }

    case SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY: {
      return 1;
    }

    case SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH:
    default: {
      return 0.0001;
    }
  }
};

export function getXAxisMinMax (props: { quadrantData: StockingQuadrant[]; xAxis: string; }) {
  const { quadrantData, xAxis } = props;
  const values = getXAxisValues({ quadrantData, xAxis });

  const min = Math.min(...values);
  const max = Math.max(...values);

  return {
    min: min - (max * 0.02),
    max: max + (max * 0.02),
  };
}

export const renderTickFormatXAxis = (props: { domainValue: d3.AxisDomain; }) => {
  const { domainValue } = props;
  return domainValue.toString();
};

export const getNumberTicks = (props: { xAxis: string }) => {
  const { xAxis } = props;

  switch (xAxis) {
    case SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH:
      return 5;

    default:
      return undefined;
  }
};

interface HeightProps {
  filters?: React.RefObject<HTMLDivElement>;
  densityDisabled: boolean;
}

export const getHeightOfTheOtherElements = (props: HeightProps) => {
  const { filters, densityDisabled } = props;

  const headerHeight = 64;
  const axisXHeight = 24;
  const labelTopHeight = 24;
  const extraHeight = densityDisabled ? 84 : 64;

  if (!filters) {
    return axisXHeight + headerHeight + extraHeight + labelTopHeight;
  }

  return getCurrentElementHeight(filters) + axisXHeight + headerHeight + extraHeight + labelTopHeight;
};

export const getWidthOfTheOtherElements = () => {
  const extraWidth = 40;

  if (window.innerWidth <= 950) {
    return extraWidth;
  }

  if (window.innerWidth <= 1420) {
    return extraWidth + 90;
  }

  return 250 + extraWidth;
};

export const getFromDate = (lastDays: number) => {
  return moment(new Date()).subtract(lastDays, 'days').format(DATE_FORMATS.YYYY_MM_DD);
};

export const getToDate = () => {
  return moment(new Date()).format(DATE_FORMATS.YYYY_MM_DD);
};

export const disabledDateFrom = (current: moment.MomentInput, toDate: Date) => {
  const minDays = 180;
  const start = moment().subtract(minDays, 'days');
  const end = moment(toDate);
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const disabledDateTo = (current: moment.MomentInput, fromDate: Date) => {
  const start = moment(fromDate).add(1, 'days');
  const end = moment();
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const disableGroupDataCheckbox = (zAxis: string) => {
  const availableItems = [
    SUCCESS_QUADRANT_PARAMETERS.PREVIOUS_ORIGIN, SUCCESS_QUADRANT_PARAMETERS.LABORATORIES,
    SUCCESS_QUADRANT_PARAMETERS.MATURATION_NAME, SUCCESS_QUADRANT_PARAMETERS.GENETIC_CODE,
  ];

  return !availableItems.some(item => item === zAxis);
};

export const getQuadrantGroupField = (zAxis: string): keyof StockingQuadrant | undefined => {
  switch (zAxis) {
    case SUCCESS_QUADRANT_PARAMETERS.PREVIOUS_ORIGIN:
      return 'previousOrigin';
  
    case SUCCESS_QUADRANT_PARAMETERS.LABORATORIES:
      return 'laboratories';

    case SUCCESS_QUADRANT_PARAMETERS.MATURATION_NAME:
      return 'maturationName';

    case SUCCESS_QUADRANT_PARAMETERS.GENETIC_CODE:
      return 'maturationCode';

    default:
      return undefined;
  }
};

export const groupStockingQuadrantData = (props: { quadrantData: StockingQuadrant[]; xAxis: string; yAxis: string; zAxis: string; }) => {
  const { zAxis, quadrantData } = props;
  const groupField = getQuadrantGroupField(zAxis);
  
  if (!groupField) {
    return quadrantData;
  }

  const groupedData = groupBy(quadrantData, groupField);

  return Object.values(groupedData).map((group) => {
    // Get the first element of the group to use as a base
    const base = group[0];
    const stockingCount = group.length;

    // Calculate avg for numeric fields
    const avgFields = numericFields.reduce((acc, field) => {
      acc[field] = roundTwoDecimals(meanBy(group, field));

      if (field === 'stockingDensity' || field === 'harvestDensity') {
        acc[field] = Math.floor(Number(acc[field]));
      }

      return acc;
    }, {} as Partial<Record<keyof StockingQuadrant, number>>);
    
    const stockingAnimals = sumBy(group, 'stockingAnimals');
    const harvestQuantity = sumBy(group, 'harvestQuantity');

    const stockingQuadrant: StockingQuadrant = {
      ...base,
      ...avgFields,
      stockingAnimals,
      harvestQuantity,
      stockingCount,
      [groupField]: base[groupField], // Keep the value of the grouping field without duplicates
    } as StockingQuadrant;

    // Return the new object with new values
    return stockingQuadrant;
  });
};

export const filterQuadrantDataByOutliers = (props: { quadrantData: StockingQuadrant[]; xAxis: string; yAxis: string; }) => {
  const { xAxis, yAxis, quadrantData } = props;

  let stockingQuadrant = filterOutliers({ data: quadrantData, accessor: item => getAxisValue({ quadrantData: item, axis: xAxis }) });
  stockingQuadrant = filterOutliers({ data: quadrantData, accessor: item => getAxisValue({ quadrantData: item, axis: yAxis }) });
  return stockingQuadrant;
};

const filterOutliers = (props: { data: StockingQuadrant[]; accessor: (item: StockingQuadrant) => number; iqrMultiplier?: number }): StockingQuadrant[] => {
  const { data, accessor, iqrMultiplier = 1.5 } = props;

  const values = data.map(accessor);

  const q1 = calculatePercentile(values, 25);
  const q3 = calculatePercentile(values, 75);

  const iqr = q3 - q1;

  const min = q1 - (iqrMultiplier * iqr);
  const max = q3 + (iqrMultiplier * iqr);

  return data.filter(item => {
    const value = accessor(item);
    return min <= value && value <= max;
  });
};

export const getAxisTooltipItem = (props: { quadrantData: StockingQuadrant; axis: string; phaseType: string }) => {
  const { axis, phaseType, quadrantData } = props;
  let weeklyGrowth = 0;
  const unitWeight = phaseType === stockingPhaseTypes.LARVAE ? i18next.t('quadrant.miligramPerDay') : i18next.t('quadrant.gramPerWeek');
  const tooltipInfo = { axisLabel: '', axisValue: '' };

  switch (axis) {
    case SUCCESS_QUADRANT_PARAMETERS.DENSITY:
      tooltipInfo.axisLabel = i18next.t('quadrant.stockingDensity');
      tooltipInfo.axisValue = `${applyThousandsSeparator(quadrantData.stockingDensity)} ${quadrantData.densityUnit}`;
      break;

    case SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY:
      tooltipInfo.axisLabel = i18next.t('quadrant.harvestDensity');
      tooltipInfo.axisValue = `${applyThousandsSeparator(quadrantData.harvestDensity)} ${quadrantData.densityUnit}`;
      break;

    case SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH:
      weeklyGrowth = phaseType === stockingPhaseTypes.LARVAE ? roundFourDecimals(quadrantData.weeklyGrowth) : roundTwoDecimals(quadrantData.weeklyGrowth);
      tooltipInfo.axisLabel = phaseType === stockingPhaseTypes.LARVAE ? i18next.t('quadrant.dailyGrowth') : i18next.t('quadrant.weeklyGrowth');
      tooltipInfo.axisValue = `${weeklyGrowth} ${unitWeight}`;
      break;

    case SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY:
      tooltipInfo.axisLabel = i18next.t('quadrant.uniformity');
      tooltipInfo.axisValue = `${roundTwoDecimals(quadrantData.uniformity)} %`;
      break;

    case SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE:
      tooltipInfo.axisLabel = i18next.t('quadrant.survivalRate');
      tooltipInfo.axisValue = `${roundTwoDecimals(quadrantData.survivalRate)} %`;
      break;

    case SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY:
      tooltipInfo.axisLabel = i18next.t('quadrant.productivity');
      tooltipInfo.axisValue = `${roundTwoDecimals(quadrantData.productivity)} ${i18next.t('quadrant.productivityUnit')}`;
      break;
  }

  return tooltipInfo;
};

export const getAxisLabel = (props: { axis: string; phaseType: string; }) => {
  const { axis, phaseType } = props;

  switch (axis) {
    case SUCCESS_QUADRANT_PARAMETERS.DENSITY:
    default:
      return i18next.t('quadrant.stockingDensity');

    case SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY:
      return i18next.t('quadrant.harvestDensity');

    case SUCCESS_QUADRANT_PARAMETERS.STOCKING_ANIMALS:
      return i18next.t('quadrant.stockingAnimals');

    case SUCCESS_QUADRANT_PARAMETERS.HARVEST_QUANTITY:
      return i18next.t('quadrant.harvestQuantity');

    case SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH: {
      const unitWeight = phaseType === stockingPhaseTypes.LARVAE ? i18next.t('quadrant.miligramPerDay') : i18next.t('quadrant.gramPerWeek');
      const label = phaseType === stockingPhaseTypes.LARVAE ? i18next.t('quadrant.dailyGrowth') : i18next.t('quadrant.weeklyGrowth');

      return `${label} ${unitWeight}`;
    }

    case SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY:
      return `${i18next.t('quadrant.uniformity')} %`;

    case SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE:
      return `${i18next.t('quadrant.survivalRate')} %`;

    case SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY:
      return `${i18next.t('quadrant.productivity')} ${i18next.t('quadrant.productivityUnit')}`;

    case SUCCESS_QUADRANT_PARAMETERS.MATURATION_NAME:
      return i18next.t('quadrant.maturation');

    case SUCCESS_QUADRANT_PARAMETERS.GENETIC_CODE:
      return i18next.t('quadrant.maturationCode');

    case SUCCESS_QUADRANT_PARAMETERS.PREVIOUS_ORIGIN:
      return i18next.t('quadrant.previousOrigin');

    case SUCCESS_QUADRANT_PARAMETERS.LABORATORIES:
      return i18next.t('quadrant.laboratories');

    case SUCCESS_QUADRANT_PARAMETERS.CONTAINER:
      return i18next.t('quadrant.container');

    case SUCCESS_QUADRANT_PARAMETERS.MODULE:
      return i18next.t('quadrant.module');
  }
};
