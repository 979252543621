import moment from 'moment';
import cx from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Dropdown, Menu, Tabs, Select } from 'antd';

import { Campus } from '../interfaces';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../../utils/select';
import { getUserSession } from '../../../utils/userSession';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvTabs } from '../../../common/components/LrvTabs/LrvTabs';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { formatYearMonthDay, getHoursOffset } from '../../../utils/date';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { LrvTooltip } from '../../../common/components/LrvTooltip/LrvTooltip';
import { LrvDatePicker } from '../../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvRadioGroup } from '../../../common/components/LrvRadioGroup/LrvRadioGroup';
import { LrvRadioButton } from '../../../common/components/LrvRadioButton/LrvRadioButton';
import { LrvCancelButton } from '../../../common/components/LrvCancelButton/LrvCancelButton';
import { typeExtension, typeReport, xlsxReportTypes, stockingPhaseTypes, xlsxReportTabs, unitStatuses, typeBiometricsReport } from '../../../config/commons';
import { setXlsxReportFilters, setXlsxReportData, setTypeReportCsv, setTypeFile, fetchXlsxReportData, setShowXlsxReport, setTabSelected, setBiometricsDate, setUnitSelected, setTypeBiometricsReport, fetchXlsxBiometricsData } from '../sowingsSlice';

import './XlsxReport.scss';
import styles from './XlsxReport.module.scss';

const { TabPane } = Tabs;
const { Option } = Select;

interface XlsxData {
  mimeType: string;
  extension: string;
  data: string;
}

interface Props {
  theme?: 'dark' | 'light';
}

function XlsxReport (props: Props) {
  const { theme = 'dark' } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const isLoadingDataReport = useSelector((state: Store) => state.stockings.xlsxReport.isLoading);
  const typeFile = useSelector((state: Store) => state.stockings.xlsxReport.typeFile);
  const typeReportCsv = useSelector((state: Store) => state.stockings.xlsxReport.typeReportCsv);
  const fromDate = useSelector((state: Store) => state.stockings.xlsxReport.fromDate);
  const toDate = useSelector((state: Store) => state.stockings.xlsxReport.toDate);
  const biometricsDate = useSelector((state: Store) => state.stockings.xlsxReport.biometricsDate);
  const xlsxData = useSelector((state: Store) => state.stockings.xlsxReport.data as XlsxData);
  const tabSelected = useSelector((state: Store) => state.stockings.xlsxReport.tabSelected);
  const typeBiometrics = useSelector((state: Store) => state.stockings.xlsxReport.typeBiometrics);
  const unitSelected = useSelector((state: Store) => state.stockings.xlsxReport.unit);
  const unitsFilter = useSelector((state: Store) => state.stockings.filters.campuses);

  const showXlsxAnalysisReport = useSelector((state: Store) => state.stockings.xlsxReport.showModal);
  const { phaseType: phaseTypeSelected, company } = useSelector((state: Store) => state.header);

  const userSession = getUserSession();

  useEffect(() => {
    if (xlsxData.data) {
      const link = document.createElement('a');
      if (xlsxData.extension === `.${typeExtension.XLSX}`) {
        link.href = `data:${xlsxData.mimeType};base64,${xlsxData.data}`;
      } else if (xlsxData.extension === `.${typeExtension.CSV}`) {
        link.href = `data:${xlsxData.mimeType};charset=utf-8,${xlsxData.data}`;
      }

      link.setAttribute('download', `${'DATA'}${xlsxData.extension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      return (() => {
        dispatch(setXlsxReportData({}));
      });
    }
  }, [dispatch, xlsxData]);

  function onCancel () {
    dispatch(setTabSelected(xlsxReportTabs.DATABASE));
    dispatch(setShowXlsxReport(false));
    dispatch(setTypeBiometricsReport(typeBiometricsReport.WEIGHT_GROUP));
    dispatch(setUnitSelected(''));
  }

  function disabledDateFrom (current: moment.Moment) {
    const minDays = 1365;
    const start = moment().subtract(minDays, 'days');
    const end = moment(toDate);
    return !(start.isSameOrBefore(current) && end.isAfter(current));
  }

  function disabledDateTo (current: moment.Moment) {
    const start = moment(fromDate).add(1, 'days');
    const end = moment();
    return !(start.isSameOrBefore(current) && end.isAfter(current));
  }

  function renderSelectFormatIcon () {
    switch (typeFile) {
      case typeExtension.XLSX:
      default:
        return (
          <div className={styles.typeFile}>
            <Icon name='file-excel-2' className={styles.icon} />
            <div className={styles.format}>
              {t('stockings.xlsxReport.formats.xlsx').toUpperCase()}
            </div>
            <Icon name='arrow-down-s' type='fill' className={styles.icon} />
          </div>
        );

      case typeExtension.CSV:
        return (
          <div className={styles.typeFile}>
            <Icon name='file-text' className={styles.icon} />
            <div className={styles.format}>
              {t('stockings.xlsxReport.formats.csv').toUpperCase()}
            </div>
            <Icon name='arrow-down-s' type='fill' className={styles.icon} />
          </div>
        );
    }
  }

  const renderMenuFormat = () => {
    return (
      <Menu onClick={(e) => dispatch(setTypeFile(e.key))}>
        <Menu.Item key={typeExtension.XLSX}>
          <div className={styles.radioButton}>
            <Icon name='file-excel-2' theme={theme} />
            &nbsp;
            {t('stockings.xlsxReport.excel')}
          </div>
        </Menu.Item>
        <Menu.Item key={typeExtension.CSV}>
          <div className={styles.radioButton}>
            <Icon name='file-text' theme={theme} />
            &nbsp;
            {t('stockings.xlsxReport.csv')}
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  function renderTypeReportCsv () {
    if (typeFile !== typeExtension.CSV) {
      return null;
    }

    return (
      <div className={styles.filterTypeFile}>
        <LrvText
          theme={theme}
          className={styles.dataToDownload}
          text={t('stockings.xlsxReport.dataToDownload')}
        />
        <LrvRadioGroup
          theme={theme}
          value={typeReportCsv}
          onChange={(event) => dispatch(setTypeReportCsv(event.target.value))}
          className={styles.reportType}
        >
          <LrvRadioButton
            theme={theme}
            value={typeReport.ANALYSIS}
            className={styles.radioButtonLeft}
          >
            <div id='global_source_button'>{t('stockings.xlsxReport.analysis')}</div>
          </LrvRadioButton>

          <LrvRadioButton
            theme={theme}
            value={typeReport.STOCKINGS}
            className={styles.radioButtonRight}
          >
            <div id='company_source_button'>{t('stockings.xlsxReport.stockings')}</div>
          </LrvRadioButton>
        </LrvRadioGroup>
      </div>
    );
  }

  function getXlsxReportData () {
    const data = {
      fromDate: formatYearMonthDay(fromDate),
      toDate: formatYearMonthDay(toDate),
      companyId: company._id,
      type: xlsxReportTypes.ANALYSIS_DATA,
      phaseType: phaseTypeSelected,
      typeFile: typeFile,
      typeReportCsv: typeReportCsv,
      userLang: userSession.language,
      hoursOffset: getHoursOffset(),
    };

    dispatch(fetchXlsxReportData(data));
  }

  function getXlsxBiometricsData () {
    const data = {
      companyId: company._id,
      biometricsDate: formatYearMonthDay(biometricsDate),
      unitId: unitSelected,
      typeBiometrics,
      phaseType: phaseTypeSelected,
      userLang: userSession.language,
      hoursOffset: getHoursOffset(),
    };

    dispatch(fetchXlsxBiometricsData(data));
  }

  const getTitle = () => {
    let phaseTypeLabel = '';
    switch (phaseTypeSelected) {
      case stockingPhaseTypes.LARVAE:
      default:
        phaseTypeLabel = `(${t('stockings.xlsxReport.phases.larvae')})`;
        break;

      case stockingPhaseTypes.JUVENILE:
        phaseTypeLabel = `(${t('stockings.xlsxReport.phases.juvenile')})`;
        break;

      case stockingPhaseTypes.ADULT:
        phaseTypeLabel = `(${t('stockings.xlsxReport.phases.growOut')})`;
        break;
    }

    switch (tabSelected) {
      case xlsxReportTabs.DATABASE:
      default:
        return `${t('stockings.xlsxReport.title.database')} ${phaseTypeLabel}`;

      case xlsxReportTabs.BIOMETRICS:
        return `${t('stockings.xlsxReport.title.biometrics')} ${phaseTypeLabel}`;
    }
  };

  const renderDatabaseTabContainer = () => {
    return (
      <div className={cx(styles.containerFull, styles.databaseContainer)}>
        <Row gutter={20} className={styles.row}>
          <div className={styles.description}>
            <div>
              <LrvText className={styles.text} text={`* ${t('stockings.xlsxReport.description.analysis')}`} theme={theme} />
            </div>
            <div>
              <LrvText className={styles.text} text={`* ${t('stockings.xlsxReport.description.stocking')}`} theme={theme} />
            </div>
          </div>
          <div className={styles.filters}>
            <LrvDatePicker
              id='from_date_picker'
              theme={theme}
              className={styles.datePicker}
              title={t('stockings.xlsxReport.dateFrom')}
              value={moment(fromDate)}
              defaultValue={moment(fromDate)}
              allowClear={false}
              disabledDate={disabledDateFrom}
              placeholder={t('stockings.xlsxReport.dateFrom')}
              onChange={(date) => {
                const dateSelected = date?.toString() as string;
                const filters = { fromDate: dateSelected, toDate };
                dispatch(setXlsxReportFilters(filters));
              }}
            />

            <LrvDatePicker
              id='to_date_picker'
              theme={theme}
              className={styles.datePicker}
              title={t('stockings.xlsxReport.dateTo')}
              value={moment(toDate)}
              defaultValue={moment(toDate)}
              allowClear={false}
              disabledDate={disabledDateTo}
              placeholder={t('stockings.xlsxReport.dateTo')}
              onChange={(date) => {
                const dateSelected = date?.toString() as string;
                const filters = {
                  fromDate,
                  toDate: dateSelected
                };
                dispatch(setXlsxReportFilters(filters));
              }}
            />


            {renderTypeReportCsv()}
          </div>
        </Row>

        <Row gutter={20} className={styles.rowButtons}>
          <LrvCancelButton
            theme={theme}
            type='text'
            onClick={onCancel}
          >
            {t('stockings.cancel').toUpperCase()}
          </LrvCancelButton>

          <Dropdown.Button
            type='primary'
            loading={isLoadingDataReport}
            disabled={(isLoadingDataReport || (!fromDate) || (!toDate))}
            className={cx(styles.dropdown, 'dropdown')}
            overlay={renderMenuFormat}
            placement='bottom'
            icon={renderSelectFormatIcon()}
            onClick={getXlsxReportData}
          >
            <>
              <Icon name='download' className={styles.downloadIcon} />
              {t('stockings.xlsxReport.download').toUpperCase()}
            </>
          </Dropdown.Button>
        </Row>
      </div>
    );
  };

  const renderUnitSelect = () => {
    return (
      <LrvSelect
        id='dropdown_campus'
        showSearch
        theme={theme}
        containerClassName={styles.select}
        value={unitSelected ? unitSelected : undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        dropdownMatchSelectWidth={false}
        title={t('stockings.selectCampus')}
        placeholder={t('stockings.selectCampus')}
        onChange={(value) => {
          const unit: Campus | undefined = unitsFilter.find((item: Campus) => item._id === value);
          if (!unit?._id) {
            return;
          }

          dispatch(setUnitSelected(unit._id));
        }}
        filterOption={filterOptionSelect}
      >
        {unitsFilter.map((unit) => {
          return (
            <Option key={unit._id} value={unit._id} label={unit.name}>
              {unit.name}
              {
                unit.status === unitStatuses.INACTIVE
                &&
                <>
                  &nbsp; &nbsp;
                  <LrvTag type='info'>{t('campus.inactive')}</LrvTag>
                </>
              }
            </Option>
          );
        })}
      </LrvSelect>
    );
  };

  const renderBiometricsReportType = () => {
    return (
      <div className={styles.filterTypeFile}>
        <LrvText
          theme={theme}
          className={styles.dataToDownload}
          text={t('stockings.xlsxReport.dataToDownload')}
        />
        <LrvRadioGroup
          theme={theme}
          value={typeBiometrics}
          onChange={(event) => dispatch(setTypeBiometricsReport(event.target.value))}
          className={styles.reportType}
        >
          <LrvRadioButton
            theme={theme}
            value={typeBiometricsReport.WEIGHT_GROUP}
            className={styles.radioButtonLeft}
          >
            <div id='global_source_button'>{t('stockings.xlsxReport.weightGroup')}</div>
          </LrvRadioButton>

          <LrvRadioButton
            theme={theme}
            value={typeBiometricsReport.COMMERCIAL_SIZES}
            className={styles.radioButtonRight}
            disabled={phaseTypeSelected !== stockingPhaseTypes.ADULT}
          >
            <LrvTooltip
              themeStyle={theme}
              title={phaseTypeSelected === stockingPhaseTypes.ADULT ? undefined : t('stockings.xlsxReport.commercialSizesTootlip')}
            >
              <div id='company_source_button'>{t('stockings.xlsxReport.commercialSizes')}</div>
            </LrvTooltip>
          </LrvRadioButton>
        </LrvRadioGroup>
      </div>
    );
  };

  const renderBiometricsTabContainer = () => {
    return (
      <div className={cx(styles.containerFull, styles.biometricsContainer)}>
        <Row gutter={20} className={styles.row}>
          <div className={styles.filters}>
            <LrvDatePicker
              id='from_date_picker'
              theme={theme}
              className={styles.datePicker}
              title={t('stockings.xlsxReport.date')}
              value={moment(biometricsDate)}
              defaultValue={moment(biometricsDate)}
              allowClear={false}
              placeholder={t('stockings.xlsxReport.dateFrom')}
              onChange={(date) => {
                const dateSelected = date?.toString() as string;
                dispatch(setBiometricsDate(dateSelected));
              }}
            />

            {renderUnitSelect()}

            {renderBiometricsReportType()}
          </div>
        </Row>

        <Row gutter={20} className={styles.rowButtons}>
          <LrvCancelButton
            theme={theme}
            type='text'
            onClick={onCancel}
          >
            {t('stockings.cancel').toUpperCase()}
          </LrvCancelButton>

          <ActionButton
            theme={theme}
            type='primary'
            containerClassName={styles.downloadButton}
            loading={isLoadingDataReport}
            disabled={(isLoadingDataReport || (!biometricsDate) || (!unitSelected))}
            className={cx(styles.dropdown, 'dropdown')}
            onClick={getXlsxBiometricsData}
          >
            <div className={styles.description}>
              <Icon name='download' className={styles.downloadIcon} />
              {t('stockings.xlsxReport.download').toUpperCase()}
            </div>
            <div className={styles.typeFile}>
              <Icon name='file-excel-2' className={styles.icon} />
              <div className={styles.format}>
                {t('stockings.xlsxReport.formats.xlsx').toUpperCase()}
              </div>
            </div>
          </ActionButton>
        </Row>
      </div>
    );
  };

  return (
    <LrvModal
      theme={theme}
      className='xlsxReportModal'
      title={getTitle()}
      open={showXlsxAnalysisReport}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={null}
      cancelText={t('stockings.cancel')}
    >
      <LrvTabs
        theme={theme}
        defaultActiveKey={tabSelected}
        onChange={(key) => dispatch(setTabSelected(key))}
      >
        <TabPane tab={<div id='tab_database'>{t('stockings.xlsxReport.tabs.database')}</div>} key={xlsxReportTabs.DATABASE}>
          {renderDatabaseTabContainer()}
        </TabPane>
        {phaseTypeSelected !== stockingPhaseTypes.LARVAE &&
          <TabPane tab={<div id='tab_database'>{t('stockings.xlsxReport.tabs.biometrics')}</div>} key={xlsxReportTabs.BIOMETRICS}>
            {renderBiometricsTabContainer()}
          </TabPane>
        }
      </LrvTabs>
    </LrvModal>
  );
}

export default XlsxReport;
