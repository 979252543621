import i18next from 'i18next';

import { weightUnits } from '../../../config/commons';
import { TransferMetrics, TransferMetricEntry } from '../../Reports/TransferGraph/interfaces';

/* eslint-disable max-depth */
export const organizeDataByVariable = (transferParameters: TransferMetrics) => {
  const result: Record<string, TransferMetricEntry[]> = {};

  // Iterate over each key in the input object (e.g., Capsule-1, Capsule-2, etc.)
  for (const transferParameter in transferParameters) {
    const variables = transferParameters[transferParameter];

    // Iterate over the variables of each key (e.g., averageWeight, animalsInCapsule, etc.)
    for (const variable in variables) {
      const value = variables[variable];

      // If there is no list for this variable yet, initialize it
      if (!result[variable]) {
        result[variable] = [];
      }

      // Add the object { name, value } to the list corresponding to the variable
      result[variable].push({
        name: transferParameter,
        value,
      });
    }
  }

  return result;
};

export const getUnit = (props: {parameter: string}) => {
  const { parameter } = props;

  switch (parameter) {
    case 'animalsInCapsule':
      return 'juv';

    case 'animalsPerCubicMeter':
      return 'juv/m³';

    case 'animalsSmallerThan':
    case 'averageWeight':
      return weightUnits.G ;
    
    case 'transferredInCapsule':
      return weightUnits.KG;

    case 'uniformityWeight':
    case 'variationCoefficientWeight':
      return '%';
  
    default:
      return '';
  }
};

export const getTitleGraph = (props: {parameter: string; maxSmallAnimalsWeight: number}) => {
  const { parameter, maxSmallAnimalsWeight } = props;

  if (parameter === 'animalsSmallerThan') {
    return i18next.t(`transfers.parameters.${parameter}`, { value: maxSmallAnimalsWeight });
  }

  return i18next.t(`transfers.parameters.${parameter}`);
};
