import { useState } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../../utils/select';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../../common/components/LrvInput/LrvInput';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { LrvInputNumber } from '../../../common/components/LrvInputNumber/LrvInputNumber';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../../utils/strings';

import * as transportSlice from './transportSlice';
import { PumpType, TransportType } from './interfaces';
import { diameterValues, pumpTypes, transportTypes, flowRateValues, capacityCapsuleValues } from './helper';

const { Option } = Select;

export const NewTransportModal = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [transportType, setTransportType] = useState<TransportType>('CAPSULE');

  const [plate, setPlate] = useState('');
  const [driver, setDriver] = useState<string>();
  const [capacity, setCapacity] = useState<number>();

  const [pumpName, setPumpName] = useState('');
  const [pumpType, setPumpType] = useState<PumpType | undefined>();
  const [diameter, setDiameter] = useState<number>();
  const [flowRate, setFlowRate] = useState<number>();

  const { showNewModal } = useSelector((state: Store) => state.transports);

  const closeModal = () => {
    dispatch(transportSlice.setShowNewModal(false));
    dispatch(transportSlice.setTransport(undefined));

    setTransportType('CAPSULE');

    setPlate('');
    setDriver(undefined);
    setCapacity(undefined);

    setPumpName('');
    setPumpType(undefined);
    setDiameter(undefined);
    setFlowRate(undefined);

    form.resetFields();
  };

  const renderTransportTypeInput = () => {
    return (
      <Form.Item
        required
        name='transportType'
        label={t('transports.type')}
        rules={[{ required: true }]}
        valuePropName='defaultValue'
        initialValue={transportType}
      >
        <LrvSelect
          theme='light'
          showSearch
          suffixIcon={<Icon name='arrow-down-s' />}
          placeholder={t('transports.type')}
          optionFilterProp='children'
          value={transportType}
          onChange={setTransportType}
          filterOption={filterOptionSelect}
          dropdownMatchSelectWidth={false}
        >
          <Option key={transportTypes.CAPSULE} value={transportTypes.CAPSULE}>{t(`transports.types.${transportTypes.CAPSULE}`)}</Option>
          <Option key={transportTypes.PUMP} value={transportTypes.PUMP}>{t(`transports.types.${transportTypes.PUMP}`)}</Option>
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderPlateInput = () => {
    return (
      <Form.Item
        label={t('transports.plate')}
        required
        name='plate'
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvInput
          theme='light'
          placeholder={t('transports.plate')}
          autoFocus
          value={plate}
          onChange={(e) => setPlate(e.target.value)}
        />
      </Form.Item>
    );
  };

  const renderDriverInput = () => {
    return (
      <Form.Item
        label={`${t('transports.driver')} (${t('common.optional')})`}
        required
        name='driver'
      >
        <LrvInput
          theme='light'
          placeholder={t('transports.driver')}
          autoFocus
          value={driver}
          onChange={(e) => setDriver(e.target.value)}
        />
      </Form.Item>
    );
  };

  const renderCapacityInput = () => {
    return (
      <Form.Item
        name='capacity'
        label={`${t('transports.capacity')} (${t('common.optional')})`}
        required
      >
        <LrvInputNumber
          theme='light'
          placeholder={t('transports.capacity')}
          value={capacity}
          formatter={applyThousandsSeparator}
          parser={applyParserThousandsSeparator}
          min={capacityCapsuleValues.MIN}
          max={capacityCapsuleValues.MAX}
          onChange={(value) => {
            if (value === null) {
              return;
            }

            setCapacity(parseInt(value.toString()));
          }}
        />
      </Form.Item>
    );
  };

  const renderPumpNameInput = () => {
    return (
      <Form.Item
        label={t('transports.pumpName')}
        required
        name='pumpName'
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvInput
          theme='light'
          placeholder={t('transports.pumpName')}
          autoFocus
          value={pumpName}
          onChange={(e) => setPumpName(e.target.value)}
        />
      </Form.Item>
    );
  };

  const renderPumpTypeInput = () => {
    return (
      <Form.Item
        name='pumpType'
        label={`${t('transports.pumpType')} (${t('common.optional')})`}
        required
      >
        <LrvSelect
          theme='light'
          showSearch
          suffixIcon={<Icon name='arrow-down-s' />}
          placeholder={t('transports.pumpType')}
          optionFilterProp='children'
          value={pumpType}
          onChange={setPumpType}
          filterOption={filterOptionSelect}
          dropdownMatchSelectWidth={false}
        >
          <Option key={pumpTypes.ELECTRIC_PUMP} value={pumpTypes.ELECTRIC_PUMP}>{t(`transports.pumpTypes.${pumpTypes.ELECTRIC_PUMP}`)}</Option>
          <Option key={pumpTypes.FUEL_PUMP} value={pumpTypes.FUEL_PUMP}>{t(`transports.pumpTypes.${pumpTypes.FUEL_PUMP}`)}</Option>
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderDiameterInput = () => {
    return (
      <Form.Item
        name='diameter'
        label={`${t('transports.diameter')} (${t('common.optional')})`}
        required
      >
        <LrvInputNumber
          theme='light'
          placeholder={t('transports.diameter')}
          value={diameter}
          formatter={applyThousandsSeparator}
          parser={applyParserThousandsSeparator}
          min={diameterValues.MIN}
          max={diameterValues.MAX}
          onChange={(value) => {
            if (value === null) {
              return;
            }

            setDiameter(parseInt(value.toString()));
          }}
        />
      </Form.Item>
    );
  };

  const renderFlowRateInput = () => {
    return (
      <Form.Item
        name='flowRate'
        label={`${t('transports.flowRate')} (${t('common.optional')})`}
        required
      >
        <LrvInputNumber
          theme='light'
          placeholder={t('transports.flowRate')}
          value={flowRate}
          formatter={applyThousandsSeparator}
          parser={applyParserThousandsSeparator}
          min={flowRateValues.MIN}
          max={flowRateValues.MAX}
          onChange={(value) => {
            if (value === null) {
              return;
            }

            setFlowRate(parseInt(value.toString()));
          }}
        />
      </Form.Item>
    );
  };

  const renderBody = () => {
    switch (transportType) {
      case 'CAPSULE':
        return (
          <>
            {renderPlateInput()}
            {renderDriverInput()}
            {renderCapacityInput()}
          </>
        );

      case 'PUMP':
        return (
          <>
            {renderPumpNameInput()}
            {renderPumpTypeInput()}
            {renderDiameterInput()}
            {renderFlowRateInput()}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <LrvModal
      theme='light'
      title={t('transports.newTransport')}
      open={showNewModal}
      destroyOnClose={true}
      okButtonProps={{ htmlType: 'submit', form: 'formNewTransport', id: 'formNewTransport_btnSave' }}
      onOk={() => {
        form.submit();
      }}
      okText={t('transports.create')}
      cancelText={t('transports.cancel')}
      onCancel={() => {
        closeModal();
      }}
    >
      <LrvForm
        theme='light'
        form={form}
        layout='vertical'
        name='formNewTransport'
        id='formNewTransport'
        onFinish={() => {
          switch (transportType) {
            case 'CAPSULE':
              dispatch(transportSlice.createTransport({ plate, capacity, driver, type: transportType }));
              break;

            case 'PUMP':
              dispatch(transportSlice.createTransport({ pumpName, pumpType, diameter, flowRate, type: transportType }));
              break;
          }

          closeModal();
        }}
      >
        {renderTransportTypeInput()}
        {renderBody()}
      </LrvForm>
    </LrvModal>
  );
};
