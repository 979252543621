import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';
import { WarningOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, useRef, useCallback } from 'react';

import { getSampleTitle } from '../helper';
import { checkAnalysisBelongToStocking, getAreaPercentIncrease } from '../../helpers';
import PreviousNextButtons from '../PreviousNextButtons';
import { Store } from '../../../../state/store.interfaces';
import Icon from '../../../../common/components/Icon/Icon';
import { ENVIRONMENT } from '../../../../config/config.api';
import IconButton from '../../../../common/components/buttons/IconButton';
import { Animal, AnimalsByGroup, Percentile, SampleAnimal } from '../interfaces';
import { LrvPopover } from '../../../../common/components/LrvPopover/LrvPopover';
import FullScreenFrame from '../../../../common/components/FullScreen/FullScreen';
import MobileFloatingButton from '../../../../common/components/FloatingButton/MobileFloatingButton';
import { groupLabels, createListPercentiles, groupAnimals, createListPercentilesOld } from '../math_percentile';
import { environments, roundWeight, getWeightUnit, analysisTypes, bucketOriginalJuvenile, roles, getMainRole } from '../../../../config/commons';

import './Sample.scss';
import Legend from './Legend';
import IconOptions from './IconOptions';
import styles from './Sample.module.scss';
import * as sampleSlice from './sampleSlice';
import QuantityAnimals from './QuantityAnimals';
import SampleD3, { SampleD3Props } from './SampleD3';
import DiseasePanel from './ManageAnimals/DiseasePanel';
import NewAnimalPanel from './ManageAnimals/NewAnimalPanel';
import ChangeGroupAnimalPanel from './ManageAnimals/ChangeGroupAnimalPanel';

let sampleChart: SampleD3 | null;
let sampleChartFullScreen: SampleD3 | null;

interface Props {
  showOptions?: boolean;
  containerClassName?: string;
  theme?: 'dark' | 'light';
}

export default function Sample (props: Props) {
  const { containerClassName = styles.container, showOptions = true, theme = 'dark' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [showFullScreenModal, setShowFullScreenModal] = useState(false);
  const [image, setImage] = useState(new window.Image());
  const [loading, setLoading] = useState(true);

  const { allAnalysis, analysis: analysisOriginal, currentIndex } = useSelector((state: Store) => state.detailAnalysis);
  const {
    editSample,
    newAnimal,
    newPercentile,
    showAnimalsRemoved,
    sampleAnimalList,
    animalSelected,
    oldPercentile,
    percentileList,
    showQuantityAnimalsPanel,
    disease,
    changesSaved,
    showPanelToChangeGroup,
    showPanelToAddDisease,
    isDownloadingSample,
    isDownloadingMask,
    isDownloadingJuvenileMask,
  } = useSelector((state: Store) => state.sample);

  const currentAnalysis = (allAnalysis && allAnalysis.length > 0) ? allAnalysis[currentIndex] : analysisOriginal;
  const analysisType = currentAnalysis?.type || '';
  const analysisPhaseType = currentAnalysis.phaseType;
  const larvaeModifiedList = currentAnalysis.inferenceData.larvaeModifiedList;
  const analysisBelongToStocking = checkAnalysisBelongToStocking({ analysisSelected: currentAnalysis });

  const refChart = useRef<HTMLDivElement>(null);
  const refChartFullScreen = useRef<HTMLDivElement>(null);

  const accessToken = localStorage.getItem('accessToken');

  const averageWeight = currentAnalysis.resultData.averageWeight;
  const labelsUnit = getWeightUnit(averageWeight);
  const imagenUrl = currentAnalysis.imageData.original?.url;
  const maskUrl = currentAnalysis.imageData.mask?.url;

  const removeAnimal = useCallback(() => {
    if (!animalSelected || !oldPercentile || !sampleAnimalList) {
      return;
    }

    const coordinates = [Math.round(animalSelected.x), Math.round(animalSelected.y)];
    const { animal: editedAnimal, index } = findEditedAnimalAndIndex(coordinates, sampleAnimalList);

    if (editedAnimal && index !== undefined) {
      const sampleAnimals = cloneDeep(sampleAnimalList);
      sampleAnimals.splice(index, 1);
      dispatch(sampleSlice.setSampleAnimalList(sampleAnimals));
    } else {
      const sampleAnimal: SampleAnimal = {
        action: 'REMOVE',
        coordinates,
      };

      const sampleAnimals = [...sampleAnimalList, sampleAnimal];
      dispatch(sampleSlice.setSampleAnimalList(sampleAnimals));
    }

    const { group: oldGroup } = oldPercentile;

    const percentiles: Percentile[] | AnimalsByGroup[] = cloneDeep(percentileList);
    const { animal: oldAnimal, index: oldIndex } = findAnimalByPercentile([animalSelected.x, animalSelected.y], oldGroup, percentiles);

    if (oldAnimal && oldIndex !== undefined) {
      percentiles[oldGroup - 1].animals.splice(oldIndex, 1);
    }

    if (!animalSelected.added) {
      const animal: Animal = {
        x: animalSelected.x,
        y: animalSelected.y,
        removed: true,
      };

      percentiles[oldGroup - 1].animals.push(animal);
    }
    dispatch(sampleSlice.setPercentileList(percentiles));

    onCloseManageAnimals();
  }, [animalSelected, oldPercentile, sampleAnimalList, percentileList]);

  const undoChangeGroupAnimal = useCallback(() => {
    if (!animalSelected || !animalSelected.previousGroup || !oldPercentile) {
      return;
    }

    const coordinates = [Math.round(animalSelected.x), Math.round(animalSelected.y)];
    const { animal: editedAnimal, index } = findEditedAnimalAndIndex(coordinates, sampleAnimalList);

    if (editedAnimal && index !== undefined) {
      const sampleAnimals = cloneDeep(sampleAnimalList);

      if (animalSelected.disease) {
        sampleAnimals[index].disease = animalSelected.disease;
        sampleAnimals[index].group = animalSelected.previousGroup;
        sampleAnimals[index].targetGroup = animalSelected.previousGroup;
      } else {
        sampleAnimals.splice(index, 1);
      }

      dispatch(sampleSlice.setSampleAnimalList(sampleAnimals));
    }

    const { group: oldGroup } = oldPercentile;
    const percentiles: Percentile[] | AnimalsByGroup[] = cloneDeep(percentileList);
    const { animal: oldAnimal, index: oldIndex } = findAnimalByPercentile([animalSelected.x, animalSelected.y], oldGroup, percentiles);

    if (oldAnimal && oldIndex !== undefined) {
      const animal: Animal = {
        x: animalSelected.x,
        y: animalSelected.y,
        disease: animalSelected.disease,
      };

      percentiles[oldGroup - 1].animals.splice(oldIndex, 1);
      percentiles[animalSelected.previousGroup - 1].animals.push(animal);
    }

    dispatch(sampleSlice.setPercentileList(percentiles));
    onCloseManageAnimals();
  }, [animalSelected, oldPercentile, sampleAnimalList, percentileList]);

  const undoRemovedAnimal = useCallback(() => {
    if (!animalSelected || !oldPercentile || !sampleAnimalList) {
      return;
    }

    const coordinates = [Math.round(animalSelected.x), Math.round(animalSelected.y)];
    const { animal: editedAnimal, index } = findEditedAnimalAndIndex(coordinates, sampleAnimalList);

    if (editedAnimal && index !== undefined) {
      const sampleAnimals = cloneDeep(sampleAnimalList);
      sampleAnimals.splice(index, 1);
      dispatch(sampleSlice.setSampleAnimalList(sampleAnimals));
    }

    const { group: oldGroup } = oldPercentile;
    const percentiles: Percentile[] | AnimalsByGroup[] = cloneDeep(percentileList);
    const { animal: oldAnimal, index: oldIndex } = findAnimalByPercentile([animalSelected.x, animalSelected.y], oldGroup, percentiles);

    if (oldAnimal && oldIndex !== undefined) {
      const animal: Animal = {
        x: animalSelected.x,
        y: animalSelected.y,
      };

      percentiles[oldGroup - 1].animals[oldIndex] = animal;
    }

    dispatch(sampleSlice.setPercentileList(percentiles));
    onCloseManageAnimals();
  }, [animalSelected, oldPercentile, sampleAnimalList, percentileList]);

  const removeDisease = useCallback(() => {
    if (!animalSelected || !oldPercentile) {
      return;
    }

    const coordinates = [Math.round(animalSelected.x), Math.round(animalSelected.y)];
    const { group } = oldPercentile;

    const { animal: editedAnimal, index } = findEditedAnimalAndIndex(coordinates, sampleAnimalList);

    if (editedAnimal && index !== undefined) {
      const sampleAnimals = cloneDeep(sampleAnimalList);
      if (!animalSelected.added && !animalSelected.previousGroup) {
        sampleAnimals.splice(index, 1);
      } else {
        sampleAnimals[index].disease = undefined;
      }

      dispatch(sampleSlice.setSampleAnimalList(sampleAnimals));
    }

    const percentiles: Percentile[] | AnimalsByGroup[] = cloneDeep(percentileList);
    const { animal: oldAnimal, index: oldIndex } = findAnimalByPercentile([animalSelected.x, animalSelected.y], group, percentiles);

    if (oldAnimal && oldIndex !== undefined) {
      percentiles[group - 1].animals[oldIndex].disease = undefined;
    }

    dispatch(sampleSlice.setPercentileList(percentiles));
    onCloseManageAnimals();
  }, [animalSelected, oldPercentile, sampleAnimalList, percentileList]);

  useEffect(() => {
    dispatch(sampleSlice.setChangesSaved(false));

    return (() => {
      sampleChart = null;
      sampleChartFullScreen = null;
    });
  }, [currentIndex]);

  useEffect(() => {
    setLoading(true);

    if (!currentAnalysis?.imageData?.reduced?.url) {
      return;
    }

    const img = new window.Image();
    img.src = currentAnalysis.imageData.reduced.url;
    img.addEventListener('load', () => {
      setImage(img);
      setLoading(false);
    });
  }, [currentAnalysis?.imageData?.reduced?.url]);

  const updatePercentiles = useCallback(() => {
    if (!currentAnalysis._id || image.height === 0) {
      return;
    }

    const { pointSizeRatio } = currentAnalysis;
    const { larvaeNumber } = currentAnalysis.resultData;
    const { weightGroups } = currentAnalysis.inferenceData;

    const labels = roundWeight({ value: currentAnalysis.resultData.labels, showUnit: false, average: averageWeight });
    const labelsSort: string[] = groupLabels(labels as number[]);

    const areaPercentIncrease = getAreaPercentIncrease({ analysisType }) * larvaeNumber;
    let percentiles: Percentile[] = pointSizeRatio === undefined ?
      createListPercentilesOld({ labelsSort, areaPercentIncrease, weightGroups }) :
      createListPercentiles({ labelsSort, pointSizeRatio, weightGroups, imageSize: image.height });

    percentiles = percentiles.length > 0 ? groupAnimals(percentiles, weightGroups) : [];
    dispatch(sampleSlice.setPercentileList(percentiles));
  }, [currentAnalysis._id, averageWeight, analysisType, image.height]);

  useEffect(() => {
    updatePercentiles();
  }, [updatePercentiles]);

  useEffect(() => {
    if (!currentAnalysis?.imageData?.reduced?.url || !percentileList?.length || !refChart.current || image.height === 0) {
      return;
    }

    if (sampleChart) {
      sampleChart.refreshChart({
        animalsByGroup: percentileList,
        showAnimalsRemoved,
        allowEditSample: false,
        newAnimal: undefined,
        animalSelected: undefined,
        currentIndex,
        urlImage: currentAnalysis.imageData.reduced.url,
      });

      return;
    }

    const props: SampleD3Props = {
      container: refChart.current,
      height: image.height,
      width: image.width,
      urlImage: currentAnalysis.imageData.reduced.url,
      animalsByGroup: percentileList,
      phaseType: analysisPhaseType,
      showAnimalsRemoved,
      translateX: - (image.width / 3),
      translateY: - (image.height / 3),
      enableScroll: false,
      allowEditSample: false,
    };
    sampleChart = new SampleD3(props);
  }, [refChart.current, currentIndex, percentileList, image.height, image.width, currentAnalysis._id, currentAnalysis?.imageData?.reduced?.url, analysisPhaseType, showAnimalsRemoved, larvaeModifiedList?.length, editSample, newAnimal, animalSelected, image]);

  useEffect(() => {
    if (!currentAnalysis?.imageData?.reduced?.url || !percentileList?.length || !showFullScreenModal || !refChartFullScreen.current) {
      return;
    }

    if (sampleChartFullScreen) {
      sampleChartFullScreen.refreshChart({
        animalsByGroup: percentileList,
        showAnimalsRemoved,
        allowEditSample: editSample && !larvaeModifiedList?.length && !changesSaved,
        newAnimal,
        animalSelected,
        currentIndex,
        urlImage: currentAnalysis.imageData.reduced.url,
        removeAnimal: removeAnimal,
        undoChangeGroupAnimal: undoChangeGroupAnimal,
        undoRemovedAnimal: undoRemovedAnimal,
        removeDisease: removeDisease,
      });

      return;
    }

    const propsFullScreen: SampleD3Props = {
      height: image.height,
      width: image.width,
      urlImage: currentAnalysis.imageData.reduced.url,
      animalsByGroup: percentileList,
      phaseType: analysisPhaseType,
      showAnimalsRemoved,
      container: refChartFullScreen.current,
      translateX: 0,
      translateY: 0,
      enableScroll: true,
      allowEditSample: editSample && !larvaeModifiedList?.length,
      newAnimal,
      addNewAnimal,
      sightIconColor: 'black',
      onSelectAnimal,
      showChangeGroupPanel,
      showDiseasesPanel,
    };

    sampleChartFullScreen = new SampleD3(propsFullScreen);
  }, [refChartFullScreen.current, showFullScreenModal, currentIndex, percentileList, image.height, image.width, currentAnalysis._id, currentAnalysis?.imageData?.reduced?.url, analysisPhaseType, showAnimalsRemoved, larvaeModifiedList?.length, editSample, newAnimal, animalSelected, image, changesSaved, removeAnimal, undoChangeGroupAnimal, undoRemovedAnimal, removeDisease]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.center}>
          <Spin />
        </div>
        <div className={styles.containerImage} id='sample'>
          <div ref={refChart} />
          <PreviousNextButtons />
        </div>
      </div>
    );
  }

  function renderButtonDownloadImage () {
    const higherRole = getMainRole();
    if (roles.SUPER_ADMIN !== higherRole) {
      return null;
    }

    const nameFile = currentAnalysis._id;
    return (
      <IconButton
        id='download_sample_button'
        loading={isDownloadingSample}
        onClick={() => dispatch(sampleSlice.downloadImage(imagenUrl, nameFile))}
        iconName='download'
        size='small'
        tooltipText={t('analysis.download.image')}
      />
    );
  }

  function renderWarnings () {
    const { showGrowOutWarning, unusualAverageWeight } = currentAnalysis;
    const showPopover = showGrowOutWarning || unusualAverageWeight;

    if (!showPopover) {
      return null;
    }

    const content = (
      <div style={{ marginLeft: -10 }}>
        <ul>
          {showGrowOutWarning && <li>{t('analysis.warnings.growOut')}</li>}
          {unusualAverageWeight && !analysisBelongToStocking && <li>{t('analysis.warnings.weightOutOfRange')}.</li>}
          {unusualAverageWeight && analysisBelongToStocking && <li>{t('analysis.warnings.weightOutOfRange')}, {t('analysis.warnings.belongsToAnotherStocking')}</li>}
        </ul>
      </div>
    );

    return (
      <LrvPopover content={content} title={t('analysis.warnings.title')}>
        <WarningOutlined className={styles.warningIcon} />
      </LrvPopover>
    );
  }

  function renderButtonDownloadMask () {
    if (environments.DEV !== ENVIRONMENT || !maskUrl || !accessToken) {
      return null;
    }

    const nameFile = currentAnalysis._id;
    const extension = analysisType === analysisTypes.GENERAL_LARVAE_ANALYSIS ? 'png' : 'json';
    const title = analysisType === analysisTypes.GENERAL_LARVAE_ANALYSIS ? t('analysis.download.mask') : t('analysis.download.jsonMask');

    return (
      <IconButton
        loading={isDownloadingMask}
        onClick={() => dispatch(sampleSlice.downloadMask(maskUrl, nameFile, extension))}
        icon={<Icon className='superAdminAction' name='download' theme={theme} />}
        size='small'
        tooltipText={title}
      />
    );
  }

  function renderButtonDownloadJuvenileImageMask () {
    if (analysisType === analysisTypes.GENERAL_JUVENILE_ANALYSIS && environments.DEV === ENVIRONMENT && maskUrl && accessToken) {
      const extension = 'jpg';
      const nameFile = currentAnalysis._id;

      return (
        <IconButton
          loading={isDownloadingJuvenileMask}
          onClick={() => dispatch(sampleSlice.generateJuvenileMask({ analysisCode: nameFile, imageName: currentAnalysis?.imageData?.original?.key, bucketName: bucketOriginalJuvenile, format: extension }))}
          icon={<Icon className={styles.generateJuvenileMask} name='download' theme={theme} />}
          size='small'
          tooltipText={t('analysis.download.mask')}
        />
      );
    }

    return null;
  }

  function renderButtonFullScreen () {
    return (
      <IconButton
        id='fullscreen_button'
        onClick={() => setShowFullScreenModal(true)}
        iconName='fullscreen'
        size='small'
        tooltipText={t('analysis.fullscreen')}
      />
    );
  }

  function renderOption () {
    if (!showOptions) {
      return null;
    }

    return (
      <div className={styles.actions}>
        {renderWarnings()}
        {renderButtonDownloadImage()}
        {renderButtonDownloadMask()}
        {renderButtonDownloadJuvenileImageMask()}
        {renderButtonFullScreen()}
      </div>
    );
  }

  const addNewAnimal = (point: number[]) => {
    dispatch(sampleSlice.setNewAnimal(point));
    dispatch(sampleSlice.resetAnimalSelected());
    dispatch(sampleSlice.resetOldPercentile());
    dispatch(sampleSlice.resetNewPercentile());
  };

  const onSelectAnimal = (animal: Animal, percentile: Percentile | AnimalsByGroup) => {
    dispatch(sampleSlice.resetNewAnimal());
    dispatch(sampleSlice.setAnimalSelected(animal));
    dispatch(sampleSlice.setOldPercentile(percentile));
  };

  const onCloseManageAnimals = () => {
    dispatch(sampleSlice.resetAnimalSelected());
    dispatch(sampleSlice.resetOldPercentile());
    dispatch(sampleSlice.resetNewPercentile());
    dispatch(sampleSlice.resetNewAnimal());
    dispatch(sampleSlice.resetDisease());
    dispatch(sampleSlice.setShowPanelToChangeGroup(false));
    dispatch(sampleSlice.setShowPanelToAddDisease(false));
  };

  const findEditedAnimalAndIndex = (coordinates: number[], sampleAnimalList: SampleAnimal[]) => {
    const sampleAnimals: SampleAnimal[] = cloneDeep(sampleAnimalList);
    let animal: SampleAnimal | undefined = undefined;

    for (let index = 0; index < sampleAnimals.length; index++) {
      animal = sampleAnimals[index];
      if (animal.coordinates[0] === coordinates[0] && animal.coordinates[1] === coordinates[1]) {
        return { animal, index };
      }
    }

    return { animal, index: undefined };
  };

  const findAnimalByPercentile = (coordinates: number[], group: number, animalsByGroup: Percentile[] | AnimalsByGroup[]) => {
    const { animals } = animalsByGroup[group - 1];
    let animal: Animal | undefined = undefined;

    for (let index = 0; index < animals.length; index++) {
      animal = animals[index];
      if (Math.round(animal.x) === Math.round(coordinates[0]) && Math.round(animal.y) === Math.round(coordinates[1])) {
        return { animal, index };
      }
    }

    return { animal, index: undefined };
  };

  const addAnimal = () => {
    if (!newAnimal || !newPercentile) {
      return;
    }

    const coordinates = [Math.round(newAnimal[0]), Math.round(newAnimal[1])];
    const { group } = newPercentile;

    const sampleAnimal: SampleAnimal = {
      action: 'ADD',
      coordinates,
      group,
      disease,
    };

    const sampleAnimals = [...sampleAnimalList, sampleAnimal];
    dispatch(sampleSlice.setSampleAnimalList(sampleAnimals));

    const [resizePointX, resizePointY] = newAnimal;

    const animal: Animal = {
      added: true,
      x: resizePointX,
      y: resizePointY,
      disease,
    };

    const percentiles: Percentile[] | AnimalsByGroup[] = cloneDeep(percentileList);
    percentiles[group - 1].animals.push(animal);
    dispatch(sampleSlice.setPercentileList(percentiles));
    onCloseManageAnimals();
  };

  const addDisease = () => {
    if (!animalSelected || !disease || !oldPercentile) {
      return;
    }

    const coordinates = [Math.round(animalSelected.x), Math.round(animalSelected.y)];
    const { group } = oldPercentile;

    const { animal: editedAnimal, index } = findEditedAnimalAndIndex(coordinates, sampleAnimalList);

    if (editedAnimal && index !== undefined) {
      const sampleAnimals = cloneDeep(sampleAnimalList);
      sampleAnimals[index].disease = disease;
      dispatch(sampleSlice.setSampleAnimalList(sampleAnimals));
    } else {
      const sampleAnimal: SampleAnimal = {
        action: 'EDIT',
        coordinates,
        group,
        targetGroup: group,
        disease,
      };

      const sampleAnimals = [...sampleAnimalList, sampleAnimal];
      dispatch(sampleSlice.setSampleAnimalList(sampleAnimals));
    }

    const percentiles: Percentile[] | AnimalsByGroup[] = cloneDeep(percentileList);
    const { animal: oldAnimal, index: oldIndex } = findAnimalByPercentile([animalSelected.x, animalSelected.y], group, percentiles);

    if (oldAnimal && oldIndex !== undefined) {
      percentiles[group - 1].animals[oldIndex].disease = disease;
    } else {
      const animal: Animal = {
        x: animalSelected.x,
        y: animalSelected.y,
        disease,
      };

      percentiles[group - 1].animals.push(animal);
    }

    dispatch(sampleSlice.setPercentileList(percentiles));
    onCloseManageAnimals();
  };

  const changeGroupAnimal = () => {
    if (!animalSelected || !newPercentile || !oldPercentile) {
      return;
    }

    const coordinates = [Math.round(animalSelected.x), Math.round(animalSelected.y)];
    const { group: targetGroup } = newPercentile;
    const { animal: editedAnimal, index } = findEditedAnimalAndIndex(coordinates, sampleAnimalList);

    if (editedAnimal && index !== undefined) {
      const sampleAnimals = cloneDeep(sampleAnimalList);
      const sampleAnimal: SampleAnimal = {
        action: editedAnimal.action,
        group: targetGroup,
        coordinates,
        targetGroup,
        disease: animalSelected.disease,
      };

      sampleAnimals[index] = sampleAnimal;
      dispatch(sampleSlice.setSampleAnimalList(sampleAnimals));
    } else {
      const sampleAnimal: SampleAnimal = {
        action: 'EDIT',
        coordinates,
        targetGroup,
        disease,
      };

      const sampleAnimals = [...sampleAnimalList, sampleAnimal];
      dispatch(sampleSlice.setSampleAnimalList(sampleAnimals));
    }

    const { group: oldGroup } = oldPercentile;

    const percentiles: Percentile[] | AnimalsByGroup[] = cloneDeep(percentileList);
    const { animal: oldAnimal, index: oldIndex } = findAnimalByPercentile([animalSelected.x, animalSelected.y], oldGroup, percentiles);

    if (oldAnimal && oldIndex !== undefined) {
      const animal: Animal = {
        x: animalSelected.x,
        y: animalSelected.y,
        disease: animalSelected.disease,
        previousGroup: oldGroup,
      };

      percentiles[oldGroup - 1].animals.splice(oldIndex, 1);
      percentiles[targetGroup - 1].animals.push(animal);
    }

    dispatch(sampleSlice.setPercentileList(percentiles));
    onCloseManageAnimals();
  };

  const changeListPercentile = (percentiles: Percentile[]) => {
    dispatch(sampleSlice.setPercentileList(percentiles));
  };

  const showChangeGroupPanel = (show: boolean) => {
    dispatch(sampleSlice.setShowPanelToChangeGroup(show));
  };

  const showDiseasesPanel = (show: boolean) => {
    dispatch(sampleSlice.setShowPanelToAddDisease(show));
  };

  const renderToolsPanel = () => {
    if (!editSample) {
      return (
        <QuantityAnimals
          changeListPercentile={changeListPercentile}
          labelsUnit={labelsUnit}
          listPercentile={percentileList as Percentile[]}
          showAnimalsRemoved={showAnimalsRemoved}
          theme={theme}
          showQuantityAnimalsPanel={showQuantityAnimalsPanel}
        />
      );
    }

    if (newAnimal) {
      return (
        <NewAnimalPanel
          labelsUnit={labelsUnit}
          listPercentile={percentileList as Percentile[]}
          theme={theme}
          sampleChartFullScreen={sampleChartFullScreen}
          addAnimal={addAnimal}
        />
      );
    }

    if (!animalSelected) {
      return null;
    }

    if (showPanelToChangeGroup) {
      return (
        <ChangeGroupAnimalPanel
          labelsUnit={labelsUnit}
          listPercentile={percentileList as Percentile[]}
          theme={theme}
          sampleChartFullScreen={sampleChartFullScreen}
          changeGroupAnimal={changeGroupAnimal}
          undoRemovedAnimal={undoRemovedAnimal}
        />
      );
    }

    if (showPanelToAddDisease) {
      return (
        <DiseasePanel
          theme={theme}
          addDisease={addDisease}
        />
      );
    }

    return null;
  };

  return (
    <div className={cx(containerClassName, 'sample')}>
      {renderOption()}

      <div className={styles.containerImage} id='sample'>
        <div ref={refChart} />
        <PreviousNextButtons />
      </div>

      <FullScreenFrame
        show={showFullScreenModal}
        title={getSampleTitle(currentAnalysis.code)}
        onClose={() => {
          dispatch(sampleSlice.setEditSample(false));
          setShowFullScreenModal(false);
          onCloseManageAnimals();
          dispatch(sampleSlice.resetSampleAnimalList());
          updatePercentiles();
        }}
        theme={theme}
      >
        <div className={styles.containerFullScreen}>
          <div className={styles.containerImageFullScreen}>
            <div
              ref={refChartFullScreen}
              style={{
                width: image.width,
                height: image.height,
                marginLeft: window.innerWidth - image.width > 0 ? (window.innerWidth - image.width) / 2 : 0,
              }}
            />
          </div>

          {
            !analysisOriginal.coordinatesRemoved &&
            <IconOptions
              editSample={editSample}
              larvaeModifiedList={larvaeModifiedList}
              analysisId={currentAnalysis._id}
            />
          }

          {renderToolsPanel()}
          {!editSample && <Legend analysisPhaseType={currentAnalysis.phaseType} theme={theme} />}

          {
            !isDesktop &&
            <MobileFloatingButton
              className={styles.floatingButton}
              onClose={() => {
                dispatch(sampleSlice.setShowQuantityAnimalsPanel(true));
              }}
            >
              <Icon className={styles.filterIcon} name='filter' theme={theme} />
            </MobileFloatingButton>
          }
        </div>
      </FullScreenFrame>
    </div>
  );
}
