import { useEffect } from 'react';
import { Dropdown, Menu } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { changeHeader } from '../../AppHeader/headerSlice';
import { stockingParameterOptions } from '../../../config/commons';
import IconButton from '../../../common/components/buttons/IconButton';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import ActionButton from '../../../common/components/buttons/ActionButton';

import './Transports.scss';
import { Transport } from './interfaces';
import styles from './Transports.module.scss';
import * as transportSlice from './transportSlice';
import { NewTransportModal } from './NewTransportModal';
import { EditTransportModal } from './EditTransportModal';

export const Transports = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const theme = getCurrentTheme();

  const {
    isLoading,
    transports,
    currentPage,
    limit,
    total,
  } = useSelector((state: Store) => state.transports);

  useEffect(() => {
    dispatch(changeHeader({ title: 'sidebar.transports' }));
    dispatch(transportSlice.fetchTransports({ currentPage: 0 }));
  }, [dispatch]);

  const menuItemsStockingOptions = (record: Transport) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case stockingParameterOptions.EDIT:
              dispatch(transportSlice.setShowEditModal(true));
              dispatch(transportSlice.setTransport(record));
              return;
          }
        }}
      >
        <Menu.Item id='menu_option_edit' key={stockingParameterOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' theme={theme} className={styles.icon} />
          <span>{t('transports.edit')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  const columns: ColumnsType<Transport> = [
    {
      key: 1,
      title: t('transports.type'),
      dataIndex: 'type',
      width: '30%',
      render: (value: string) => t(`transports.types.${value}`),
    },
    {
      key: 2,
      title: t('transports.plate'),
      dataIndex: 'plate',
      width: '30%',
    },
    {
      key: 3,
      title: t('transports.pumpName'),
      dataIndex: 'pumpName',
      width: '30%',
    },
    {
      key: 4,
      width: '5%',
      render: (_, record: Transport) => {
        return (
          <Dropdown
            overlay={() => menuItemsStockingOptions(record)}
            trigger={['click']}
            placement='bottomRight'
          >
            <IconButton
              onClick={(e) => e.stopPropagation()}
              iconName='more-2'
            />
          </Dropdown>
        );
      },
    }
  ];

  const onChangePage = (page: number) => {
    dispatch(transportSlice.fetchTransports({ currentPage: page }));
    dispatch(transportSlice.setCurrentPage(page));
  };

  return (
    <div className={styles.container}>
      <div className={styles.alignButtonRight}>
        <ActionButton
          id='add_button'
          type='primary'
          icon={<Icon name='add' />}
          onClick={() => {
            dispatch(transportSlice.setShowNewModal(true));
          }}
        >
          {t('transports.add')}
        </ActionButton>
      </div>

      <LrvTable
        columns={columns}
        className='transportTable'
        dataSource={transports}
        loading={isLoading}
        scroll={{ y: '' }}
        theme={theme}
        size='small'
        pagination={{
          size: 'default',
          showSizeChanger: false,
          defaultPageSize: limit,
          pageSize: limit,
          onChange: onChangePage,
          total: total,
          current: currentPage,
        }}
      />

      <NewTransportModal />
      <EditTransportModal />
    </div>
  );
};
