
export const transportTypes = {
  CAPSULE: 'CAPSULE',
  PUMP: 'PUMP',
};

export const pumpTypes = {
  ELECTRIC_PUMP: 'ELECTRIC_PUMP',
  FUEL_PUMP: 'FUEL_PUMP',
};

export const capacityCapsuleValues = {
  MIN: 0,
  MAX: 40,
};

export const diameterValues = {
  MIN: 4,
  MAX: 10,
};

export const flowRateValues = {
  MIN: 0,
  MAX: 500,
};
