import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Company } from '../AppHeader/interfaces';
import { analysisStatuses } from '../../config/commons';
import { getUserSession } from '../../utils/userSession';
import { axiosClient as axios } from '../../utils/axios_instance';
import { ANALYSES_URL, GROWTH_DELTA_URL, COMPANIES_URL } from '../../config/config.api';

import { MetricsState, Point } from './interfaces';

export const pagination = 10;

const initialState: MetricsState = {
  growthDelta: [],
  analysis: [],
  isFetchAnalysis: false,
  isFetchMetrics: false,
  isDownloadingFile: false,
};

export const metricsSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    setAnalysis: (state: MetricsState, action: PayloadAction<Point[]>) => {
      state.analysis = action.payload;
    },
    setIsFetchAnalysis: (state: MetricsState, action: PayloadAction<boolean>) => {
      state.isFetchAnalysis = action.payload;
    },
    setIsFetchMetrics: (state: MetricsState, action: PayloadAction<boolean>) => {
      state.isFetchMetrics = action.payload;
    },
    setIsDownloadingFile: (state: MetricsState, action: PayloadAction<boolean>) => {
      state.isDownloadingFile = action.payload;
    },
    setGrowthDelta: (state: MetricsState, action: PayloadAction<[]>) => {
      state.growthDelta = action.payload;
    },
  },
});

export const {
  setAnalysis, setIsFetchAnalysis,
  setIsFetchMetrics, setIsDownloadingFile,
  setGrowthDelta,
} = metricsSlice.actions;

export const fetchAnalysis = (props: { companyId: string; stockingsId: string[]; showLoading: boolean; token?: string; maxStage: number }) => async (dispatch: Function) => {
  const { companyId, stockingsId, showLoading, token, maxStage } = props;

  if (showLoading) {
    dispatch(setIsFetchAnalysis(true));
  }

  const params = {
    $limit: -1,
    companyId,
    sowingId: stockingsId,
    '$sort[stage]': '1',
    hidden: false,
    status: analysisStatuses.COMPLETED,
    $select: [
      'code',
      'createdAt',
      'type',
      'sowingId',
      'inputData.stage',
      'resultData.averageWeight',
      'resultData.uniformity',
      'resultData.larvaePerGram',
      'resultData.larvaeNumber',
      'resultData.averageLength',
      'resultData.variationCoefficientLength',
      'resultData.histogramPigmentation',
      'resultData.animalsAboveConditionFactor',
      'resultData.lunarAge',
      'unusualAverageWeight',
    ],
    'trayId[$exists]': false,
  };

  try {
    let response;

    if (token) {
      response = await axios.get<Point[]>(ANALYSES_URL, {
        headers: { 'Authorization': token },
        params: params,
      });
    } else {
      response = await axios.get<Point[]>(ANALYSES_URL, { params: params });
    }

    const analysis = response.data.filter((analysis: Point) => analysis.inputData.stage <= maxStage);
    dispatch(setAnalysis(analysis));
  } catch (e) {
    dispatch(setAnalysis([]));
    return;
  }


  if (showLoading) {
    dispatch(setIsFetchAnalysis(false));
  }
};

export const fetchGrowthDelta = (stockingId: string[] | string, token?: string) => async (dispatch: Function) => {
  dispatch(setIsFetchMetrics(true));

  try {
    const isArray = Array.isArray(stockingId);
    let stockingIds = stockingId;
    if (stockingId instanceof Array) {
      stockingIds = stockingId.join();
    }

    const params = new URLSearchParams({ sowingId: stockingIds.toString() });
    let response;

    if (token) {
      response = await axios.get(GROWTH_DELTA_URL, {
        headers: { 'Authorization': token },
        params: params,
      });
    } else {
      response = await axios.get(GROWTH_DELTA_URL, { params: params });
    }

    let deltaData = response.data;

    if (isArray && stockingId.length === 1) {
      deltaData = [{ stockingId: stockingId[0], data: response.data }];
    }

    if (!isArray) {
      deltaData = [{ stockingId: stockingId, data: response.data }];
    }

    dispatch(setGrowthDelta(deltaData));
  } catch (e) {
    console.log('ERROR');
  }

  dispatch(setIsFetchMetrics(false));
};

export const getCompany = async (id?: string) => {
  const userSession = getUserSession();
  const companyId = id || userSession.companyId;
  const params = {
    $select: ['_id', 'name', 'maxStage', 'maxDayJuvenile', 'maxDayGrowOut', 'allowBeta', 'allowXlsxAnalysisReport', 'allowAutomaticConsolidation'],
  };

  try {
    const response = await axios.get<Company>(`${COMPANIES_URL}/${companyId}`, { params });
    return response.data;
  } catch (e) {
    console.log(e?.response);
  }
};

export default metricsSlice.reducer;
