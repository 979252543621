import * as d3 from 'd3';
import cx from 'classnames';

import { THEME } from '../../../config/commons';
import { getDateWithoutTime } from '../../../common/components/charts/ParameterChart/helpers';

import styles from './ParameterStateD3.module.scss';
import { StockingParameterData, StockingParameterValue } from './interfaces';

let isLightTheme = true;

interface Props {
  container: HTMLDivElement | null;
  minDate: Date;
  maxDate: Date;
  minY: number;
  maxY: number;
  height: number;
  width: number;
  theme: string;
  parameter: string;
  data: StockingParameterData;
  referenceCurves: StockingParameterValue[];
}

export default class ParameterStateD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined> = d3.select<SVGSVGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'svg'));
  groupMain: d3.Selection<SVGGElement, unknown, null, undefined>;

  scaleTimeX: d3.ScaleTime<number, number, never> = d3.scaleTime<number, number, never>();
  scaleLinear: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never> = d3.scaleLinear();

  yAxis: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  width: number;
  height: number;
  margin = { top: 10, right: 20, bottom: 10, left: 40 };

  theme: string;
  minDate: Date;
  maxDate: Date;
  minY: number;
  maxY: number;
  parameter: string;
  data: StockingParameterData;
  referenceCurves: StockingParameterValue[] = [];

  // eslint-disable-next-line
  constructor(props: Props) {
    const { container, data, minDate, maxDate, minY, maxY, width, height, theme, parameter, referenceCurves } = props;

    this.container = container;
    this.theme = theme;
    this.data = data;
    this.minDate = minDate;
    this.maxDate = maxDate;
    this.minY = minY;
    this.maxY = maxY;
    this.parameter = parameter;
    this.referenceCurves = referenceCurves;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    d3.select(container).select('svg').remove();

    isLightTheme = theme === THEME.LIGHT;

    this.svg = d3.select(container)
      .append('svg')
      .attr('class', styles.svg)
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'content')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top} )`);

    this.updateDataPoints();
  }

  updateDataPoints = () => {
    this.buildAxisX();
    this.buildAxisY();

    this.drawYAxis();
    this.renderReferenceLines();
    this.renderLines();

    this.renderLinesAxis();
  };

  generateLines = () => {
    const line = d3.line<StockingParameterValue>()
      .x(d => this.scaleTimeX(getDateWithoutTime(new Date(d.date))))
      .y(d => this.scaleLinear(d.value))
      .curve(d3.curveCatmullRom);

    return { line };
  };

  renderLines = () => {
    const { data, groupMain, minDate } = this;

    const { line } = this.generateLines();

    const filteredData = data.values.filter(d => {
      const date = new Date(d.date);
      return date.getTime() > minDate.getTime();
    });

    groupMain
      .append('path')
      .datum(filteredData)
      .attr('class', styles.lines)
      .attr('d', line);
  };

  renderReferenceLines = () => {
    const { referenceCurves, groupMain, minDate } = this;

    const { line } = this.generateLines();

    const filteredData = referenceCurves.filter(d => {
      const date = new Date(d.date);
      return date.getTime() > minDate.getTime();
    });

    groupMain
      .append('path')
      .datum(filteredData)
      .attr('class', styles.referenceLines)
      .attr('d', line);
  };

  getDefaultDomain = () => {
    const { minDate, maxDate } = this;
    return [getDateWithoutTime(new Date(minDate)), getDateWithoutTime(new Date(maxDate))];
  };

  buildAxisX = () => {
    const { width } = this;
    const defaultDomain = this.getDefaultDomain();

    this.scaleTimeX = d3.scaleTime()
      .domain(defaultDomain)
      .range([0, width]);
  };

  buildAxisY = () => {
    const { height, maxY, minY } = this;

    this.scaleLinear = d3.scaleLinear()
      .domain([minY, maxY])
      .range([height, 0]);
  };

  drawYAxis = () => {
    const { groupMain } = this;

    const axisLeft = d3.axisLeft(this.scaleLinear)
      .tickFormat((d) => d.toString())
      .ticks(6)
      .tickSize(0)
      .tickPadding(4);

    this.yAxis = groupMain.append('g')
      .attr('id', 'axisY')
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axisLeft);
  };

  renderLinesAxis = () => {
    const { groupMain, height, width } = this;

    groupMain.append('line')
      .attr('class', styles.linesAxis)
      .attr('y1', 0)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', 0);

    groupMain.append('line')
      .attr('class', styles.linesAxis)
      .attr('y1', height)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', width);

    groupMain.append('line')
      .attr('class', styles.linesAxis)
      .attr('y1', 0)
      .attr('y2', height)
      .attr('x1', width)
      .attr('x2', width);

    groupMain.append('line')
      .attr('class', styles.linesAxis)
      .attr('y1', 0)
      .attr('y2', 0)
      .attr('x1', 0)
      .attr('x2', width);
  };
}
