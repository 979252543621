import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Descriptions, Form } from 'antd';

import { Store } from '../../state/store.interfaces';
import { fetchAnalysis } from '../Analysis/sowingSlice';
import { LrvTag } from '../../common/components/LrvTag/LrvTag';
import TinyLabel from '../../common/components/TinyLabel/TinyLabel';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import FinishStockingInfo from '../../common/components/FinishSowingInfo/FinishSowingInfo';
import { LrvDescriptions } from '../../common/components/LrvDescriptions/LrvDescriptions';
import { calcDensity, getAnimals, getStartDateStocking, getUnitDensity } from '../../helpers/stocking.helpers';
import { tankStatuses, containerTypes, unitStatuses, stockingStatuses, transferTypes } from '../../config/commons';

import './Sowings.scss';
import styles from './Sowings.module.scss';
import { enableTransfers } from './transferStockingSlice';
import { enableStocking, setShowModalEnable } from './finishStockingSlice';

interface Props {
  theme?: 'dark' | 'light';
}

function EnableStockingModal (props: Props) {
  const { theme = 'dark' } = props;

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();
  const pathname = history.location.pathname;

  const [disabledButtonFormEditStocking, setDisabledButtonFormEditStocking] = useState(false);

  const { company, phaseType } = useSelector((state: Store) => state.header);

  const {
    currentPage,
    hasErrorEdit,
    isLoadingStocking,
    selectedCampus,
    selectedModuleId,
    selectedStocking,
    selectedTankId,
  } = useSelector((state: Store) => state.stockings);

  const {
    stockingsToShow,
  } = useSelector((state: Store) => state.stockings.filters);

  const {
    showModalEnable: showModal,
    isLoadingEnable,
  } = useSelector((state: Store) => state.finishStocking);

  const {
    isLoadingEnableTransfer,
  } = useSelector((state: Store) => state.transferStocking);

  const isCampusInactive = selectedStocking.campusId.status === unitStatuses.INACTIVE;

  useEffect(() => {
    const makeFetchAnalysis = pathname !== 'production/stockings';
    if (showModal && selectedStocking._id !== '' && makeFetchAnalysis && !hasErrorEdit) {
      dispatch(fetchAnalysis({ page: 0, stockingId: selectedStocking._id, companyId: selectedStocking.companyId }));
    }
  }, [dispatch, hasErrorEdit, pathname, selectedStocking._id, showModal]);

  useEffect(() => {
    if (isCampusInactive) {
      setDisabledButtonFormEditStocking(true);
    }
  }, [isCampusInactive]);

  function getTankDescription () {
    if (selectedStocking.tankId?.status) {
      if (selectedStocking.tankId?.status === tankStatuses.AVAILABLE) {
        return t(`stockings.enableDescription.${selectedStocking.tankId?.type ?? containerTypes.TANK}.enable`);
      }
      return t(`stockings.enableDescription.${selectedStocking.tankId?.type ?? containerTypes.TANK}.notAvailable`);
    }
    return t(`stockings.enableDescription.${selectedStocking.tankId?.type ?? containerTypes.TANK}.undefined`);
  }

  function getTankStatus () {
    if (selectedStocking.tankId?.status) {
      if (selectedStocking.tankId?.status === tankStatuses.AVAILABLE) {
        return <LrvTag color='#87d068'>{t('stockings.tankStatus.available')}</LrvTag>;
      }
      return <LrvTag color='#f50'>{t('stockings.tankStatus.stocking')}</LrvTag>;
    }
    return <LrvTag color='#f50'>{t('stockings.tankStatus.notExist')}</LrvTag>;
  }

  function renderLabelCampusInactive () {
    if (isCampusInactive) {
      return (
        <Form.Item>
          <TinyLabel className={styles.campusInactive} text={t('stockings.inactiveCampus')} />
        </Form.Item>
      );
    }

    return null;
  }

  return <LrvModal
    theme={theme}
    isLoading={isLoadingStocking}
    className={cx(styles.settingStockingModal, 'settingStockingModal')}
    title={t('stockings.enableStocking')}
    open={showModal}
    destroyOnClose={true}
    okButtonProps={{
      id: 'submit_edit_stocking',
      htmlType: 'submit',
      form: 'formEnableStocking',
      loading: isLoadingEnable || isLoadingEnableTransfer,
      disabled: disabledButtonFormEditStocking,
    }}
    onOk={() => {
      const paramsToFetchStocking = { companyId: company._id, campusId: selectedCampus?._id, moduleId: selectedModuleId, tankId: selectedTankId, page: currentPage, phaseType: selectedStocking.phaseType, stockingsToShow };
      const makeFetchStockings = pathname === '/production/stockings';

      switch (selectedStocking.status) {
        case stockingStatuses.HARVESTED:
        case stockingStatuses.DISCARDED: {
          dispatch(enableStocking({ stockingId: selectedStocking?._id, makeFetchStockings, paramsToFetchStocking }));
          break;
        }

        case transferTypes.FULL_TRANSFER: {
          dispatch(enableTransfers({ stockingId: selectedStocking?._id, makeFetchStockings, paramsToFetchStocking }));
          break;
        }
      }

    }}
    okText={t('stockings.enable')}
    cancelText={t('stockings.cancel')}
    onCancel={() => {
      dispatch(setShowModalEnable(false));
    }}
    width={580}
  >
    <div>
      {renderLabelCampusInactive()}

      <LrvDescriptions
        theme={theme}
        title={getTankDescription()}
        column={1}
      >
        <Descriptions.Item label={t(`campus.containerTypes.${selectedStocking.tankId?.type ?? containerTypes.TANK}`)}>
          {selectedStocking.tankId?.name} {selectedStocking.tankId?.name ? '-' : ''} &nbsp;
          {getTankStatus()}
        </Descriptions.Item>
      </LrvDescriptions>

      <FinishStockingInfo
        theme={theme}
        name={selectedStocking.name}
        status={selectedStocking.status}
        phaseType={selectedStocking.phaseType}
        harvestQuantity={selectedStocking.harvestQuantity}
        larvaePerGram={selectedStocking.larvaePerGram}
        startDate={getStartDateStocking({ stocking: selectedStocking, phaseType })}
        endDate={selectedStocking.endDate}
        comment={selectedStocking.comment}
        maturation={selectedStocking.maturationId?.name}
        density={calcDensity(selectedStocking)}
        unitDensity={getUnitDensity(selectedStocking)}
        averageHarvestedWeight={selectedStocking.averageHarvestedWeight}
        animals={getAnimals({ stocking: selectedStocking, phaseTypeSelected: phaseType })}
      />
    </div>
  </LrvModal>;
}

export default EnableStockingModal;
