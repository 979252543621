import cx from 'classnames';
import moment from 'moment';
import { Row, Space, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { getHoursOffset } from '../../../utils/date';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { filterOptionSelect } from '../../../utils/select';
import { changeHeader } from '../../AppHeader/headerSlice';
import { handleResizeEvent } from '../../../utils/dimensions';
import { DropdownProps } from '../../../common/interfaces/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import IconButton from '../../../common/components/buttons/IconButton';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';
import CleanButton from '../../../common/components/buttons/CleanButton';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { getUnitPhaseTypeFromAnalysis } from '../../../helpers/units.helpers';
import { LrvCheckbox } from '../../../common/components/LrvCheckbox/LrvCheckbox';
import { THEME, stockingPhaseTypes, roundFourDecimals } from '../../../config/commons';
import { LrvDatePicker } from '../../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvFilterPanel } from '../../../common/components/LrvSideFloatingPanel/LrvFilterPanel';

import styles from './SuccessQuadrant.module.scss';
import SuccessQuadrantD3 from './SuccessQuadrantD3';
import * as successQuadrantSlice from './successQuadrantSlice';
import { AnalysisQuadrantFilter, StockingQuadrant } from './interfaces';
import { getHeightOfTheOtherElements, getWidthOfTheOtherElements, disabledDateFrom, disabledDateTo, getXAxisMinMax, SUCCESS_QUADRANT_PARAMETERS, COLOR_LEGEND_WIDTH, filterQuadrantDataByOutliers, getAxisLabel, disableGroupDataCheckbox, groupStockingQuadrantData } from './helpers';

let chart: SuccessQuadrantD3 | null;

const { Option } = Select;

const SuccessQuadrant = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const refChartMain = useRef<HTMLDivElement>(null);
  const refFilters = useRef<HTMLDivElement>(null);

  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);
  const {
    filters,
    isLoading,
    units,
    modules,
    quadrantData,
    isDownloadingFile,
    densityDisabled,
  } = useSelector((state: Store) => state.successQuadrant);

  const [height, setHeight] = useState(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, densityDisabled }));
  const [width, setWidth] = useState(window.innerWidth - getWidthOfTheOtherElements());
  const [groupDataCheckboxDisabled, setGroupDataCheckboxDisabled] = useState(false);
  const [showSettingsPanel, setShowSettingsPanel] = useState(false);

  const {
    fromDate,
    toDate,
    campusId,
    moduleId,
    xAxis,
    yAxis,
    zAxis,
    circleSizeParameter,
    filterOutliers,
    groupData,
  } = filters;

  const hideSettingsItems = !campusId || !moduleId || !quadrantData.length;

  useEffect(() => {
    dispatch(changeHeader({ title: t('quadrant.title') }));

    return () => {
      chart = null;
      dispatch(successQuadrantSlice.resetFilters());
      dispatch(successQuadrantSlice.setQuadrantData([]));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(successQuadrantSlice.resetFilters());
    dispatch(successQuadrantSlice.setQuadrantData([]));
  }, [phaseType, selectedCompany._id]);

  useEffect(() => {
    if (!selectedCompany._id) {
      return;
    }

    const params = {
      companyId: selectedCompany._id,
      unitPhaseType: getUnitPhaseTypeFromAnalysis(phaseType),
    };

    dispatch(successQuadrantSlice.fetchUnits(params));
  }, [selectedCompany, phaseType]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(window.innerWidth - getWidthOfTheOtherElements());
      setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, densityDisabled }));
    });
  }, [densityDisabled]);

  useEffect(() => {
    if (!selectedCompany._id || !quadrantData.length) {
      return;
    }

    setWidth(window.innerWidth - getWidthOfTheOtherElements());
    setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, densityDisabled }));
  }, [selectedCompany._id, quadrantData, densityDisabled]);

  useEffect(() => {
    if (!quadrantData.length || !selectedCompany?._id) {
      return;
    }

    const showChartMain = () => {
      const filterQuadrantData = filterOutliers ? filterQuadrantDataByOutliers({ quadrantData, xAxis, yAxis }) : quadrantData;
      const quadrantDataGrouped = !groupDataCheckboxDisabled && groupData ? groupStockingQuadrantData({ quadrantData: filterQuadrantData, zAxis, xAxis, yAxis }) : filterQuadrantData;
      const { max: lastStage, min: firstStage } = getXAxisMinMax({ quadrantData: quadrantDataGrouped, xAxis });
      const size = width > height ? height : width;

      if (chart) {
        chart.refreshChart({
          quadrantData: quadrantDataGrouped,
          firstStage,
          height: size,
          width: size,
          lastStage,
          phaseType,
          xAxis,
          yAxis,
          zAxis,
          circleSizeParameter,
          theme,
          groupData,
        });
        return;
      }

      chart = new SuccessQuadrantD3({
        container: refChartMain.current,
        quadrantData: quadrantDataGrouped,
        firstStage,
        lastStage,
        height: size,
        width: size,
        phaseType,
        xAxis,
        yAxis,
        zAxis,
        circleSizeParameter,
        theme,
        groupData,
      });
    };

    showChartMain();
  }, [quadrantData, chart, selectedCompany, width, height, phaseType, xAxis, yAxis, zAxis, circleSizeParameter, filterOutliers, groupData, groupDataCheckboxDisabled, densityDisabled, theme]);
  
  useEffect(() => {
    if (!densityDisabled) {
      dispatch(successQuadrantSlice.setFilters({ ...filters, zAxis: SUCCESS_QUADRANT_PARAMETERS.DENSITY }));
      return;
    }
    dispatch(successQuadrantSlice.setFilters({ ...filters, zAxis: SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE }));
  }, [densityDisabled]);

  useEffect(() => {
    const disabled = disableGroupDataCheckbox(zAxis);
    setGroupDataCheckboxDisabled(disabled);
  }, [zAxis]);

  useEffect(() => {
    const size = width > height ? height : width;
    chart && chart.resize({ height: size, width: size });
  }, [width, height]);

  const renderUnitsDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_units'
        theme={theme}
        className={className}
        value={campusId || undefined}
        onChange={(value) => {
          const filterParams: AnalysisQuadrantFilter = {
            ...filters,
            campusId: value,
            moduleId: '',
          };

          dispatch(successQuadrantSlice.setFilters(filterParams));
          dispatch(successQuadrantSlice.setQuadrantData([]));
          dispatch(successQuadrantSlice.fetchModules({ phaseType, unitId: value }));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('quadrant.unit')}
        placeholder={t('quadrant.unit')}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
      >
        {units.map((unit) => <Option key={unit._id} value={unit._id}>{unit.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderModuleDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_modules'
        theme={theme}
        className={className}
        value={moduleId || undefined}
        onChange={(value) => {
          const filterParams: AnalysisQuadrantFilter = {
            ...filters,
            moduleId: value,
          };
          dispatch(successQuadrantSlice.setFilters(filterParams));

          const requestParams = {
            moduleId: value,
            campusId: filterParams.campusId,
            companyId: selectedCompany._id,
            fromDate: filterParams.fromDate,
            hoursOffset: getHoursOffset(),
            phaseType,
            toDate: filterParams.toDate,
          };
          dispatch(successQuadrantSlice.fetchQuadrantData(requestParams));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('quadrant.module')}
        placeholder={t('quadrant.module')}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
      >
        {modules.map((module) => <Option key={module._id} value={module._id}>{module.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderFromDate = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvDatePicker
        theme={theme}
        className={className}
        id='from_date'
        title={t('quadrant.fromDate')}
        allowClear={false}
        disabledDate={(value) => disabledDateFrom(value, new Date(toDate))}
        value={fromDate === '' ? undefined : moment(fromDate)}
        placeholder={t('quadrant.fromDate')}
        onChange={(date) => {
          const dateSelected = date?.toString() as string;

          const filterParams: AnalysisQuadrantFilter = {
            ...filters,
            fromDate: dateSelected,
          };

          dispatch(successQuadrantSlice.setFilters(filterParams));

          const requestParams = {
            campusId: filterParams.campusId,
            moduleId: filterParams.moduleId,
            companyId: selectedCompany._id,
            fromDate: dateSelected,
            hoursOffset: getHoursOffset(),
            phaseType,
            toDate: filterParams.toDate,
          };
          dispatch(successQuadrantSlice.fetchQuadrantData(requestParams));
        }}
      />
    );
  };

  const renderToDate = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvDatePicker
        theme={theme}
        className={className}
        id='to_date'
        title={t('quadrant.toDate')}
        allowClear={false}
        disabledDate={(value) => disabledDateTo(value, new Date(fromDate))}
        value={fromDate === '' ? undefined : moment(toDate)}
        placeholder={t('quadrant.toDate')}
        onChange={(date) => {
          const dateSelected = date?.toString() as string;
          const filterParams: AnalysisQuadrantFilter = {
            ...filters,
            toDate: dateSelected,
          };

          dispatch(successQuadrantSlice.setFilters(filterParams));

          const requestParams = {
            campusId: filterParams.campusId,
            moduleId: filterParams.moduleId,
            companyId: selectedCompany._id,
            fromDate: filterParams.fromDate,
            hoursOffset: getHoursOffset(),
            phaseType,
            toDate: dateSelected,
          };
          dispatch(successQuadrantSlice.fetchQuadrantData(requestParams));
        }}
      />
    );
  };

  const disableOption = (props: { value: string; selectedAxis: string[]; }) => {
    const { value, selectedAxis } = props;
    return selectedAxis.some(item => item === value);
  };

  const renderXAxis = (props: DropdownProps) => {
    const { className } = props;
    const title = t('quadrant.xAxis');

    return (
      <LrvSelect
        id='dropdown_x_axis'
        theme='light'
        className={className}
        value={xAxis || undefined}
        onChange={(value) => {
          const params: AnalysisQuadrantFilter = {
            ...filters,
            xAxis: value,
          };

          dispatch(successQuadrantSlice.setFilters(params));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={title}
        placeholder={title}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
      >
        {
          !densityDisabled &&
          <Option
            key={SUCCESS_QUADRANT_PARAMETERS.DENSITY}
            value={SUCCESS_QUADRANT_PARAMETERS.DENSITY}
            disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.DENSITY, selectedAxis: [yAxis, zAxis] })}
          >
            {t('quadrant.stockingDensity')}
          </Option>
        }

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH}
          value={SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH}
          disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH, selectedAxis: [yAxis, zAxis] })}
        >
          {phaseType === stockingPhaseTypes.LARVAE ? t('quadrant.dailyGrowth') : t('quadrant.weeklyGrowth')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY}
          value={SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY}
          disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY, selectedAxis: [yAxis, zAxis] })}
        >
          {t('quadrant.uniformity')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE}
          value={SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE}
          disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE, selectedAxis: [yAxis, zAxis] })}
        >
          {t('quadrant.survivalRate')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY}
          value={SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY}
          disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY, selectedAxis: [yAxis, zAxis] })}
        >
          {t('quadrant.productivity')}
        </Option>

        {
          !densityDisabled &&
          <Option
            key={SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY}
            value={SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY}
            disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY, selectedAxis: [yAxis, zAxis] })}
          >
            {t('quadrant.harvestDensity')}
          </Option>
        }
      </LrvSelect>
    );
  };

  const renderYAxis = (props: DropdownProps) => {
    const { className } = props;
    const title = t('quadrant.yAxis');

    return (
      <LrvSelect
        id='dropdown_y_axis'
        theme='light'
        className={className}
        value={yAxis || undefined}
        onChange={(value) => {
          const params: AnalysisQuadrantFilter = {
            ...filters,
            yAxis: value,
          };

          dispatch(successQuadrantSlice.setFilters(params));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={title}
        placeholder={title}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
      >
        {
          !densityDisabled &&
          <Option
            key={SUCCESS_QUADRANT_PARAMETERS.DENSITY}
            value={SUCCESS_QUADRANT_PARAMETERS.DENSITY}
            disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.DENSITY, selectedAxis: [xAxis, zAxis] })}
          >
            {t('quadrant.stockingDensity')}
          </Option>
        }

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH}
          value={SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH}
          disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH, selectedAxis: [xAxis, zAxis] })}
        >
          {phaseType === stockingPhaseTypes.LARVAE ? t('quadrant.dailyGrowth') : t('quadrant.weeklyGrowth')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY}
          value={SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY}
          disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY, selectedAxis: [xAxis, zAxis] })}
        >
          {t('quadrant.uniformity')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE}
          value={SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE}
          disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE, selectedAxis: [xAxis, zAxis] })}
        >
          {t('quadrant.survivalRate')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY}
          value={SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY}
          disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY, selectedAxis: [xAxis, zAxis] })}
        >
          {t('quadrant.productivity')}
        </Option>

        {
          !densityDisabled &&
          <Option
            key={SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY}
            value={SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY}
            disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY, selectedAxis: [yAxis, zAxis] })}
          >
            {t('quadrant.harvestDensity')}
          </Option>
        }
      </LrvSelect>
    );
  };

  const renderZAxis = (props: DropdownProps) => {
    const { className } = props;
    const title = t('quadrant.zAxis');

    return (
      <LrvSelect
        id='dropdown_z_axis'
        theme='light'
        className={className}
        value={zAxis || undefined}
        onChange={(value) => {
          const params: AnalysisQuadrantFilter = {
            ...filters,
            zAxis: value,
          };

          dispatch(successQuadrantSlice.setFilters(params));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={title}
        placeholder={title}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
      >
        {
          !densityDisabled &&
          <Option
            key={SUCCESS_QUADRANT_PARAMETERS.DENSITY}
            value={SUCCESS_QUADRANT_PARAMETERS.DENSITY}
            disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.DENSITY, selectedAxis: [xAxis, yAxis] })}
          >
            {t('quadrant.stockingDensity')}
          </Option>
        }

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.MATURATION_NAME}
          value={SUCCESS_QUADRANT_PARAMETERS.MATURATION_NAME}
          disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.MATURATION_NAME, selectedAxis: [xAxis, yAxis] })}
        >
          {t('quadrant.maturation')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.GENETIC_CODE}
          value={SUCCESS_QUADRANT_PARAMETERS.GENETIC_CODE}
          disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.GENETIC_CODE, selectedAxis: [xAxis, yAxis] })}
        >
          {t('quadrant.maturationCode')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.MODULE}
          value={SUCCESS_QUADRANT_PARAMETERS.MODULE}
          disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.MODULE, selectedAxis: [xAxis, yAxis] })}
        >
          {t('quadrant.module')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE}
          value={SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE}
        >
          {t('quadrant.survivalRate')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY}
          value={SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY}
          disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY, selectedAxis: [xAxis, yAxis] })}
        >
          {t('quadrant.uniformity')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY}
          value={SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY}
          disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY, selectedAxis: [xAxis, yAxis] })}
        >
          {t('quadrant.productivity')}
        </Option>

        {
          phaseType !== stockingPhaseTypes.LARVAE &&
          <Option
            key={SUCCESS_QUADRANT_PARAMETERS.PREVIOUS_ORIGIN}
            value={SUCCESS_QUADRANT_PARAMETERS.PREVIOUS_ORIGIN}
            disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.PREVIOUS_ORIGIN, selectedAxis: [xAxis, yAxis] })}
          >
            {t('quadrant.previousOrigin')}
          </Option>
        }

        {
          phaseType !== stockingPhaseTypes.LARVAE &&
          <Option
            key={SUCCESS_QUADRANT_PARAMETERS.LABORATORIES}
            value={SUCCESS_QUADRANT_PARAMETERS.LABORATORIES}
            disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.LABORATORIES, selectedAxis: [xAxis, yAxis] })}
          >
            {t('quadrant.laboratories')}
          </Option>
        }

        {
          !densityDisabled &&
          <Option
            key={SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY}
            value={SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY}
            disabled={disableOption({ value: SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY, selectedAxis: [yAxis, zAxis] })}
          >
            {t('quadrant.harvestDensity')}
          </Option>
        }
      </LrvSelect>
    );
  };

  const renderCircleSizeParameters = (props: DropdownProps) => {
    const { className } = props;
    const title = t('quadrant.circleSize');

    return (
      <LrvSelect
        id='dropdown_circle_size_axis'
        theme='light'
        className={className}
        value={circleSizeParameter || undefined}
        onChange={(value) => {
          const params: AnalysisQuadrantFilter = {
            ...filters,
            circleSizeParameter: value,
          };

          dispatch(successQuadrantSlice.setFilters(params));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={title}
        placeholder={title}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
      >
        {
          !densityDisabled &&
          <Option
            key={SUCCESS_QUADRANT_PARAMETERS.DENSITY}
            value={SUCCESS_QUADRANT_PARAMETERS.DENSITY}
          >
            {t('quadrant.stockingDensity')}
          </Option>
        }

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.STOCKING_ANIMALS}
          value={SUCCESS_QUADRANT_PARAMETERS.STOCKING_ANIMALS}
        >
          {t('quadrant.stockingAnimals')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH}
          value={SUCCESS_QUADRANT_PARAMETERS.WEEKLY_GROWTH}
        >
          {phaseType === stockingPhaseTypes.LARVAE ? t('quadrant.dailyGrowth') : t('quadrant.weeklyGrowth')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY}
          value={SUCCESS_QUADRANT_PARAMETERS.UNIFORMITY}
        >
          {t('quadrant.uniformity')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE}
          value={SUCCESS_QUADRANT_PARAMETERS.SURVIVAL_RATE}
        >
          {t('quadrant.survivalRate')}
        </Option>

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY}
          value={SUCCESS_QUADRANT_PARAMETERS.PRODUCTIVITY}
        >
          {t('quadrant.productivity')}
        </Option>

        {
          !densityDisabled &&
          <Option
            key={SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY}
            value={SUCCESS_QUADRANT_PARAMETERS.HARVEST_DENSITY}
          >
            {t('quadrant.harvestDensity')}
          </Option>
        }

        <Option
          key={SUCCESS_QUADRANT_PARAMETERS.HARVEST_QUANTITY}
          value={SUCCESS_QUADRANT_PARAMETERS.HARVEST_QUANTITY}
        >
          {t('quadrant.harvestQuantity')}
        </Option>
      </LrvSelect>
    );
  };

  const renderExcludeOutliers = () => {
    return (
      <LrvCheckbox
        id='checkbox_exclude_outliers'
        theme='light'
        className={styles.excludeOutliers}
        onChange={event => {
          const params: AnalysisQuadrantFilter = {
            ...filters,
            filterOutliers: event.target.checked,
          };

          dispatch(successQuadrantSlice.setFilters(params));
        }}
        checked={filterOutliers}
      >
        {t('quadrant.excludeOutliers')}
      </LrvCheckbox>
    );
  };

  const renderGroupDataCheckbox = () => {
    return (
      <LrvCheckbox
        id='checkbox_group_data'
        theme='light'
        className={cx(styles.groupData, groupDataCheckboxDisabled ? styles.checkboxDisabled : '')}
        disabled={groupDataCheckboxDisabled}
        onChange={event => {
          const params: AnalysisQuadrantFilter = {
            ...filters,
            groupData: event.target.checked,
          };

          dispatch(successQuadrantSlice.setFilters(params));
        }}
        checked={groupData}
      >
        {t('quadrant.groupData')}
      </LrvCheckbox>
    );
  };


  const renderEmptyContent = (title: string) => {
    return (
      <div className={styles.center} >
        <LrvEmpty description={title} theme={theme} />
      </div>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}>
            <DotSpinner />
          </div>
        </div>
      );
    }

    if (!campusId) {
      return renderEmptyContent(t('quadrant.emptyUnit'));
    }

    if (!moduleId) {
      return renderEmptyContent(t('quadrant.emptyModule'));
    }

    if (!quadrantData.length) {
      return renderEmptyContent(t('quadrant.empty'));
    }

    return null;
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon
          title={<div className={styles.title}>{t('quadrant.title')}</div>}
          cleanButtonProps={{
            onClick: () => {
              dispatch(successQuadrantSlice.resetFilters());
            },
          }}
        >
          <Space direction='vertical' className={styles.bodyPanel}>
            {renderUnitsDropdown({ theme: 'light' })}
            {renderModuleDropdown({ theme: 'light' })}
            {renderFromDate({ theme: 'light' })}
            {renderToDate({ theme: 'light' })}
            {!hideSettingsItems &&
              <>
                <div className={cx(styles.divider, isLightTheme ? styles.dividerLight : styles.dividerDark)}>
                  <LrvText theme={theme} text={t('quadrant.settings')} />
                </div>
                {renderExcludeOutliers()}
                {renderGroupDataCheckbox()}
                {renderXAxis({ className: styles.select, theme })}
                {renderYAxis({ className: styles.select, theme })}
                {renderZAxis({ className: styles.select, theme })}
                {renderCircleSizeParameters({ className: styles.select, theme })}
              </>
            }
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const renderSettingsPanel = () => {
    return (
      <div className={styles.settingsSidePanel}>
        <LrvFilterPanel
          title={<div className={styles.title}>{t('quadrant.settings')}</div>}
          visible={showSettingsPanel}
          onClose={() => setShowSettingsPanel(false)}
          showFilterIcon
          hideCleanButton
          showCloseButton
          hideIcon={hideSettingsItems || window.innerWidth < 880}
        >
          <Space direction='vertical' className={styles.bodyPanel}>
            {renderExcludeOutliers()}
            {renderGroupDataCheckbox()}
            {renderXAxis({ className: styles.select, theme })}
            {renderYAxis({ className: styles.select, theme })}
            {renderZAxis({ className: styles.select, theme })}
            {renderCircleSizeParameters({ className: styles.select, theme })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={theme}
        onClick={() => {
          dispatch(successQuadrantSlice.resetFilters());
        }}
      />
    );
  };

  const renderIconDownloadReport = () => {
    return (
      <IconButton
        id='success_quadrant_report_button'
        className={cx(styles.iconButton, hideSettingsItems ? styles.disabledIconBtn : '')}
        icon={<Icon name='download' theme={theme} />}
        onClick={() => dispatch(successQuadrantSlice.fetchUrlSuccessQuadrantPdf({ campusId, circleSizeParameter, companyId: selectedCompany._id, companyName: selectedCompany.name, filterOutliers, groupData, fromDate, hours: getHoursOffset(), moduleId, phaseType, toDate, xAxis, yAxis, zAxis }))}
        placement='bottomLeft'
        tooltipText={t('quadrant.download')}
        loading={isDownloadingFile}
        disabled={hideSettingsItems}
      />
    );
  };

  const renderTopAxis = () => {
    const svgWidth = (width > height ? height : width) + COLOR_LEGEND_WIDTH;

    return (
      <div
        className={styles.labelAxisY}
        style={{
          width: svgWidth,
        }}
      >
        <LrvText
          className={isLightTheme ? styles.lightText : styles.darkText}
          text={getAxisLabel({ axis: yAxis, phaseType })}
        />

        <LrvText
          className={isLightTheme ? styles.lightText : styles.darkText}
          style={{
            width: COLOR_LEGEND_WIDTH + 11,
          }}
          text={getAxisLabel({ axis: zAxis, phaseType })}
        />
      </div>
    );
  };

  const renderXAxisLabel = () => {
    return (
      <div className={styles.labelAxisX}>
        <LrvText
          className={isLightTheme ? styles.lightText : styles.darkText}
          text={getAxisLabel({ axis: xAxis, phaseType })}
          style={{
            right: `${COLOR_LEGEND_WIDTH / 2}px`,
          }}
        />
      </div>
    );
  };

  const getColumnsType = () => {
    const columnsType: ColumnsType<StockingQuadrant> = [
      {
        key: 1,
        width: '10%',
        title: t('quadrant.module'),
        dataIndex: 'moduleName',
        ellipsis: { showTitle: false },
        sorter: (a, b) => a.moduleName.localeCompare(b.moduleName),
        className: styles.cell,
      },
      {
        key: 2,
        width: '7%',
        title: t('quadrant.container'),
        dataIndex: ['containerName'],
        ellipsis: { showTitle: false },
        sorter: (a, b) => a.containerName.localeCompare(b.containerName),
        className: styles.cell,
      },
      {
        key: 3,
        width: '7%',
        title: t('quadrant.maturation'),
        dataIndex: ['maturationName'],
        ellipsis: { showTitle: false },
        sorter: (a, b) => a.maturationName.localeCompare(b.maturationName),
        className: styles.cell,
      },
      {
        key: 4,
        width: '6%',
        title: t('quadrant.maturationCode'),
        dataIndex: ['maturationCode'],
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        sorter: (a, b) => (a.maturationCode || '').localeCompare(b.maturationCode || ''),
        className: styles.cell,
      },
      {
        key: 5,
        width: '10%',
        title: t('quadrant.survivalRate'),
        dataIndex: ['survivalRate'],
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        sorter: (a, b) => a.survivalRate - b.survivalRate,
        className: styles.cell,
        render: (_, record: StockingQuadrant) => roundFourDecimals(record.survivalRate),
      },
      {
        key: 6,
        width: '10%',
        title: t('quadrant.weeklyGrowth'),
        dataIndex: ['weeklyGrowth'],
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        sorter: (a, b) => a.weeklyGrowth - b.weeklyGrowth,
        className: styles.cell,
        render: (_, record: StockingQuadrant) => roundFourDecimals(record.weeklyGrowth),
      },
      {
        key: 7,
        width: '7%',
        title: t('quadrant.stockingDensity'),
        dataIndex: ['stockingDensity'],
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        sorter: (a, b) => a.stockingDensity - b.stockingDensity,
        className: styles.cell,
      },
      {
        key: 8,
        width: '8%',
        title: t('quadrant.uniformity'),
        dataIndex: ['uniformity'],
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        sorter: (a, b) => a.uniformity - b.uniformity,
        className: styles.cell,
      },
      {
        key: 9,
        width: '7%',
        title: t('quadrant.quadrant'),
        dataIndex: ['quadrant'],
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        sorter: (a, b) => a.quadrant - b.quadrant,
        className: styles.cell,
      },
    ];

    return columnsType;
  };

  const renderTable = () => {
    return (
      <LrvTable
        id='success-quadrant-table'
        className={cx(styles.table, isLightTheme ? styles.tableLight : styles.tableDark)}
        columns={getColumnsType()}
        loading={isLoading || !chart}
        dataSource={quadrantData}
        size='small'
        theme={theme}
        pagination={false}
        onRow={(record: StockingQuadrant) => {
          return {
            onClick: (e) => {
              e.stopPropagation();
              const url = `/production/stockings/${record.stockingId}`;
              window.open(url, '_blank');
            }
          };
        }}
      />
    );
  };

  const renderNotShowingDensityLabel = () => {
    if (!densityDisabled || !moduleId) {
      return null;
    }

    return (
      <Row align='middle' className={cx(styles.notShowingDensity, isLightTheme ? styles.notShowingDensityLight : styles.notShowingDensityDark)}>
        <Space>
          <Icon name='error-warning' className={styles.warningIcon} type='fill' />
          <LrvText
            theme={theme}
            text={t('quadrant.notShowingDensity')}
          />
        </Space>
      </Row>
    );
  };

  return (
    <div className={styles.container}>
      <Row className={styles.rowHeader}>
        <Row ref={refFilters} className={styles.filtersContainer}>
          <Space className={styles.filters} align='end'>
            {renderUnitsDropdown({ className: styles.select, theme })}
            {renderModuleDropdown({ className: styles.select, theme })}
            {renderFromDate({ className: styles.select, theme })}
            {renderToDate({ className: styles.select, theme })}
            {renderCleanButton()}
          </Space>
        </Row>

        {renderSidePanel()}

        <Row>
          <Space className={styles.iconButtons}>
            {renderSettingsPanel()}
            {renderIconDownloadReport()}
          </Space>
        </Row>
      </Row>

      {renderContent()}

      <div className={cx(styles.containerSuccessQuadrant, densityDisabled ? styles.lessMargin : '')}>
        <div className={styles.successQuadrant} style={{ display: quadrantData.length > 0 ? '' : 'none' }}>
          {renderNotShowingDensityLabel()}

          <div className={styles.containerChart}>
            <div className={styles.chart}>
              {renderTopAxis()}
              <div className={styles.successQuadrantChart}>
                <div id='chart' ref={refChartMain} />
              </div>
              {renderXAxisLabel()}
            </div>
          </div>

          {renderTable()}
        </div>
      </div>
    </div>
  );
};

export default SuccessQuadrant;