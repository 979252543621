import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Header from '../Header';
import Footer from '../Footer';
import { Store } from '../../../state/store.interfaces';
import { formatLongDateWithZone } from '../../../utils/date';
import { TransferGraphD3 } from '../../Reports/TransferGraph/TransferGraphD3';
import { TransferMetricEntry, ParameterType } from '../../Reports/TransferGraph/interfaces';

import styles from './TransferGraph.module.scss';
import { getTitleGraph, getUnit } from './helpers';

interface Props {
  transferMetrics: TransferMetricEntry[];
  parameter: ParameterType;
}

export const TransferGraph = (props: Props) => {
  const { transferMetrics, parameter } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const refChartMain = useRef<HTMLDivElement>(null);

  const {
    stocking,
    transfer,
  } = useSelector((state: Store) => state.transferGraph);
  const { company } = useSelector((state: Store) => state.header);

  const width = 1680 - 96;
  const height = 950 - 22;

  useEffect(() => {
    if (!transferMetrics.length) {
      return;
    }

    new TransferGraphD3({
      container: refChartMain.current,
      transferMetrics,
      height,
      width,
    });
  }, [dispatch, transferMetrics, width, height, transferMetrics, refChartMain.current]);

  if (!transferMetrics.length) {
    return null;
  }

  const renderTitle = () => {
    const campusName = stocking.campusName?.toLowerCase();
    if (!campusName) {
      return company.name;
    }

    return company.name + ' - ' + t('analysis.campus') + ' ' + campusName.charAt(0).toUpperCase() + campusName.slice(1);
  };

  const renderSubtitle = () => {
    return t('transfers.transferDate') + ' ' + formatLongDateWithZone(transfer.transferDate);
  };

  const renderGraphTitle = () => {
    return `${getTitleGraph({ parameter, maxSmallAnimalsWeight: transfer.maxSmallAnimalsWeight })} (${getUnit({ parameter })})`;
  };

  return (
    <div className={styles.container}>
      <Header
        title={renderTitle()}
        subtitle={renderSubtitle()}
      />

      <div className={styles.graphTitle}>
        <span>{renderGraphTitle()}</span>
      </div>

      <div className={styles.graph} ref={refChartMain} />
      <Footer/>
    </div>
  );
};
